import {
  Flex,
  Switch,
  Button,
  Box,
  useDisclosure,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
  Th,
  HStack,
  Thead,
  Img,
} from "@chakra-ui/react";
import { MdAdd } from "react-icons/md";

import { useState } from "react";
import { CategoryItemHeader } from "./CategoryItemHeader";
import { TCategory, TItem } from "../../../types";
import { CreateItemModal } from "../modals/CreateItemModal";
import { EditItemModal } from "../modals/EditItemModal";
import { formatPrice } from "../../../utils/formatPrice";
import { DuplicateItemModal } from "../modals/DuplicateItemModal";

interface ICategoryItemProps {
  category: TCategory;
}

function CategoryItem({ category }: ICategoryItemProps): JSX.Element {
  const { id, items } = category;

  const [itemsState, setItemsState] = useState<TItem[]>(() => items);

  const [editItem, setEditItem] = useState<TItem | null>(null);
  const [duplicateItem, setDuplicateItem] = useState<TItem | null>(null);

  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isOpenEditModal,
    onClose: onCloseEditModal,
    onOpen: onOpenEditModal,
  } = useDisclosure();

  const {
    isOpen: isOpenDuplicateModal,
    onClose: onCloseDuplicateModal,
    onOpen: onOpenDuplicateModal,
  } = useDisclosure();

  return (
    <Box border="1px solid #d5d5d5">
      <CategoryItemHeader category={category} />
      <Flex p="1rem 0" flexDirection="column" borderTop="1px solid #d5d5d5">
        <TableContainer>
          <Table variant="simple" size="sm">
            <Thead>
              <Tr>
                <Th>Item</Th>
                <Th>Descrição</Th>
                <Th>Preço</Th>
                <Th>Status</Th>
                <Th>Ações</Th>
              </Tr>
            </Thead>
            <Tbody>
              {itemsState.map((item) => (
                <Tr key={item.id}>
                  <Td w="20%">
                    <Flex gap={4} alignItems="center" whiteSpace="normal">
                      <Img src={item.urlImage} boxSize={12} borderRadius="md" />
                      {item.name}
                    </Flex>
                  </Td>
                  <Td whiteSpace="normal" w="30%">
                    {item.description}
                  </Td>
                  <Td>
                    {item.discountPrice ? (
                      <Flex>
                        <span style={{ marginRight: "16px" }}>
                          {formatPrice(item.discountPrice)}
                        </span>
                        <span style={{ textDecoration: "line-through" }}>
                          {formatPrice(item.originalPrice)}
                        </span>
                      </Flex>
                    ) : (
                      formatPrice(item.originalPrice)
                    )}
                  </Td>
                  <Td>
                    <Switch
                      colorScheme="primary"
                      isChecked={item.status === "UNAVAILABLE"}
                    >
                      Pausar
                    </Switch>
                  </Td>
                  <Td>
                    <HStack>
                      <Button
                        colorScheme="primary"
                        variant="link"
                        onClick={() => {
                          setDuplicateItem(item);
                          onOpenDuplicateModal();
                        }}
                      >
                        Duplicar
                      </Button>
                      <Button
                        colorScheme="primary"
                        variant="link"
                        onClick={() => {
                          setEditItem(item);
                          onOpenEditModal();
                        }}
                      >
                        Editar
                      </Button>
                    </HStack>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <Button
          ml={4}
          mt={4}
          width="20%"
          leftIcon={<MdAdd size={20} />}
          colorScheme="primary"
          variant="outline"
          onClick={onOpen}
        >
          Adicionar Itens
        </Button>
      </Flex>

      <CreateItemModal
        isOpen={isOpen}
        onClose={onClose}
        categoryId={id}
        setItemsState={setItemsState}
      />
      <EditItemModal
        isOpen={isOpenEditModal}
        onClose={onCloseEditModal}
        categoryId={id}
        item={editItem}
      />
      <DuplicateItemModal
        isOpen={isOpenDuplicateModal}
        onClose={onCloseDuplicateModal}
        categoryId={id}
        item={duplicateItem}
        setItemsState={setItemsState}
      />
    </Box>
  );
}

export { CategoryItem };
