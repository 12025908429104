import { atom, selector } from "recoil";
import { useAuth } from "../../../hooks/auth";
import { api } from "../../../services/api";
import { TCategory } from "../../../types";

export const categoriesState = atom<TCategory[]>({
  key: "categories",
  default: selector({
    key: "categoriesDefault",
    get: async () => {
      try {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { user } = useAuth();
        const response = await api.get(
          `/companies/${user.companyId}/categories`
        );

        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
  }),
});
