import { useEffect, Dispatch, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Img,
} from "@chakra-ui/react";

import { useForm } from "react-hook-form";
import { TFlavor, TSize, TStatus } from "../../../types";
import { api } from "../../../services/api";
import { currencyMask } from "../../../utils/masks";
import { formatStringInputToNumber } from "../../../utils/formatStringInputToNumber";
import { formatNumberToStringInput } from "../../../utils/formatNumberToStringInput";

interface UpdatePizzaFlavorModalProps {
  isOpen: boolean;
  onClose: () => void;
  sizes: TSize[];
  pizzaId: string;
  setFlavors: Dispatch<React.SetStateAction<TFlavor[]>>;
  flavorToUpdate: TFlavor;
  flavorIndex: number;
}

interface UpdateFlavorFormData {
  name: string;
  description: string;
  urlImage: string;

  prices: {
    id: string;
    originalPrice: string;
    sizeId: string;
  }[];
}

function UpdatePizzaFlavorModal({
  isOpen,
  onClose,
  sizes,
  pizzaId,
  setFlavors,
  flavorToUpdate,
  flavorIndex,
}: UpdatePizzaFlavorModalProps): JSX.Element {
  const [imgSrc, setImgSrc] = useState<undefined | string>(undefined);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<UpdateFlavorFormData>();

  // Precisa de ser dentro de useEfecct???

  useEffect(() => {
    setValue("name", flavorToUpdate.name);
    setValue("description", flavorToUpdate.description);
    setImgSrc(flavorToUpdate.urlImage);
    sizes.forEach((size, index) => {
      setValue(`prices.${index}.sizeId`, size.id);
      // mudar essa logica
      const teste = flavorToUpdate.prices.find(
        (price) => price.sizeId === size.id
      );
      setValue(
        `prices.${index}.originalPrice`,
        `R$ ${formatNumberToStringInput(teste?.originalPrice || 0)}`
      );
    });
  }, [flavorToUpdate, setValue, sizes]);

  /** mudar isso */

  const onImageChange = (e: any): void => {
    const [file] = e.target.files;
    setImgSrc(URL.createObjectURL(file));
  };

  async function handleCreateFlavorFormSubmit(
    data: UpdateFlavorFormData
  ): Promise<void> {
    const formData = new FormData();
    formData.append("urlImage", data.urlImage[0]);
    formData.append(
      "obj",
      JSON.stringify({
        name: data.name,
        description: data.description,
      })
    );

    const flavorResponse = await api.put(
      `/pizzas/${pizzaId}/flavors/${flavorToUpdate.id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    const prices = data.prices.map((price) => {
      // ['R$', '0,00']
      const stringPrice = price.originalPrice.split(" ")[1];
      return {
        ...price,
        originalPrice: formatStringInputToNumber(stringPrice),
      };
    });
    // console.log(prices);
    // // const pricesResponse = await api.put(
    // //   `/flavors/${flavorResponse.data.id}/prices`,
    // //   prices
    // // );

    setFlavors((state) =>
      state.map((flavor) => {
        if (flavor.id === flavorToUpdate.id) {
          return {
            ...flavor,
            ...flavorResponse.data,
          };
        }
        return flavor;
      })
    );
    reset();
    onClose();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent
        as="form"
        onSubmit={handleSubmit(handleCreateFlavorFormSubmit)}
      >
        <ModalHeader>Editar sabor</ModalHeader>
        <ModalCloseButton />
        <ModalBody display="flex" flexDir="row">
          <VStack w="60%">
            <FormControl>
              <FormLabel className="required">Nome</FormLabel>
              <Input {...register("name")} />
            </FormControl>
            <FormControl>
              <FormLabel className="required">Descrição</FormLabel>
              <Textarea {...register("description")} />
            </FormControl>
            <TableContainer>
              <Table variant="unstyled" size="md">
                <Thead>
                  <Tr>
                    <Th p={2}>Tamanho</Th>
                    <Th p={2} className="required">
                      Preço
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {sizes.map((size, index) => (
                    <Tr key={size.id}>
                      <Td p={2}>{size.name}</Td>
                      <Td p={2}>
                        <Input
                          {...register(`prices.${index}.originalPrice`)}
                          onChange={(e) => {
                            const { value } = e.currentTarget;
                            if (value === "R$") {
                              e.currentTarget.value = "";
                              return e;
                            }
                            e.currentTarget.value = `R$ ${currencyMask(value)}`;
                            return e;
                          }}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </VStack>
          <Box flex="1" p={4}>
            <Input
              type="file"
              {...register("urlImage")}
              onChange={onImageChange}
            />
            {imgSrc && <Img src={imgSrc} />}
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" variant="outline" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button type="submit" colorScheme="blue" isLoading={isSubmitting}>
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export { UpdatePizzaFlavorModal };
