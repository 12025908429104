import { useEffect, Dispatch, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Img,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useForm } from "react-hook-form";

import { TFlavor, TSize } from "../../../types";
import { api } from "../../../services/api";
import { currencyMask } from "../../../utils/masks";
import { formatStringInputToNumber } from "../../../utils/formatStringInputToNumber";

interface CreatePizzaFlavorModalProps {
  isOpen: boolean;
  onClose: () => void;
  sizes: TSize[];
  pizzaId: string;
  setFlavors: Dispatch<React.SetStateAction<TFlavor[]>>;
  flavorIndex: number;
}

interface CreateFlavorFormData {
  name: string;
  description: string;
  urlImage: string;
  index: number;
  status: "AVAILABLE" | "UNAVAILABLE";
  prices: {
    originalPrice: string;
    sizeId: string;
  }[];
}

const priceSchema = {
  originalPrice: Yup.string().required("Campo obrigatório"),
};

const schema = Yup.object().shape({
  name: Yup.string().required("Campo obrigatório"),
  description: Yup.string().required("Campo obrigatório"),
  prices: Yup.array().of(Yup.object().shape(priceSchema)),
});

function CreatePizzaFlavorModal({
  isOpen,
  onClose,
  sizes,
  pizzaId,
  setFlavors,
  flavorIndex,
}: CreatePizzaFlavorModalProps): JSX.Element {
  const toast = useToast();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<CreateFlavorFormData>({
    defaultValues: {
      status: "UNAVAILABLE",
    },
    resolver: yupResolver(schema),
  });

  // Precisa de ser dentro de useEfecct???
  useEffect(() => {
    setValue("index", flavorIndex);
    sizes.forEach((size, index) => {
      setValue(`prices.${index}.sizeId`, size.id);
    });
  });

  /** mudar isso */
  const [imgSrc, setImgSrc] = useState<undefined | string>(undefined);
  const onImageChange = (e: any): void => {
    const [file] = e.target.files;
    if (file) {
      setImgSrc(URL.createObjectURL(file));
    }
  };

  async function handleCreateFlavorFormSubmit(
    data: CreateFlavorFormData
  ): Promise<void> {
    try {
      const formData = new FormData();
      formData.append("urlImage", data.urlImage[0]);
      formData.append(
        "obj",
        JSON.stringify({
          name: data.name,
          description: data.description,
          index: data.index,
          status: data.status,
        })
      );
      const flavorResponse = await api.post(
        `/pizzas/${pizzaId}/flavors`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Desfaz a mascara do input de preco
      const prices = data.prices.map((price) => {
        // ['R$', '0,00']
        const stringPrice = price.originalPrice.split(" ")[1];
        return {
          ...price,
          originalPrice: formatStringInputToNumber(stringPrice),
        };
      });
      const pricesResponse = await api.post(
        `/flavors/${flavorResponse.data.id}/prices`,
        { prices }
      );
      setFlavors((state) => [
        ...state,
        { ...flavorResponse.data, prices: pricesResponse.data },
      ]);

      toast({
        title: "Sabor adicionado com sucesso!",
        status: "success",
        duration: 2000,
        position: "top-right",
        isClosable: true,
      });
      reset();
      setImgSrc(undefined);
      onClose();
    } catch (error) {
      console.log(error);
      toast({
        title: "Algo deu errado!",
        status: "error",
        duration: 2000,
        position: "top-right",
        isClosable: true,
      });
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent
        as="form"
        onSubmit={handleSubmit(handleCreateFlavorFormSubmit)}
      >
        <ModalHeader>Adicionar sabor</ModalHeader>
        <ModalCloseButton />
        <ModalBody display="flex" flexDir="row">
          <VStack w="60%">
            <FormControl isInvalid={!!errors.name}>
              <FormLabel className="required">Nome</FormLabel>
              <Input {...register("name")} />
              <FormErrorMessage> {errors?.name?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.description}>
              <FormLabel className="required">Descrição</FormLabel>
              <Textarea {...register("description")} />
              <FormErrorMessage>
                {errors?.description?.message}
              </FormErrorMessage>
            </FormControl>
            <TableContainer>
              <Table variant="unstyled" size="md">
                <Thead>
                  <Tr>
                    <Th p={2}>Tamanho</Th>
                    <Th p={2} className="required">
                      Preço
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {sizes.map((size, index) => (
                    <Tr key={size.id}>
                      <Td p={2}>{size.name}</Td>
                      <Td p={2}>
                        <FormControl
                          isInvalid={!!errors.prices?.[index]?.originalPrice}
                        >
                          <Input
                            {...register(`prices.${index}.originalPrice`)}
                            onChange={(e) => {
                              const { value } = e.currentTarget;
                              if (value === "R$") {
                                e.currentTarget.value = "";
                                return e;
                              }
                              e.currentTarget.value = `R$ ${currencyMask(
                                value
                              )}`;
                              return e;
                            }}
                          />
                          <FormErrorMessage>
                            {errors?.prices?.[index]?.originalPrice?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </VStack>
          <Box flex="1">
            <Input
              type="file"
              {...register("urlImage")}
              onChange={onImageChange}
            />
            {imgSrc && <Img src={imgSrc} />}
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="gray" mr={3} onClick={onClose}>
            Close
          </Button>

          <Button type="submit" colorScheme="blue" isLoading={isSubmitting}>
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export { CreatePizzaFlavorModal };
