import { AiOutlineHome } from "react-icons/ai";
import { FcDataConfiguration } from "react-icons/fc";

import { FaUserAstronaut } from "react-icons/fa";
import { BsWallet2 } from "react-icons/bs";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { BiMap } from "react-icons/bi";
import { BiFoodMenu, BiHourglass } from "react-icons/bi";
import { MdOutlineFastfood, MdBusiness } from "react-icons/md";
import { GiReceiveMoney } from "react-icons/gi";
import { SidebarItem } from "../models/SidebarItem";

export const SidebarData: SidebarItem[] = [
  {
    title: "DashBoard",
    path: "/DashBoard",
    icon: <AiOutlineHome />,
  },
  {
    title: "Pedidos",
    path: "/Order",
    icon: <MdOutlineFastfood />,
  },

  {
    title: "Cardápio",
    path: "/Menu",
    icon: <BiFoodMenu />,
  },
  {
    title: "Horário",
    path: "/OpeningHour",
    icon: <BiHourglass />,
  },
  {
    title: "Area de entrega",
    path: "/delivery-area",
    icon: <BiMap />,
  },
  {
    title: "Pagamento",
    path: "/Payment",
    icon: <GiReceiveMoney />,
  },
  {
    title: "Carteira",
    path: "/Wallet",
    icon: <BsWallet2 />,
  },
  {
    title: "Relatórios",
    path: "/Reports",
    icon: <HiOutlineDocumentReport />,
  },
  {
    title: "Entregador",
    path: "/Couriers",
    icon: <FaUserAstronaut />,
  },
  {
    title: "Integração",
    path: "/Integration",
    icon: <FcDataConfiguration />,
  },
  {
    title: "Config",
    path: "/Configuration",
    icon: <MdBusiness />,
  },
];
