/* eslint-disable react/jsx-no-bind */
import {
  Box,
  FormControl,
  Grid,
  VStack,
  FormLabel,
  Input,
  Button,
  Flex,
  Text,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";

import {
  GoogleMap,
  Marker,
  useLoadScript,
  DrawingManager,
  Circle,
  Polygon,
} from "@react-google-maps/api";
import {
  useCallback,
  useEffect,
  useState,
  ReactNode,
  useMemo,
  useRef,
} from "react";

import { RiDeleteBinLine, RiPencilLine } from "react-icons/ri";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import { memo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useAuth } from "../../../hooks/auth";
import { api } from "../../../services/api";

import "../../../styles.css";
import { formatPrice } from "../../../utils/formatPrice";
import { areasState, parametersState } from "../store/atom";

interface MapProps {
  children: ReactNode;
}

const libraries: (
  | "drawing"
  | "geometry"
  | "localContext"
  | "places"
  | "visualization"
)[] = ["drawing"];

function Map({ children }: MapProps): JSX.Element {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY!,
    libraries,
  });
  const { user } = useAuth();
  const center = useMemo(() => user.coords, [user.coords]);

  if (!isLoaded) {
    return <div>loading...</div>;
  }
  return (
    <GoogleMap
      zoom={14}
      center={center}
      mapContainerClassName="map-container"
      onClick={(e) => {
        console.log({ lat: e.latLng?.lat(), lng: e.latLng?.lng() });
      }}
    >
      {children}
    </GoogleMap>
  );
}

const MemoMap = memo(Map);

interface FormData {
  name: string;
  deliveryFee: number;
  deliveryTime: number;
}
function transform(data: any): Array<{ lat: number; lng: number }> {
  return data.map((item: any) => ({ lat: item[1], lng: item[0] }));
}

interface FormValues {
  id?: string;
  name: string;
  color: string;
  coordinates: {
    lat: number;
    lng: number;
  }[];
}

// const backendData = [
//   {
//     id: "761c2f36-53d8-47de-a326-932c1b567ecc",
//     name: "Area-1",
//     color: "red",
//     coordinates: [
//       [
//         [-43.443359602, -21.684949922],
//         [-43.440140951, -21.688658548],
//         [-43.444432486, -21.692686087],
//         [-43.447908629, -21.698667374],
//         [-43.439239729, -21.699664231],
//         [-43.437008131, -21.696035638],
//         [-43.431772459, -21.695238133],
//         [-43.427051771, -21.698069256],
//         [-43.429111708, -21.701259187],
//         [-43.435849417, -21.698747122],
//         [-43.431987036, -21.703492097],
//         [-43.424734343, -21.704169938],
//         [-43.417009581, -21.700740828],
//         [-43.424863089, -21.69491913],
//         [-43.430742491, -21.689934612],
//         [-43.433832396, -21.680922167],
//         [-43.443359602, -21.684949922],
//       ],
//     ],
//   },
//   {
//     id: "e974b2ee-c87b-438f-a398-d1754287cc51",
//     name: "Area-2",
//     color: "green",
//     coordinates: [
//       [
//         [-43.445097674, -21.695357759],
//         [-43.446728457, -21.702854126],
//         [-43.43527006, -21.703691462],
//         [-43.436686266, -21.697790134],
//         [-43.445097674, -21.695357759],
//       ],
//     ],
//   },
// ];

// const circles = [
//   {
//     id: "1",
//     value: 2,
//     time: 30,
//     distance: 1000,
//   },
//   {
//     id: "2",
//     value: 3,
//     time: 35,
//     distance: 2000,
//   },
//   {
//     id: "3",
//     value: 4,
//     time: 40,
//     distance: 3000,
//   },
//   {
//     id: "4",
//     value: 5,
//     time: 45,
//     distance: 4000,
//   },
//   {
//     id: "5",
//     value: 6,
//     time: 50,
//     distance: 5000,
//   },
//   {
//     id: "6",
//     value: 7,
//     time: 55,
//     distance: 6000,
//   },
// ];

function colorRandom(): string {
  // eslint-disable-next-line no-bitwise
  const color = `#${((Math.random() * 0xffffff) << 0)
    .toString(16)
    .padStart(6, "0")}`;
  return color;
}

function Create(): JSX.Element {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY!,
    libraries,
  });
  const { user } = useAuth();
  const center = useMemo(() => user.coords, [user.coords]);

  const parameters = useRecoilValue(parametersState);
  const [areas, setAreas] = useRecoilState(areasState);

  const { register, handleSubmit, control } = useForm<FormValues>();

  const navigate = useNavigate();
  const toast = useToast();

  const drawingManagerRef = useRef<google.maps.drawing.DrawingManager | null>(
    null
  );
  const mapRef = useRef<google.maps.Map | null>(null);
  const polygonRef = useRef<google.maps.Polygon | null>(null);
  const refs = useRef<google.maps.Polygon[]>([]);

  const onLoadMap = (map: google.maps.Map): void => {
    mapRef.current = map;
  };

  const onLoad = (drawingManager: google.maps.drawing.DrawingManager): void => {
    drawingManagerRef.current = drawingManager;
    drawingManagerRef.current.setOptions({
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [google.maps.drawing.OverlayType.POLYGON],
      },
      polygonOptions: {
        fillColor: "red",
        fillOpacity: 0.1,
        strokeWeight: 2,
        clickable: true,
        editable: true,
        zIndex: 1,
      },
    });
  };

  function onEdit(id: string, i: number): void {
    const nextPath = refs.current[i]
      .getPath()
      .getArray()
      .map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() }));

    setAreas((state) =>
      state.map((area) => {
        if (area.id === id) {
          return {
            ...area,
            coordinates: nextPath,
          };
        }

        return area;
      })
    );
  }

  function onLoadPolygon(polygon: google.maps.Polygon, index: number): void {
    refs.current.push(polygon);
  }

  const onPolygonComplete = (polygon: google.maps.Polygon): void => {
    polygonRef.current = polygon;
    const array = polygon
      .getPath()
      .getArray()
      .map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() }));

    setAreas((state) => [
      ...state,
      {
        id: v4(),
        name: `Área-${state.length + 1}`,
        color: colorRandom(),
        coordinates: array,
      },
    ]);

    drawingManagerRef.current?.setDrawingMode(null);
    polygonRef.current?.setMap(null);
  };

  function onDeletePolygon(id: string, index: number): void {
    setAreas((state) => state.filter((area) => area.id !== id));
    refs.current[index].setMap(null);
    refs.current.splice(index, 1);
  }

  async function handleFormSubmit(data: FormValues): Promise<void> {
    try {
      const teste = areas.map((area) => {
        const coordinatesN = [...area.coordinates];
        coordinatesN.push(coordinatesN[0]);
        return {
          id: area.id,
          name: area.name,
          color: area.color,
          geom: {
            type: "Polygon",
            coordinates: [coordinatesN.map((coord) => [coord.lng, coord.lat])],
          },
        };
      });

      await api.post(`/companies/${user.companyId}/delivery-areas`, teste);
    } catch (error) {
      console.log(error);
    }
  }
  if (!isLoaded) {
    return <div>loading...</div>;
  }

  async function verifyIsWithin(e: google.maps.MapMouseEvent): Promise<void> {
    const result = await api.get(
      `/companies/96f5cdcf-47ec-4b13-a31a-14070f35de74/isWithin`,
      { params: { lat: e.latLng?.lat(), lng: e.latLng?.lng() } }
    );

    toast({
      title:
        result.data.position === "IN"
          ? "Dentro da Área de Entrega"
          : "Fora da Área de Entrega",
      description:
        result.data.position === "IN"
          ? `Taxa de entrega: ${formatPrice(
              result.data.delivery.deliveryFee
            )}  Tempo:${result.data.delivery.deliveryTime} min`
          : "",
      status: result.data.position === "IN" ? "success" : "error",
      position: "top-right",
    });
  }

  return (
    <Grid
      templateAreas={`"left map"`}
      gridTemplateColumns="270px  1fr"
      h="100vh"
    >
      <Box
        as="form"
        onSubmit={handleSubmit(handleFormSubmit)}
        gridArea="left"
        p={4}
      >
        <VStack spacing={2}>
          {areas.map((area, index) => (
            <Flex
              key={area.id}
              w="100%"
              border="1px solid #f5f5f5"
              borderRadius="md"
              alignItems="center"
            >
              <Flex w="45%" px={4}>
                {area.name}
              </Flex>
              <Flex opacity="0.4" bg={area.color} w="30%" h="30px" />
              <Flex px={4} gap={4}>
                <RiPencilLine
                  onClick={() =>
                    refs.current[index].setOptions({ editable: true })
                  }
                />
                <RiDeleteBinLine
                  onClick={() => onDeletePolygon(area.id, index)}
                />
              </Flex>
            </Flex>
          ))}
        </VStack>

        <Button
          type="submit"
          mt="300px"
          w="full"
          colorScheme="primary"
          // isDisabled={areas.length === 0}
        >
          Salvar
        </Button>
        <Button
          mt="1rem"
          w="full"
          colorScheme="primary"
          variant="outline"
          onClick={() => navigate("/delivery-area")}
        >
          Voltar
        </Button>
      </Box>
      <Box gridArea="map">
        <GoogleMap
          zoom={14}
          center={center}
          mapContainerClassName="map-container"
          onLoad={onLoadMap}
          onClick={verifyIsWithin}
        >
          <Marker
            position={{ lat: -21.692187635703263, lng: -43.43235181639841 }}
          />
          <DrawingManager
            onLoad={onLoad}
            onPolygonComplete={onPolygonComplete}
          />
          {parameters.map((parameter) => (
            <Circle
              key={parameter.id}
              center={user.coords}
              radius={parameter.distance}
              options={{
                strokeWeight: 0.8,
                strokeOpacity: 0.6,
                fillOpacity: 0,
                clickable: true,
                zIndex: 4,
              }}
              onClick={verifyIsWithin}
            />
          ))}
          {areas.map((area, index) => (
            <Polygon
              key={area.id}
              // editable
              // draggable

              path={area.coordinates}
              options={{
                strokeWeight: 1,
                fillColor: area.color,
                clickable: true,
                zIndex: 4,
              }}
              onLoad={(polygon) => onLoadPolygon(polygon, index)}
              // onUnmount={}
              onMouseUp={() => onEdit(area.id, index)}
              onDragEnd={() => onEdit(area.id, index)}
              onClick={verifyIsWithin}
            />
          ))}
        </GoogleMap>
      </Box>
    </Grid>
  );
}

export { Create };
