import Chart from "react-apexcharts";
import { useEffect, useState } from "react";
import { api } from "../../../services/api";
// https://www.smashingmagazine.com/2020/11/data-visualization-apexcharts/

const retornoBanco2 = [
  { date: "11-12", receita: 500, pedidos: 10 },
  { date: "12-12", receita: 1000, pedidos: 20 },
  { date: "13-12", receita: 750, pedidos: 15 },
  { date: "14-12", receita: 250, pedidos: 5 },
  { date: "15-12", receita: 1500, pedidos: 30 },
  { date: "16-12", receita: 1550, pedidos: 33 },
  { date: "17-12", receita: 500, pedidos: 10 },
];

export default function RevenueLine({
  url,
  startDate,
  endDate,
}: {
  url: string;
  startDate: string;
  endDate: string;
}): JSX.Element {
  const [revenueLineDate, setRevenueLineDate] = useState<string[]>([]);
  const [revenueLineRecive, setRevenueLineRecive] = useState<number[]>([]);
  const [revenueLineOrder, setRevenueLineOrder] = useState<number[]>([]);

  useEffect(() => {
    api
      .get(`${url}/dashboard/orders-resume`, { params: { startDate, endDate } })
      .then((resp: any) => {
        // console.log(resp.data);
        setRevenueLineDate(resp.data.map((item: any) => item.date));
        setRevenueLineRecive(resp.data.map((item: any) => item.amount));
        setRevenueLineOrder(resp.data.map((item: any) => item.quantity));
      });
  }, [endDate, startDate, url]);

  const state = {
    series: [
      {
        name: "Receita",
        type: "column",
        data: revenueLineRecive,
      },
      {
        name: "Número de Pedidos",
        type: "line",
        data: revenueLineOrder,
      },
    ],

    options: {
      // define que os valores serão mostrados dentro do grafico
      dataLabels: {
        enabled: true,
      },
      // define que o tracado tera uma leve curva, se nao ele fica reto
      stroke: {
        width: [0, 2],
      },
      chart: {
        stacked: true,
      },

      // definindo eixo x que é a parte de baixo
      xaxis: {
        categories: revenueLineDate,
      },
    },
  };

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <Chart
            options={state.options}
            series={state.series}
            type="area"
            width="500"
          />
        </div>
      </div>
    </div>
  );
}
