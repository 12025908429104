import {
  Box,
  Button,
  RadioProps,
  useRadio,
  UseRadioProps,
  Flex,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface CustomRadioProps {
  props: UseRadioProps;
  value: "Income" | "Outcome";
}
const transform = {
  Income: "Entrada",
  Outcome: "Saída",
};
function CustomRadio({ value, props }: CustomRadioProps): JSX.Element {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" width="100%" justifySelf="center">
      <input {...input} />
      <Flex
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        _checked={{
          bg: "blue.600",
          color: "white",
          borderColor: "blue.600",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={5}
        py={3}
        marginInlineEnd={5}
        width="100%"
        justifyContent="center"
      >
        {transform[value]}
        {/* <Center>{children}</Center> */}
      </Flex>
    </Box>
  );
}

export { CustomRadio };
