import {
  Heading,
  Avatar,
  Box,
  Center,
  Text,
  Stack,
  Button,
  Link,
  Badge,
  useColorModeValue,
  Flex,
  Divider,
} from "@chakra-ui/react";
// import useSWR from "swr";
import empreparoImage from "../artes/empreparo.png";
import emrotaImage from "../artes/emrota.png";
import canceladoImage from "../artes/cancelado.png";
import { TOrder } from "..";

interface CardsOrderProps {
  data: TOrder[];
}
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function CardsOrder({ data }: CardsOrderProps) {
  // pendente
  // empreparacao
  // concluido
  // cancelado
  const resul = data.reduce(
    (acc, order) => {
      switch (order.status) {
        case "PLACED": {
          acc.pendent += 1;
          break;
        }

        case "CONFIRMED": {
          acc.confirmed += 1;
          break;
        }

        case "DISPATCHED": {
          acc.dispatched += 1;
          break;
        }
        case "CANCELLED": {
          acc.cancelled += 1;
          break;
        }

        default:
          break;
      }
      return acc;
    },
    {
      pendent: 0,
      confirmed: 0,
      dispatched: 0,
      cancelled: 0,
    }
  );
  console.log(resul);
  return (
    <Center>
      <Stack
        margin="10px"
        // minH="75vh"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          minW="200px"
          maxW="320px"
          w="full"
          bg={useColorModeValue("white", "gray.900")}
          boxShadow="2xl"
          rounded="lg"
          p={0}
          textAlign="center"
        >
          <Avatar
            size="xl"
            src={empreparoImage}
            mb={4}
            pos="relative"
            _after={{
              content: '""',
              w: 6,
              h: 6,
              bg: "green.300",
              border: "2px solid white",
              rounded: "full",
              pos: "absolute",
              bottom: 0,
              right: 3,
            }}
          />
          <Divider />
          <Heading
            fontSize="2xl"
            fontFamily="body"
            color="gray.500"
            margin="10px"
          >
            {resul.confirmed} Preparação
          </Heading>
          {/* <Divider />
          <Text fontSize="60" color="gray" mb={4}>
            x
          </Text> */}
        </Box>
        <Box
          minW="200px"
          maxW="320px"
          w="full"
          bg={useColorModeValue("white", "gray.900")}
          boxShadow="2xl"
          rounded="lg"
          p={0}
          textAlign="center"
        >
          <Avatar
            size="xl"
            src={emrotaImage}
            mb={4}
            pos="relative"
            _after={{
              content: '""',
              w: 6,
              h: 6,
              bg: "yellow.400",
              border: "2px solid white",
              rounded: "full",
              pos: "absolute",
              bottom: 0,
              right: 3,
            }}
          />
          <Divider />
          <Heading
            fontSize="2xl"
            fontFamily="body"
            color="gray.500"
            margin="10px"
          >
            {resul.dispatched} Em rota
          </Heading>
          {/* <Divider />
          <Text fontSize="60" color="gray" mb={4}>
            x
          </Text> */}
        </Box>
        <Box
          minW="200px"
          maxW="320px"
          w="full"
          bg={useColorModeValue("white", "gray.900")}
          boxShadow="2xl"
          rounded="lg"
          p={0}
          textAlign="center"
        >
          <Avatar
            size="xl"
            src={canceladoImage}
            mb={4}
            pos="relative"
            _after={{
              content: '""',
              w: 6,
              h: 6,
              bg: "red.600",
              border: "2px solid white",
              rounded: "full",
              pos: "absolute",
              bottom: 0,
              right: 3,
            }}
          />
          <Divider />
          <Heading
            fontSize="2xl"
            fontFamily="body"
            color="gray.500"
            margin="10px"
          >
            {resul.cancelled} Cancelados
          </Heading>
          {/* <Divider />
          <Text fontSize="60" color="gray" mb={4}>
            x
          </Text> */}
        </Box>
      </Stack>
    </Center>
  );
}
