import { Box, Center, Stack, Text } from "@chakra-ui/react";
import { useRecoilValue } from "recoil";

import { categoriesState } from "../store/atom";

import { CategoryPizza } from "./CategoryPizza";
import { CategoryItem } from "./CategoryItem";

function CategoriesContainer(): JSX.Element {
  const categories = useRecoilValue(categoriesState);

  if (categories.length === 0) {
    return (
      <Center>
        <Box
          w="450px"
          p={4}
          border="1px solid"
          borderColor="blue.400"
          borderRadius="md"
          textAlign="center"
          color="blue.400"
        >
          <Text>Menu vazio</Text>
          <Text> Comece configurando seu menu, adicionando categorias</Text>
        </Box>
      </Center>
    );
  }

  return (
    <Stack spacing="1rem">
      {categories.map((category) => {
        switch (category.type) {
          case "PIZZA":
            return <CategoryPizza key={category.id} category={category} />;

          case "DEFAULT":
            return <CategoryItem key={category.id} category={category} />;

          default:
            return <div key="default">Categoria inválida</div>;
        }
      })}
    </Stack>
  );
}

export { CategoriesContainer };
