/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-array-index-key */
import { CheckIcon } from "@chakra-ui/icons";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  Input,
  Button,
  Text,
  ModalFooter,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/react";
import { jsx } from "@emotion/react";
import React, { useEffect, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { formatPrice } from "../../../utils/formatPrice";
// interface FormData {
//   id: string;
//   name: string;
//   licensePlate: string;
//   daily: string;
//   phoneNumber: string;
// }

interface ModalUpdateOrderProps {
  isOpen: boolean;
  onClose: () => void;
  orderUpdate: any;
  handleUpdatePrint: (courier: any) => void;
}

function PaymentCash({
  additionalInfo,
  total,
}: {
  additionalInfo: string;
  total: number;
}): JSX.Element {
  if (additionalInfo !== "Sem troco") {
    const receberString = additionalInfo.split(" ")[2];
    const receber = Number(
      receberString.replace(/[^0-9,]*/g, "").replace(",", ".")
    );
    return (
      <>
        <Text>Receber em dinhero: {formatPrice(receber)} </Text>
        <Text>Levar de Troco : {formatPrice(receber - total)}</Text>
      </>
    );
  }
  return <Text>Sem Troco</Text>;
}

function ModalOrder({
  isOpen,
  onClose,
  orderUpdate,
  handleUpdatePrint,
}: ModalUpdateOrderProps): JSX.Element {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<any>();

  function cont(): void {
    const tela_impressao = window.open("about:blank");
    if (tela_impressao) {
      tela_impressao.document.write(
        `<div align=center margin-bottom="0px">Número do Pedido: ${orderUpdate.orderNumber}</div><hr>`
      );

      tela_impressao.document.write(
        `<div width=60 align=left>Cliente: ${orderUpdate.customer.name}</div>`
      );
      tela_impressao.document.write(
        `<div width=70 align=left>Telefone: ${orderUpdate.customer.phoneNumber}</div>`
      );
      orderUpdate.delivery?.address.addressFormatted &&
        tela_impressao.document.write(
          `<div width=70 align=left>Endereço: ${orderUpdate.delivery?.address.addressFormatted}</div>`
        );
      orderUpdate.delivery?.address.neighborhood &&
        tela_impressao.document.write(
          `<div width=70 align=left>Bairro: ${orderUpdate.delivery?.address.neighborhood}</div>`
        );
      orderUpdate.delivery?.address.city &&
        tela_impressao.document.write(
          `<div width=70 align=left>Cidade: ${orderUpdate.delivery?.address.city}</div>`
        );
      orderUpdate.delivery?.address.zipCode &&
        tela_impressao.document.write(
          `<div width=70 align=left>Cep: ${orderUpdate.delivery?.address.zipCode}</div><hr>`
        );

      tela_impressao.document.write(`qtd - ITEM<br>`);
      for (let i = 0; i < orderUpdate.itens.length; i += 1) {
        tela_impressao.document.write(
          `<div > ${orderUpdate.itens[i].quantity} - ${
            orderUpdate.itens[i].name
          } ${formatPrice(orderUpdate.itens[i].unitPrice)}</div>`
        );
        for (let j = 0; j < orderUpdate.itens[i].options.length; j += 1) {
          tela_impressao.document.write(
            `<div >${orderUpdate.itens[i].options[j].quantity} - ${
              orderUpdate.itens[i].options[j].name
            } ${formatPrice(orderUpdate.itens[i].options[j].unitPrice)}</div>`
          );
        }
        if (orderUpdate.itens[i].observations) {
          tela_impressao.document.write(
            `<div style="margin-left: 40px;">Observação: ${orderUpdate.itens[i].observations}</div><br>`
          );
        }
      }

      for (let i = 0; i < orderUpdate.pizzas.length; i += 1) {
        tela_impressao.document.write(
          `<div>${orderUpdate.pizzas[i].quantity} - ${
            orderUpdate.pizzas[i].size.name
          } ${orderUpdate.pizzas[i].size.numberOfFlavors}
         ${orderUpdate.pizzas[i].size.numberOfFlavors > 1 ? "Sabores" : "Sabor"}
          </div>`
        );
        for (let j = 0; j < orderUpdate.pizzas[i].flavors.length; j += 1) {
          tela_impressao.document.write(
            `<div style="margin-left: 20px;">
            
            ${
              orderUpdate.pizzas[i].size.numberOfFlavors > 1
                ? `1/${orderUpdate.pizzas[i].size.numberOfFlavors} `
                : "1 "
            }${orderUpdate.pizzas[i].flavors[j].name} ${formatPrice(
              orderUpdate.pizzas[i].flavors[j].price
            )}</div>`
          );
        }
        if (orderUpdate.pizzas[i].observations) {
          tela_impressao.document.write(
            `<div style="margin-left: 40px;">Observação: ${orderUpdate.pizzas[i].observations}</div><br>`
          );
        } else {
          tela_impressao.document.write(`<div><br></div>`);
        }
      }
      tela_impressao.document.write(
        ` <hr> <div align=center> Pagamento </div><br>`
      );
      tela_impressao.document.write(
        `<div width=60 align=left>Taxa de Entrega: ${formatPrice(
          orderUpdate.deliveryFee
        )}</div>`
      );
      tela_impressao.document.write(
        `<div width=60 align=left>  Pedido + Entrega: ${formatPrice(
          orderUpdate.subTotal
        )}</div>`
      );

      switch (orderUpdate.paymentMethod) {
        case "CASH": {
          if (orderUpdate.additionalInfo !== "Sem troco") {
            const receberString = orderUpdate.additionalInfo.split(" ")[2];
            const receber = Number(
              receberString.replace(/[^0-9,]*/g, "").replace(",", ".")
            );
            tela_impressao.document.write(
              `<div>Forma de Pagamento: Dinheiro</div>
              <div>Receber em dinhero : ${formatPrice(receber)}</div>
              <div>Levar de Troco : ${formatPrice(
                receber - orderUpdate.total
              )}</div>
              
              `
            );
          } else {
            tela_impressao.document.write(
              `<div>Forma de Pagamento: Dinheiro</div>
                <div>Sem Troco</div>`
            );
          }

          break;
        }

        case "DEBIT_CARD":
          tela_impressao.document.write(
            `<div>Forma de Pagamento: Debito</div>`
          );
          break;
        case "CREDIT_CARD":
          tela_impressao.document.write(
            `<div margin-bottom="0px">Forma de Pagamento: Credito</div>`
          );

          break;
        default:
          break;
      }

      // tela_impressao.document.write(
      //   `<div width=60 align=left>Pedido: ${formatPrice(
      //     orderUpdate.total
      //   )}</div>`
      // );

      tela_impressao.window.print();
      tela_impressao.window.close();
    }
  }
  const paymentSwitch = (): any => {
    switch (orderUpdate.paymentMethod) {
      case "CASH":
        return "Dinheiro ";
      case "DEBIT_CARD":
        return "Cartão de débito ";
      case "CREDIT_CARD":
        return "Cartão de crédito";
      default:
        return "foo";
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>
          <Button
            leftIcon={<CheckIcon />}
            colorScheme="blue"
            mt="24px"
            width="100%"
            marginBottom="8px"
            onClick={() => cont()}
          >
            imprimir
          </Button>
        </ModalHeader>
        <ModalBody>
          <Box margin="10px" id="box">
            <Text textColor="gray.400">
              -----------------------------------------------------------
              ------------- | Numero do Pedido: {orderUpdate.orderNumber}{" "}
              |-------------
              -----------------------------------------------------------
            </Text>

            <Text textColor="gray.400">
              {new Date(orderUpdate.createdAt).toString()}
            </Text>
            <Text textColor="gray.400">{orderUpdate.customer.name}</Text>
            <Text textColor="gray.400">{orderUpdate.customer.phoneNumber}</Text>
            <Text textColor="gray.400">
              {orderUpdate.delivery?.address.addressFormatted}
            </Text>
            <Text textColor="gray.400">
              {orderUpdate.delivery?.address.neighborhood}
            </Text>
            <Text textColor="gray.400">
              {orderUpdate.delivery?.address.reference}
            </Text>
            <Text textColor="gray.400">
              {orderUpdate.delivery?.address.city}
            </Text>
            <Text textColor="gray.400">
              {orderUpdate.delivery?.address.zipCode}
            </Text>
            <Text textColor="gray.400">
              -----------------------------------------------------------
              ---------------- | ------ -- Pedido: ----- |-------------
              -------------------------------------------------------------
            </Text>
            <Text textColor="gray.400">QTD&nbsp; ITEM</Text>
            <Text textColor="gray.400">
              {/* {orderUpdate.itens.quantity} - &nbsp;&nbsp;&nbsp;
              {orderUpdate.customer.name} */}
            </Text>
            {orderUpdate.itens.map((item: any) => (
              <div key={item.id} style={{ margin: " 10px" }}>
                {item.quantity}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {item.name}&nbsp; {formatPrice(item.unitPrice)}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div style={{ marginLeft: "60px" }}>
                  *** Observação: {item.observations}
                </div>
              </div>
            ))}

            {/* pizzas */}
            {orderUpdate.pizzas.map((pizzaArr: any) => (
              <div key={pizzaArr.id} style={{ margin: " 10px" }}>
                {pizzaArr.quantity}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {pizzaArr.size.name}&nbsp;{pizzaArr.size.numberOfFlavors} Sabor
                <div>
                  {pizzaArr.flavors.map((flavorArr: any, index: number) => (
                    <div key={index}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {pizzaArr.size.numberOfFlavors > 1
                        ? `1/${pizzaArr.size.numberOfFlavors}  ${flavorArr.name}`
                        : flavorArr.name}
                      &nbsp; {formatPrice(flavorArr.price)}
                    </div>
                  ))}
                  <div key={pizzaArr.edge.id}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {pizzaArr.edge.name}&nbsp;
                    {formatPrice(pizzaArr.edge.price)}
                  </div>
                </div>
                <div style={{ marginLeft: "60px" }}>
                  *** Observação: {pizzaArr.observations}
                </div>
              </div>
            ))}
            <br />
            <Text textColor="gray.400">
              -----------------------------------------------------------
              ---------------- | ------- Pagamento: ---- |-------------
              -----------------------------------------------------------
            </Text>
            <Text>Taxa de Entrega: {formatPrice(orderUpdate.deliveryFee)}</Text>
            <Text>Pedido + Entrega: {formatPrice(orderUpdate.total)}</Text>
            <Text>Forma de Pagamento: {paymentSwitch()}</Text>
            {/* {orderUpdate.additionalInfo && (
              <Text>{orderUpdate.additionalInfo}</Text>
            )} */}
            {orderUpdate.paymentMethod === "CASH" && (
              <PaymentCash
                additionalInfo={orderUpdate.additionalInfo}
                total={orderUpdate.total}
              />
            )}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export { ModalOrder };
