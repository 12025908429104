/* eslint-disable @typescript-eslint/no-use-before-define */
import { atom, selector } from "recoil";
import { useAuth } from "../../../hooks/auth";
import { api } from "../../../services/api";

interface TParameter {
  id?: string;
  value: number;
  time: number;
  distance: number;
}

interface TArea {
  id: string;
  name: string;
  color: string;
  coordinates: {
    lat: number;
    lng: number;
  }[];
}

// valores default
// const defaultParameters: TParameter[] = [
//   {
//     // id: "1",
//     value: 0,
//     time: undefined,
//     distance: 1000,
//   },
//   {
//     // id: "2",
//     value: 3,
//     time: 35,
//     distance: 2000,
//   },
//   {
//     // id: "3",
//     value: 4,
//     time: 40,
//     distance: 3000,
//   },
//   {
//     // id: "4",
//     value: 5,
//     time: 45,
//     distance: 4000,
//   },
//   {
//     // id: "5",
//     value: 6,
//     time: 50,
//     distance: 5000,
//   },
//   {
//     // id: "6",
//     value: 7,
//     time: 55,
//     distance: 6000,
//   },
// ];

const backendData = [
  {
    id: "761c2f36-53d8-47de-a326-932c1b567ecc",
    name: "Area-1",
    color: "red",
    coordinates: [
      [
        [-43.443359602, -21.684949922],
        [-43.440140951, -21.688658548],
        [-43.444432486, -21.692686087],
        [-43.447908629, -21.698667374],
        [-43.439239729, -21.699664231],
        [-43.437008131, -21.696035638],
        [-43.431772459, -21.695238133],
        [-43.427051771, -21.698069256],
        [-43.429111708, -21.701259187],
        [-43.435849417, -21.698747122],
        [-43.431987036, -21.703492097],
        [-43.424734343, -21.704169938],
        [-43.417009581, -21.700740828],
        [-43.424863089, -21.69491913],
        [-43.430742491, -21.689934612],
        [-43.433832396, -21.680922167],
        [-43.443359602, -21.684949922],
      ],
    ],
  },
  {
    id: "e974b2ee-c87b-438f-a398-d1754287cc51",
    name: "Area-2",
    color: "green",
    coordinates: [
      [
        [-43.445097674, -21.695357759],
        [-43.446728457, -21.702854126],
        [-43.43527006, -21.703691462],
        [-43.436686266, -21.697790134],
        [-43.445097674, -21.695357759],
      ],
    ],
  },
];

export const parametersState = atom<TParameter[]>({
  key: "parameters",
  default: selector({
    key: "parametersDefault",
    get: async () => {
      try {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { user } = useAuth();
        const response = await api.get(
          `/companies/${user.companyId}/delivery-parameters`
        );

        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
  }),
});

export const areasState = atom<TArea[]>({
  key: "areas",
  default: selector({
    key: "areasDefault",
    get: async () => {
      try {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { user } = useAuth();
        const response = await api.get(
          `/companies/${user.companyId}/delivery-areas`
        );

        return response.data.map((el: any) => ({
          ...el,
          coordinates: transform2(el.geom),
        }));
      } catch (error) {
        console.log(error);
        return [];
      }
    },
  }),

  // default: backendData.map((el) => ({
  //   ...el,
  //   coordinates: transform(
  //     el.coordinates[0].splice(1, el.coordinates[0].length - 1) // arruma isso aqui(postgis primeiro e ultimo )
  //   ),
  // })),
});

function transform(data: any): Array<{ lat: number; lng: number }> {
  if (data.length > 0) {
    return data.map((item: any) => ({ lat: item[1], lng: item[0] }));
  }
  return [];
}
function transform2(data: any): Array<{ lat: number; lng: number }> {
  const resp = data.coordinates[0];
  // retira ultimo vertice
  resp.length -= 1;

  return resp.map((item: any) => ({ lat: item[1], lng: item[0] }));
}

/**
 *     get: async () => {
      try {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { user } = useAuth();
        const response = await api.get(
          `/companies/${user.companyId}/delivery-areas`
        );
        console.log(response.data[0].geom.coordinates[0]);
        return response.data.map((el: any) => ({
          ...el,
          coordinates: transform(
            el.geom.coordinates[0].splice(el.coordinates[0].length - 2, 1) // arruma isso aqui(postgis primeiro e ultimo )
          ),
        }));
      } catch (error) {
        console.log(error);
        return [];
      }
    },
  }),
 */
