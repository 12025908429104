import { Dispatch, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  ModalFooter,
  Button,
  FormControl,
  Input,
  FormErrorMessage,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { RiDeleteBinLine } from "react-icons/ri";

import { useFieldArray } from "react-hook-form";
import { api } from "../../../services/api";

import { TEdge, TSize } from "../../../types";
import { formatStringInputToNumber } from "../../../utils/formatStringInputToNumber";
import { currencyMask } from "../../../utils/masks";
import { formatNumberToStringInput } from "../../../utils/formatNumberToStringInput";

interface EditConfigCategoryPizzaModalProps {
  isOpen: boolean;
  onClose: () => void;
  pizzaId: string;
  sizes: TSize[];
  setSizes: Dispatch<React.SetStateAction<TSize[]>>;
  edges: TEdge[];
  setEdges: Dispatch<React.SetStateAction<TEdge[]>>;
}

const sizesDefaut = [
  {
    name: "Pequena (25cm)",
    numberOfFlavors: 1,
    status: "AVAILABLE",
    index: 0,
  },
  {
    name: "Média (35cm)",
    numberOfFlavors: 2,
    status: "AVAILABLE",
    index: 1,
  },
  {
    name: "Grande (45cm)",
    numberOfFlavors: 2,
    status: "AVAILABLE",
    index: 2,
  },
];

const edgesDefault = [
  {
    name: "Borda Tradicional",
    price: " R$ 0,00",
    status: "AVAILABLE",
    index: 0,
  },
];

interface FormData {
  sizes: {
    name: string;
    numberOfFlavors: number;
    status: string;
    index: number;
  }[];

  edges: {
    name: string;
    price: string;
    status: string;
    index: number;
  }[];
}

const sizeSchema = {
  name: Yup.string().required("Campo obrigatório"),
  numberOfFlavors: Yup.number().required("Campo obrigatório"),
};

const edgeSchema = {
  name: Yup.string().required("Campo obrigatório"),
  price: Yup.string().required("Campo obrigatório"),
};

const schema = Yup.object().shape({
  sizes: Yup.array().of(Yup.object().shape(sizeSchema)),
  edges: Yup.array().of(Yup.object().shape(edgeSchema)),
});

function EditConfigCategoryPizzaModal({
  isOpen,
  onClose,
  pizzaId,
  sizes: sizesState,
  setSizes,
  edges: edgesState,
  setEdges,
}: EditConfigCategoryPizzaModalProps): JSX.Element {
  // mudar tab pelo button proximo
  const [tabIndex, setTabIndex] = useState(0);
  const toast = useToast();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<FormData>({
    defaultValues: {
      sizes: sizesState,
      edges: edgesState.map((edge) => ({
        name: edge.name,
        price: formatNumberToStringInput(edge.price),
        status: edge.status,
        index: edge.index,
      })),
    },
    resolver: yupResolver(schema),
  });

  const {
    fields: sizes,
    append: appendSize,
    remove: removeSize,
  } = useFieldArray({
    control,
    name: "sizes",
  });

  const {
    fields: edges,
    append: appendEdge,
    remove: removeEdge,
  } = useFieldArray({
    control,
    name: "edges",
  });

  function handleCloseModal(): void {
    reset();
    onClose();
  }

  async function handleCreateFormSubmit(data: FormData): Promise<void> {
    /** Estudar: se adicionar sizes e dar errado nas bordas */
    try {
      const response = await api.post(`/pizzas/${pizzaId}/sizes`, {
        sizes: data.sizes,
      });

      setSizes((state) => [...state, ...response.data]);

      const edgesFormatted = data.edges.map((edge) => ({
        ...edge,
        price: formatStringInputToNumber(edge.price),
      }));

      const edgesResponse = await api.post(`/pizzas/${pizzaId}/edges`, {
        edges: edgesFormatted,
      });

      setEdges((state) => [...state, ...edgesResponse.data]);

      toast({
        title: "Tamanhos e bordas adicionadas sucesso!",
        status: "success",
        position: "top-right",
        isClosable: true,
        duration: 2000,
      });

      handleCloseModal();
    } catch (error) {
      console.log(error);
      toast({
        title: "Algo deu errado, tente novamente!",
        status: "error",
        position: "top-right",
        isClosable: true,
        duration: 2000,
      });
    }
  }

  function handleChangeTabIndex(): void {
    switch (tabIndex) {
      case 0:
        setTabIndex((state) => state + 1);
        break;
      case 1:
        setTabIndex((state) => state - 1);
        break;
      default:
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      size="4xl"
      scrollBehavior="inside"
      onClose={() => {
        handleCloseModal();
      }}
    >
      <ModalOverlay />

      <ModalContent
        as="form"
        overflow="hidden"
        onSubmit={handleSubmit(handleCreateFormSubmit)}
      >
        <ModalHeader>Editar tamanhos e bordas</ModalHeader>
        <ModalCloseButton />
        <ModalBody py={0}>
          <Tabs index={tabIndex} onChange={(index) => setTabIndex(index)}>
            <TabList position="sticky" top="0" bg="white" zIndex="sticky">
              <Tab>Tamanhos</Tab>
              <Tab>Bordas</Tab>
            </TabList>

            <TabPanels overflow="auto" py={4}>
              {/* tamanhos */}
              <TabPanel p={0}>
                <TableContainer>
                  <Table variant="unstyled" size="sm" bg="white">
                    <Thead>
                      <Tr>
                        <Th>Nome</Th>
                        <Th>Quantidade de sabores</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {sizes.map((size, index) => (
                        // Esse id nao e do banco, fornecido pelo react-hook-form
                        <Tr key={size.id}>
                          <Td w="30%">
                            <FormControl
                              isInvalid={!!errors?.sizes?.[index]?.name}
                            >
                              <Input {...register(`sizes.${index}.name`)} />
                              <FormErrorMessage>
                                {errors?.sizes?.[index]?.name?.message}
                              </FormErrorMessage>
                            </FormControl>
                          </Td>
                          <Td w="10%">
                            <FormControl>
                              <NumberInput min={1} max={4}>
                                <NumberInputField
                                  readOnly
                                  {...register(
                                    `sizes.${index}.numberOfFlavors`,
                                    { valueAsNumber: true }
                                  )}
                                />
                                <NumberInputStepper>
                                  <NumberIncrementStepper />
                                  <NumberDecrementStepper />
                                </NumberInputStepper>
                              </NumberInput>
                            </FormControl>
                          </Td>
                          <Td>
                            <RiDeleteBinLine
                              cursor="pointer"
                              size={20}
                              onClick={() => removeSize(index)}
                            />
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                    <Tfoot>
                      <Tr>
                        <Th>
                          <Button
                            colorScheme="blue"
                            onClick={() =>
                              appendSize({
                                name: "",
                                numberOfFlavors: 1,
                                status: "AVAILABLE",
                                index: sizes.length,
                              })
                            }
                          >
                            + Adicionar Tamanho
                          </Button>
                        </Th>
                      </Tr>
                    </Tfoot>
                  </Table>
                </TableContainer>
              </TabPanel>
              {/* bordas */}
              <TabPanel p={0}>
                <TableContainer>
                  <Table variant="unstyled" size="sm" bg="white">
                    <Thead>
                      <Tr>
                        <Th>Nome</Th>
                        <Th>Preço</Th>
                        {/* <Th>Quantidade de sabores</Th> */}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {edges.map((edge, index) => (
                        // Esse id nao e do banco, fornecido pelo react-hook-form
                        <Tr key={edge.id}>
                          <Td w="30%">
                            <FormControl
                              isInvalid={!!errors?.edges?.[index]?.name}
                            >
                              <Input {...register(`edges.${index}.name`)} />
                              <FormErrorMessage>
                                {errors?.edges?.[index]?.name?.message}
                              </FormErrorMessage>
                            </FormControl>
                          </Td>
                          <Td w="20%">
                            <FormControl
                              isInvalid={!!errors?.edges?.[index]?.price}
                            >
                              <Input
                                {...register(`edges.${index}.price`)}
                                onChange={(e) => {
                                  const { value } = e.currentTarget;
                                  if (value === "R$") {
                                    e.currentTarget.value = "";
                                    return e;
                                  }
                                  e.currentTarget.value = `R$ ${currencyMask(
                                    value
                                  )}`;
                                  return e;
                                }}
                              />
                              <FormErrorMessage>
                                {errors?.edges?.[index]?.price?.message}
                              </FormErrorMessage>
                            </FormControl>
                          </Td>
                          <Td>
                            <RiDeleteBinLine
                              cursor="pointer"
                              size={20}
                              onClick={() => removeEdge(index)}
                            />
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                    <Tfoot>
                      <Tr>
                        <Th>
                          <Button
                            colorScheme="blue"
                            onClick={() =>
                              appendEdge({
                                name: "",
                                price: "",
                                status: "AVAILABLE",
                                index: edges.length,
                              })
                            }
                          >
                            + Adicionar Borda
                          </Button>
                        </Th>
                      </Tr>
                    </Tfoot>
                  </Table>
                </TableContainer>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            variant="outline"
            mr={3}
            onClick={() => handleCloseModal()}
          >
            Close
          </Button>
          {tabIndex < 1 && (
            <Button colorScheme="blue" onClick={() => handleChangeTabIndex()}>
              Próximo
            </Button>
          )}
          {tabIndex === 1 && (
            <Button colorScheme="blue" type="submit" isLoading={isSubmitting}>
              Salvar
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export { EditConfigCategoryPizzaModal };
