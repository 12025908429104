import {
  Flex,
  Heading,
  Stack,
  Text,
  Image,
  useToast,
  Input,
  Hide,
  FormControl,
  FormLabel,
  Button,
  Checkbox,
  Center,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import loginImage from "../../download/loginImage.png";
import { useAuth } from "../../hooks/auth";

interface FormData {
  email: string;
  password: string;
}

function SignIn(): JSX.Element {
  const { register, handleSubmit } = useForm<FormData>();
  const { signIn } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  async function handleFormSubmit(data: FormData): Promise<void> {
    try {
      await signIn({
        email: data.email,
        password: data.password,
      });

      navigate("/Dashboard", { replace: true });
    } catch (err) {
      toast({
        title: "Erro",
        description: `Usuário ou senha incorreto`,
        status: "warning",
        position: "top-right",
        isClosable: true,
      });
    }
  }
  return (
    <Stack minH="75vh" direction={{ base: "column", md: "row" }}>
      <Flex p={8} flex={1} align="center" justify="center">
        <Stack spacing={4} w="full" maxW="md">
          <Center>
            <Heading fontSize="4xl" textColor="blue.600">
              Entrar
            </Heading>
          </Center>
          <Center>
            <Text fontSize="lg" color="gray.600">
              Esse é seu painel de gerenciamento ✌️
            </Text>
          </Center>

          <FormControl>
            <FormLabel margin="0px" textColor="blue.400">
              Login
            </FormLabel>
            <Input {...register("email")} type="email" />
          </FormControl>

          <FormControl>
            <FormLabel textColor="blue.400" margin="0px">
              Senha
            </FormLabel>
            <Input {...register("password")} type="password" />
          </FormControl>
          <Stack spacing={6}>
            <Stack
              direction={{ base: "column", sm: "row" }}
              align="start"
              justify="space-between"
            >
              <Checkbox textColor="gray.400">Lembrar-me</Checkbox>
              <Button
                onClick={() => navigate("/Forgot")}
                colorScheme="teal"
                variant="link"
                size="xs"
              >
                Esqueci a senha
              </Button>
            </Stack>
            <Button
              onClick={handleSubmit(handleFormSubmit)}
              marginTop="20px"
              colorScheme="blue"
            >
              Entrar
            </Button>
          </Stack>
        </Stack>
        <Hide below="md">
          <Image maxW="600px" alt="login img" src={loginImage} />
        </Hide>
      </Flex>
    </Stack>
  );
}

export { SignIn };
