import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Text,
  Button,
  Tabs,
  Alert,
  AlertIcon,
  Center,
  TableContainer,
  Thead,
  Th,
  Tr,
  Table,
  Tbody,
  Td,
  Switch,
  Box,
  FormControl,
  Tfoot,
  TableCaption,
  Input,
} from "@chakra-ui/react";
import { HourTemplate } from "../Components/HourTemplate";

function Hours(): JSX.Element {
  return (
    <Box justifyContent="center" overflowX="auto">
      <Tabs isFitted>
        <TabList margin="8">
          <Tab fontSize="20px">Horário de Funcionamento</Tab>
          <Tab fontSize="20px">Pausa/Folga</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <TableContainer marginLeft="4">
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th>
                      <Center>
                        <Text
                          fontFamily="sans-serif"
                          fontSize="lg"
                          colorScheme="blue.500"
                        >
                          Dia
                        </Text>
                      </Center>
                    </Th>
                    <Th>
                      <Center>
                        <Text
                          fontFamily="sans-serif"
                          fontSize="lg"
                          colorScheme="blue.500"
                        >
                          Aberto
                        </Text>
                      </Center>
                    </Th>
                    <Th>
                      <Center>
                        <Text
                          fontFamily="sans-serif"
                          fontSize="lg"
                          colorScheme="blue.500"
                        >
                          Abertura
                        </Text>
                      </Center>
                    </Th>
                    <Center>
                      <Th>
                        {" "}
                        <Center>
                          <Text
                            fontFamily="sans-serif"
                            fontSize="lg"
                            colorScheme="blue.500"
                          >
                            Fechamento
                          </Text>
                        </Center>
                      </Th>
                    </Center>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>
                      <Center>Domingo</Center>
                    </Td>
                    <Td>
                      <FormControl>
                        <Center>
                          <Switch id="restauranteisOpen" />{" "}
                        </Center>
                      </FormControl>
                    </Td>
                    <Td>
                      <HourTemplate />
                    </Td>
                    <Td>
                      <HourTemplate />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Center>Segunda</Center>
                    </Td>
                    <Td>
                      <FormControl>
                        <Center>
                          <Switch id="restauranteisOpen" />
                        </Center>
                      </FormControl>
                    </Td>
                    <Td>
                      <HourTemplate />
                    </Td>
                    <Td>
                      <HourTemplate />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Center>Terca</Center>
                    </Td>
                    <Td>
                      <FormControl>
                        <Center>
                          <Switch id="restauranteisOpen" />
                        </Center>
                      </FormControl>
                    </Td>
                    <Td>
                      <HourTemplate />
                    </Td>
                    <Td>
                      <HourTemplate />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Center>Quarta</Center>
                    </Td>
                    <Td>
                      <FormControl>
                        <Center>
                          <Switch id="restauranteisOpen" />
                        </Center>
                      </FormControl>
                    </Td>
                    <Td>
                      <HourTemplate />
                    </Td>
                    <Td>
                      <HourTemplate />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Center>Quinta</Center>
                    </Td>
                    <Td>
                      <FormControl>
                        <Center>
                          <Center>
                            <Switch id="restauranteisOpen" />
                          </Center>
                        </Center>
                      </FormControl>
                    </Td>
                    <Td>
                      <HourTemplate />
                    </Td>
                    <Td>
                      <HourTemplate />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Center>Sexta</Center>
                    </Td>
                    <Td>
                      <FormControl>
                        <Center>
                          <Switch id="restauranteisOpen" />
                        </Center>
                      </FormControl>
                    </Td>
                    <Td>
                      <HourTemplate />
                    </Td>
                    <Td>
                      <HourTemplate />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Center>Sábado</Center>
                    </Td>
                    <Td>
                      <FormControl>
                        <Center>
                          <Switch id="restauranteisOpen" />
                        </Center>
                      </FormControl>
                    </Td>
                    <Td>
                      <HourTemplate />
                    </Td>
                    <Td>
                      <HourTemplate />
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel>
            <Alert status="info">
              <AlertIcon />
              Cadastre uma pausa no horario de funcionamento do seu restaurante.
            </Alert>
            <Center margin="6px">
              <Button colorScheme="blue" size="md" height="48px" width="200px">
                Cadastrar
              </Button>
            </Center>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export { Hours };
