import {
  VStack,
  HStack,
  Input,
  Flex,
  Button,
  InputGroup,
  InputLeftElement,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { RiDeleteBinLine } from "react-icons/ri";
import { api } from "../../../../services/api";

import { currencyMask } from "../../../../utils/masks";
import { EditItemFormData } from "./index";

interface OptionsProps {
  index: number;
  complementId: string | undefined;
}
function Options({ index, complementId }: OptionsProps): JSX.Element {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<EditItemFormData>();
  const {
    fields: options,
    append,
    remove,
  } = useFieldArray({
    control,
    name: `complements.${index}.options`,
  });

  async function handleOnClickRemove(
    productId: string | undefined,
    optionIndexRemoved: number
  ): Promise<void> {
    // se productId = undefined, apagando uma option que nao foi salva ainda
    // no banco de dados
    if (!productId) {
      remove(optionIndexRemoved);
    } else {
      // delete no banco e depois remove do form
      try {
        await api.delete(
          `/complements/${complementId}/products/${productId}/options`
        );
        remove(optionIndexRemoved);
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <>
      <VStack>
        {options.map((option, optionIndex) => (
          <HStack key={option.id}>
            <FormControl
              isInvalid={
                !!errors?.complements?.[index]?.options?.[optionIndex]?.name
              }
            >
              <Input
                placeholder="Nome"
                {...register(
                  `complements.${index}.options.${optionIndex}.name` as const
                )}
              />

              <FormErrorMessage>
                {
                  errors?.complements?.[index]?.options?.[optionIndex]?.name
                    ?.message
                }
              </FormErrorMessage>
            </FormControl>

            <FormControl>
              <Input
                {...register(
                  `complements.${index}.options.${optionIndex}.description` as const
                )}
                placeholder="Descrição"
              />
            </FormControl>

            <FormControl
              isInvalid={
                !!errors?.complements?.[index]?.options?.[optionIndex]
                  ?.originalPrice
              }
            >
              <InputGroup>
                <InputLeftElement pointerEvents="none" color="gray.400">
                  R$
                </InputLeftElement>
                <Input
                  {...register(
                    `complements.${index}.options.${optionIndex}.originalPrice` as const
                  )}
                  placeholder="Preço"
                  onChange={(e) => {
                    const { value } = e.currentTarget;
                    e.currentTarget.value = currencyMask(value);
                    return e;
                  }}
                />
              </InputGroup>
              <FormErrorMessage>
                {
                  errors?.complements?.[index]?.options?.[optionIndex]
                    ?.originalPrice?.message
                }
              </FormErrorMessage>
            </FormControl>
            <Input
              {...register(
                `complements.${index}.options.${optionIndex}.cod` as const
              )}
              placeholder="Cod"
            />
            <Flex>
              <RiDeleteBinLine
                size={20}
                cursor="pointer"
                onClick={() =>
                  handleOnClickRemove(option.productId, optionIndex)
                }
              />
            </Flex>
          </HStack>
        ))}
      </VStack>
      <Button
        w="30%"
        mt={4}
        colorScheme="blue"
        onClick={() =>
          append({ name: "", description: "", originalPrice: "", cod: "" })
        }
      >
        + Adicionar Opções
      </Button>
    </>
  );
}

export { Options };
