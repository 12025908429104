import {
  VStack,
  HStack,
  Input,
  Flex,
  Button,
  InputGroup,
  InputLeftElement,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { RiDeleteBinLine } from "react-icons/ri";

import { currencyMask } from "../../../utils/masks";
import { CreateItemFormData } from "../modals/CreateItemModal";

interface OptionsProps {
  // register: UseFormRegister<CreateItemFormData>;
  // control: Control<CreateItemFormData>;
  // setValue: UseFormSetValue<CreateItemFormData>;
  // getValues: UseFormGetValues<CreateItemFormData>;
  index: number;
}
function Options({ index }: OptionsProps): JSX.Element {
  const {
    register,
    control,

    formState: { errors },
  } = useFormContext<CreateItemFormData>();
  const {
    fields: options,
    append,
    remove,
  } = useFieldArray({
    control,
    name: `complements.${index}.options`,
  });
  return (
    <>
      <VStack>
        {options.map((option, optionIndex) => (
          <HStack key={option.id}>
            <FormControl
              isInvalid={
                !!errors?.complements?.[index]?.options?.[optionIndex]?.name
              }
            >
              <Input
                placeholder="Nome"
                {...register(
                  `complements.${index}.options.${optionIndex}.name` as const
                )}
              />

              <FormErrorMessage>
                {
                  errors?.complements?.[index]?.options?.[optionIndex]?.name
                    ?.message
                }
              </FormErrorMessage>
            </FormControl>

            <FormControl>
              <Input
                {...register(
                  `complements.${index}.options.${optionIndex}.description` as const
                )}
                placeholder="Descrição"
              />
            </FormControl>

            <FormControl
              isInvalid={
                !!errors?.complements?.[index]?.options?.[optionIndex]
                  ?.originalPrice
              }
            >
              <InputGroup>
                <InputLeftElement pointerEvents="none" color="gray.400">
                  R$
                </InputLeftElement>
                <Input
                  {...register(
                    `complements.${index}.options.${optionIndex}.originalPrice` as const
                  )}
                  placeholder="Preço"
                  onChange={(e) => {
                    const { value } = e.currentTarget;
                    e.currentTarget.value = currencyMask(value);
                    return e;
                  }}
                />
              </InputGroup>
              <FormErrorMessage>
                {
                  errors?.complements?.[index]?.options?.[optionIndex]
                    ?.originalPrice?.message
                }
              </FormErrorMessage>
            </FormControl>
            <Input
              {...register(
                `complements.${index}.options.${optionIndex}.cod` as const
              )}
              placeholder="Cod"
            />
            <Flex>
              <RiDeleteBinLine size={20} onClick={() => remove(optionIndex)} />
            </Flex>
          </HStack>
        ))}
      </VStack>
      <Button
        w="30%"
        mt={4}
        colorScheme="blue"
        onClick={() =>
          append({ name: "", description: "", originalPrice: "", cod: "" })
        }
      >
        + Adicionar Opções
      </Button>
    </>
  );
}

export { Options };
