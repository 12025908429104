import { useForm } from "react-hook-form";
import { DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Input,
  Tab,
  TabList,
  useToast,
  TabPanel,
  TabPanels,
  FormControl,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { FaFacebook, FaTiktok } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { SiGoogleanalytics } from "react-icons/si";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { api } from "../../services/api";
import { useAuth } from "../../hooks/auth";

interface FormMarketing {
  facebook: string | null;
  google: string | null;
  tiktok: string | null;
  googleTagManager: string | null;
}

const baseMarketing = {
  facebook: null,
  tiktok: null,
  google: null,
  googleTagManager: null,
};

function Integration(): JSX.Element {
  const { user } = useAuth();
  // const [ads, setAds] = useState<FormMarketing>({} as FormMarketing);
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<FormMarketing>();
  const toast = useToast();
  const myWatch = watch();

  useEffect(() => {
    api.get(`/companies/${user.companyId}/ads`).then((resp) => {
      setValue("facebook", resp.data.facebook);
      setValue("google", resp.data.google);
      setValue("tiktok", resp.data.tiktok);
      setValue("googleTagManager", resp.data.googleTagManager);
    });
  }, [user.companyId]);

  async function handleFormSubmit(data: any): Promise<void> {
    await api.put(`/companies/${user.companyId}/ads`, data).then((response) =>
      toast({
        title: "Sucesso",
        description: `Pixel Cadastrado`,
        status: "success",
        position: "top-right",
        isClosable: true,
      })
    );
  }

  return (
    <>
      <Center>
        <Heading textColor="primary.500" marginTop="20px">
          Configuração de Pixel
        </Heading>
      </Center>
      <Center>
        <Text textColor="gray.400">
          Salve a configuração após inserir o evento.
        </Text>
      </Center>
      <Center marginTop="20px">
        <Box as="form" onSubmit={handleSubmit(handleFormSubmit)}>
          <Tabs>
            <TabList p="4px">
              <Tab>
                <FaFacebook />

                <Text marginLeft="4px">Facebook</Text>
              </Tab>
              <Tab>
                <FcGoogle />

                <Text marginLeft="4px">GoogleAds</Text>
              </Tab>
              <Tab>
                <FaTiktok color="purple" />
                <Text marginLeft="4px">TikTok</Text>
              </Tab>

              <Tab>
                <SiGoogleanalytics color="orange" />
                <Text marginLeft="4px">Google Tag Manager</Text>
              </Tab>
            </TabList>
            <TabPanels p="10px">
              <TabPanel>
                <Flex>
                  <Input
                    placeholder="Digite o código seu Pixel"
                    {...register("facebook")}
                  />
                  {myWatch.facebook && (
                    <Button
                      colorScheme="red"
                      onClick={() => setValue("facebook", null)}
                    >
                      Deletar
                    </Button>
                  )}
                </Flex>
              </TabPanel>
              <TabPanel>
                <Flex>
                  <Input
                    placeholder="Digite o código seu Pixel"
                    {...register("google")}
                  />
                  {myWatch.google && (
                    <Button
                      colorScheme="red"
                      onClick={() => setValue("google", null)}
                    >
                      Deletar
                    </Button>
                  )}
                </Flex>
              </TabPanel>
              <TabPanel>
                <Flex>
                  <Input
                    placeholder="Digite o código seu Pixel"
                    {...register("tiktok")}
                  />
                  {myWatch.tiktok && (
                    <Button
                      colorScheme="red"
                      onClick={() => setValue("tiktok", null)}
                    >
                      Deletar
                    </Button>
                  )}
                </Flex>
              </TabPanel>
              <TabPanel>
                <Flex>
                  <Input
                    placeholder="Digite o código seu Pixel"
                    {...register("googleTagManager")}
                  />
                  {myWatch.googleTagManager && (
                    <Button
                      colorScheme="red"
                      onClick={() => setValue("googleTagManager", null)}
                    >
                      Deletar
                    </Button>
                  )}
                </Flex>
              </TabPanel>
            </TabPanels>
          </Tabs>
          <Center>
            <Button type="submit" colorScheme="primary" width="70%">
              Salvar Configurações
            </Button>
          </Center>
        </Box>
      </Center>
    </>
  );
}
export { Integration };
