import { useState } from "react";
import {
  Flex,
  Button,
  Text,
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Center,
  useDisclosure,
  Img,
  Switch,
  useToast,
} from "@chakra-ui/react";
import { MdAdd, MdEdit } from "react-icons/md";

import { CategoryPizzaHeader } from "./CategoryPizzaHeader";
import { TCategory, TEdge, TFlavor, TSize } from "../../../types";
import { ConfigCategoryPizzaModal } from "../modals/ConfigCategoryPizzaModal";
import { CreatePizzaFlavorModal } from "../modals/CreatePizzaFlavorModal";
import { formatPrice } from "../../../utils/formatPrice";
import { api } from "../../../services/api";
import { UpdatePizzaFlavorModal } from "../modals/UpdatePizzaFlavorModal";

interface ICategoryPizzaProps {
  category: TCategory;
}

function CategoryPizza({ category }: ICategoryPizzaProps): JSX.Element {
  const { pizza } = category;

  const [flavors, setFlavors] = useState<TFlavor[]>(() => pizza.flavors);
  const [sizes, setSizes] = useState<TSize[]>(() => pizza.sizes);
  const [edges, setEdges] = useState<TEdge[]>(() => pizza.edges);
  const [flavorToUpDate, setFlavorToUpDate] = useState<TFlavor>();
  const toast = useToast();

  const {
    isOpen: isOpenConfigCategoryPizzaModal,
    onOpen: onOpenConfigCategoryPizzaModal,
    onClose: onCloseConfigCategoryPizzaModal,
  } = useDisclosure();

  const {
    isOpen: isOpenCreatePizzaFlavorModal,
    onOpen: onOpenCreatePizzaFlavorModal,
    onClose: onCloseCreatePizzaFlavorModal,
  } = useDisclosure();

  const {
    isOpen: isOpenUpdatePizzaFlavorModal,
    onOpen: onOpenUpdatePizzaFlavorModal,
    onClose: onCloseUpdatePizzaFlavorModal,
  } = useDisclosure();

  async function handleChangeFlavorStatus(flavorId: string): Promise<void> {
    try {
      const index = flavors.findIndex((flavor) => flavor.id === flavorId);

      const newStatus =
        flavors[index].status === "AVAILABLE" ? "UNAVAILABLE" : "AVAILABLE";

      await api.patch(`flavors/${flavorId}/status`, { status: newStatus });

      setFlavors((state) => [
        ...state.slice(0, index),
        { ...flavors[index], status: newStatus },
        ...state.slice(index + 1),
      ]);

      toast({
        title: "Status alterado com sucesso!",
        status: "success",
        position: "top-right",
        isClosable: true,
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Algo deu errado, tente novamente",
        status: "error",
        position: "top-right",
        isClosable: true,
      });
    }
  }

  return (
    <>
      <Box border="1px solid #d5d5d5">
        <CategoryPizzaHeader
          category={category}
          sizes={sizes}
          setSizes={setSizes}
          edges={edges}
          setEdges={setEdges}
        />
        <Flex
          p="1rem 0"
          flexDirection="column"
          borderTop="1px solid #d5d5d5"
          // display="none"
        >
          {edges.length !== 0 && sizes.length !== 0 ? (
            <TableContainer>
              <Table variant="simple" size="sm">
                <Thead>
                  <Tr>
                    <Th>Sabor</Th>
                    <Th>Descrição</Th>
                    <Th>Preço</Th>
                    <Th>Status</Th>
                    <Th>Ações</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {flavors.map((flavor) => (
                    <Tr key={flavor.id}>
                      <Td w="20%">
                        <Flex gap={4} alignItems="center">
                          <Img
                            src={flavor.urlImage}
                            boxSize={12}
                            borderRadius="md"
                          />
                          {flavor.name}
                        </Flex>
                      </Td>
                      <Td whiteSpace="normal" w="30%">
                        {flavor.description}
                      </Td>
                      <Td>
                        Apartir de{" "}
                        {formatPrice(
                          flavor.prices.reduce(
                            (acc, value) => Math.min(acc, value.originalPrice),
                            Infinity
                          )
                        )}
                      </Td>
                      <Td>
                        <Switch
                          colorScheme="primary"
                          defaultChecked={flavor.status === "AVAILABLE"}
                          onChange={() => handleChangeFlavorStatus(flavor.id)}
                        >
                          {flavor.status === "AVAILABLE"
                            ? "Disponível"
                            : "Pausado"}
                        </Switch>
                      </Td>
                      <Td>
                        <MdEdit
                          size={18}
                          cursor="pointer"
                          onClick={() => {
                            setFlavorToUpDate(flavor);
                            onOpenUpdatePizzaFlavorModal();
                          }}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          ) : (
            <Center display="flex" flexDir="column">
              <Text>Configure os tamanhos e bordas da pizza</Text>
              <Button
                colorScheme="blue"
                variant="outline"
                onClick={onOpenConfigCategoryPizzaModal}
              >
                Configurar
              </Button>
            </Center>
          )}
          <Button
            width="20%"
            ml={4}
            mt={4}
            leftIcon={<MdAdd size={20} />}
            colorScheme="primary"
            variant="outline"
            disabled={!(edges.length !== 0 && sizes.length !== 0)}
            onClick={onOpenCreatePizzaFlavorModal}
          >
            Adicionar Sabores
          </Button>
        </Flex>
      </Box>
      <ConfigCategoryPizzaModal
        isOpen={isOpenConfigCategoryPizzaModal}
        onClose={onCloseConfigCategoryPizzaModal}
        pizzaId={pizza.id}
        setSizes={setSizes}
        setEdges={setEdges}
      />
      <CreatePizzaFlavorModal
        isOpen={isOpenCreatePizzaFlavorModal}
        onClose={onCloseCreatePizzaFlavorModal}
        sizes={sizes}
        pizzaId={pizza.id}
        setFlavors={setFlavors}
        flavorIndex={flavors.length}
      />

      {flavorToUpDate && (
        <UpdatePizzaFlavorModal
          isOpen={isOpenUpdatePizzaFlavorModal}
          onClose={onCloseUpdatePizzaFlavorModal}
          sizes={sizes}
          pizzaId={pizza.id}
          setFlavors={setFlavors}
          flavorToUpdate={flavorToUpDate}
          flavorIndex={flavors.length}
        />
      )}
    </>
  );
}

export { CategoryPizza };
