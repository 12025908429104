import { Flex, Input, Select } from "@chakra-ui/react";

function HourTemplate(): JSX.Element {
  const Hours = Array.from({ length: 24 }, (x, i) => i);
  const Minutes = Array.from({ length: 60 }, (x, i) => i);

  return (
    <Flex alignItems="center">
      <Select size="xs" colorScheme="blue" iconSize="undefined">
        {Hours.map((Hourr, index) => (
          <option value={index}>{Hourr}</option>
        ))}
      </Select>
      <p>:</p>

      <Select size="xs" iconSize="xs">
        {Minutes.map((Min, index) => (
          <option value={index}>{Min}</option>
        ))}
      </Select>
    </Flex>
  );
}

export { HourTemplate };
