import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { api } from "../../../services/api";

export default function Payment({
  url,
  startDate,
  endDate,
}: {
  url: string;
  startDate: string;
  endDate: string;
}): JSX.Element {
  const [amout, setAmout] = useState<number[]>([]);
  const [forms, setForms] = useState<string[]>([]);

  // console.log(startDate);
  useEffect(() => {
    // console.log(url);
    api
      .get(`${url}/dashboard/payment-methods`, {
        params: {
          startDate,
          endDate,
        },
      })
      .then((resp) => {
        // console.log(resp.data);

        setAmout(resp.data.map((item: any) => Math.round(item.amount)));
        setForms(resp.data.map((item: any) => item.name));
      });
  }, [endDate, startDate, url]);

  const series = [
    {
      name: "amout",
      data: amout,
    },
  ];
  const options = {
    chart: { id: "teste" },
    xaxis: { categories: forms },
    plotOptions: {
      bar: {
        columnWidth: "30%",
        endingShape: "flat",
        dataLabels: {
          position: "top",
        },
      },
    },
  };

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <Chart options={options} series={series} type="bar" width="500" />
        </div>
      </div>
    </div>
  );
}
