/* eslint-disable react/jsx-no-bind */
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Button,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  RadioGroup,
  Radio,
  HStack,
  VStack,
  Text,
  useToast,
} from "@chakra-ui/react";

import { useForm, Controller } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { useAuth } from "../../../hooks/auth";
import { api } from "../../../services/api";
import { TCategory } from "../../../types";
import { categoriesState } from "../store/atom";

interface CreateCategoryModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface FormData {
  name: string;
  type: "PIZZA" | "DEFAULT";
}

function CreateCategoryModal({
  isOpen,
  onClose,
}: CreateCategoryModalProps): JSX.Element {
  const setCategories = useSetRecoilState(categoriesState);
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<FormData>();
  const { user } = useAuth();
  const toast = useToast();

  function closeResetForm(): void {
    reset();
    onClose();
  }

  async function handleFormSubmit(data: FormData): Promise<void> {
    try {
      const response = await api.post(
        `/companies/${user.companyId}/categories`,
        data
      );
      const newCategory = response.data;

      if (data.type === "PIZZA") {
        // cria automaticamente o produto pizza
        const pizzaResponse = await api.post(
          `/company/${user.companyId}/category/${newCategory.id}`
        );

        const pizza = pizzaResponse.data;
        newCategory.pizza = pizza;
      }

      setCategories((oldCategories) => [newCategory, ...oldCategories]);
      toast({
        title: "Categoria criada com sucesso!",
        status: "success",
        position: "top-right",
        isClosable: true,
      });

      closeResetForm();
    } catch (error) {
      console.log(error);
      toast({
        title: "Algo deu errado, tente novamente!",
        status: "error",
        position: "top-right",
        isClosable: true,
      });
    }
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(handleFormSubmit)}>
        <ModalHeader>Nova Categoria</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack>
            <FormControl isInvalid={!!errors.name}>
              <FormLabel>Nome</FormLabel>
              <Input
                {...register("name", { required: "Campo obrigatório." })}
              />
              {errors.name && (
                <Text color="red.300">{errors.name.message}</Text>
              )}
            </FormControl>
            <FormControl isInvalid={!!errors.type}>
              <FormLabel>Escolha o Tipo</FormLabel>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <HStack>
                      <Radio value="PIZZA">Pizzas</Radio>
                      <Radio value="DEFAULT">Outros</Radio>
                    </HStack>
                  </RadioGroup>
                )}
                rules={{ required: true }}
              />
              {errors.name && <Text color="red.300">Escolha um tipo.</Text>}
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="primary"
            variant="outline"
            mr={3}
            onClick={closeResetForm}
          >
            Fechar
          </Button>
          <Button type="submit" colorScheme="primary" isLoading={isSubmitting}>
            Adicionar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export { CreateCategoryModal };
