import { atom, selector } from "recoil";
import { useAuth } from "../../../hooks/auth";
import { api } from "../../../services/api";
import { Schedule, TOpeningHour } from "../../../types";
import { transformOpeningHours } from "../../../utils/tranformOpeningHours";

export const openingHoursState = atom<Schedule[]>({
  key: "openingHours",
  default: selector({
    key: "openingHoursDefault",
    get: async () => {
      try {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { user } = useAuth();
        const response = await api.get<TOpeningHour[]>(
          `/companies/${user.companyId}/opening-hours`
        );
        const data: Schedule[] = [];
        response.data.forEach((item) => {
          transformOpeningHours(item, data);
        });

        return data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
  }),
});
