import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
// https://www.smashingmagazine.com/2020/11/data-visualization-apexcharts/
import { api } from "../../../services/api";

const retornoBanco = [
  { category: "Motoboy", revenue: 1250.0 },
  { category: "Compras", revenue: 1050.0 },
  { category: "Funcionários", revenue: 550.0 },
  { category: "Freelance", revenue: 50.0 },
];

export default function ExpensiveCategories({
  url,
  startDate,
  endDate,
}: {
  url: string;
  startDate: string;
  endDate: string;
}): JSX.Element {
  const [category, setCategory] = useState<number[]>([]);
  const [categoryValue, setCategoryValue] = useState<number[]>([]);

  useEffect(() => {
    api.get(`${url}/transactions`).then((resp) => {
      setCategory(retornoBanco.map((item: any) => item.category));
      setCategoryValue(retornoBanco.map((item: any) => item.revenue));
    });
  }, [url]);

  const state = {
    series: [
      {
        data: categoryValue,
      },
    ],
    options: {
      xaxis: {
        categories: category,
      },

      plotOptions: {
        bar: {
          distributed: true,
          horizontal: true,
          startingShape: "flat",
          endingShape: "rounded",
          dataLabels: {
            position: "bottom",
          },
        },
      },
    },
  };

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <Chart
            options={state.options}
            series={state.series}
            type="bar"
            width="500"
          />
        </div>
      </div>
    </div>
  );
}
