/* eslint-disable react/jsx-no-bind */
import {
  Flex,
  Button,
  Text,
  useDisclosure,
  Switch,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
} from "@chakra-ui/react";
import React, { ChangeEvent } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useSetRecoilState } from "recoil";
import { useAuth } from "../../../hooks/auth";
import { api } from "../../../services/api";
import { TCategory } from "../../../types";
import { EditCategoryModal } from "../modals/EditCategoryModal";
import { categoriesState } from "../store/atom";

interface ICategoryItemHeaderProps {
  category: TCategory;
}
function CategoryItemHeader({
  category,
}: ICategoryItemHeaderProps): JSX.Element {
  const { name, status, id } = category;
  const setCategories = useSetRecoilState(categoriesState);
  const toast = useToast();
  const { user } = useAuth();
  const {
    isOpen: isOpenEditCategoryModal,
    onClose: onCloseEditCategoryModal,
    onOpen: onOpenEditCategoryModal,
  } = useDisclosure();

  async function handleChangeStatusCategory(
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> {
    const newStatus = e.target.checked === true ? "AVAILABLE" : "UNAVAILABLE";
    const response = await api.patch(
      `/companies/${user.companyId}/categories/${id}`,
      { status: newStatus }
    );

    setCategories((state) =>
      state.map((cat) => {
        if (cat.id === id) {
          return {
            ...cat,
            status: response.data.status,
          };
        }
        return cat;
      })
    );

    toast({
      title: "Status alterado com sucesso!",
      status: "success",
      position: "top-right",
      isClosable: true,
    });
  }

  return (
    <>
      <Flex
        p="0.5rem 1rem"
        align="center"
        justifyContent="space-between"
        bg="gray.100"
        color="#000"
      >
        <Text>{name}</Text>
        <Flex alignItems="center" align="center" justifyContent="center">
          <Switch
            mr="1rem"
            colorScheme="primary"
            defaultChecked={status === "AVAILABLE"}
            onChange={handleChangeStatusCategory}
          >
            {category.status === "AVAILABLE" ? "Disponível" : "Pausado"}
          </Switch>

          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<BiDotsVerticalRounded size={25} />}
              bg="transparent"
              _hover={{ bg: "transparent" }}
              _active={{ bg: "transparent" }}
            />
            <MenuList>
              <MenuItem onClick={onOpenEditCategoryModal}>
                Editar categoria
              </MenuItem>

              <MenuItem>Deletar categoria</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>

      <EditCategoryModal
        isOpen={isOpenEditCategoryModal}
        onClose={onCloseEditCategoryModal}
        category={category}
      />
    </>
  );
}

export { CategoryItemHeader };
