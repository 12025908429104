import {
  AddIcon,
  ArrowBackIcon,
  CheckIcon,
  DeleteIcon,
  EditIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Text,
  Heading,
  Button,
  Center,
  Table,
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Input,
  useDisclosure,
  VStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Switch,
  useToast,
} from "@chakra-ui/react";

function TestePrint(): JSX.Element {
  function cont(): void {
    const conteudo =
      "Atenção isso é um aviso. Estou vendo vocês pela camera de segurança.Caso não depositem um pix de 2.500 reais vou atras de vocês. Eu sei onde vocês moram.";
    const tela_impressao = window.open("about:blank");
    if (tela_impressao) {
      tela_impressao.document.write(conteudo);
      tela_impressao.window.print();
      tela_impressao.window.close();
    }
  }
  return (
    <>
      <div id="print" className="conteudo">
        <p>Vamos trabalhar ai</p>
      </div>
      <button type="button" onClick={() => cont()}>
        imprimir
      </button>
    </>
  );
}

export { TestePrint };
