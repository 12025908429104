import Chart from "react-apexcharts";
import { useEffect, useState } from "react";
import { api } from "../../../services/api";

const receitadespesa = [
  { data: "11-10", revenue: 1250.0, expense: 70.0 },
  { data: "11-10", revenue: 1500.0, expense: 250.0 },
  { data: "11-10", revenue: 125.0, expense: 10.0 },
  { data: "11-10", revenue: 500.0, expense: 100.0 },
  { data: "11-10", revenue: 800.0, expense: 0.0 },
  { data: "11-10", revenue: 1521.0, expense: 159.0 },
  { data: "11-10", revenue: 125.0, expense: 10.0 },
];

export default function RevenueExpense({
  url,
  startDate,
  endDate,
}: {
  url: string;
  startDate: string;
  endDate: string;
}): JSX.Element {
  const [revenueExpenseDate, setRevenueExpenseDate] = useState<number[]>([]);
  const [revenueExpenseRevenue, setRevenueExpenseRevenue] = useState<number[]>(
    []
  );
  const [revenueExpenseExpense, setRevenueExpenseExpense] = useState<number[]>(
    []
  );

  useEffect(() => {
    api.get(`${url}/resume`).then((resp) => {
      setRevenueExpenseDate(receitadespesa.map((item: any) => item.data));
      setRevenueExpenseRevenue(
        receitadespesa.map((item: any) => Math.round(item.revenue))
      );
      setRevenueExpenseExpense(
        receitadespesa.map((item: any) => Math.round(item.expense))
      );
    });
  }, [url]);

  const state = {
    series: [
      {
        name: "Receitas",
        data: revenueExpenseRevenue,
      },
      {
        name: "Despesas",
        data: revenueExpenseExpense,
      },
    ],
    options: {
      colors: ["#008ffbd9", "#ff8c00"],
      plotOptions: {
        bar: {
          columnWidth: "75%",
          endingShape: "flat",
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        offsetY: -25,
        style: {
          fontSize: "12px",
          colors: ["#008FFB", "#ff8c00"],
        },
      },

      xaxis: {
        categories: revenueExpenseDate,
      },

      yaxis: {
        title: {
          text: "Receita x Despesas",
        },
      },
    },
  };
  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <Chart
            options={state.options}
            series={state.series}
            type="bar"
            width="500"
          />
        </div>
      </div>
    </div>
  );
}
