/* eslint-disable jsx-a11y/control-has-associated-label */
import { CheckIcon, SmallAddIcon } from "@chakra-ui/icons";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  useToast,
  ModalBody,
  Box,
  Input,
  Button,
  ModalFooter,
  FormControl,
  Select,
  FormLabel,
  FormErrorMessage,
  Stack,
  useRadioGroup,
  Flex,
  RadioGroup,
  Radio,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useCallback, useState, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { api } from "../../../services/api";
import { IWallet, Wallet } from "..";
import { useAuth } from "../../../hooks/auth";
import {
  maskCelPhone,
  maskCurrency,
  undoCurrencyMask,
  undoMask,
  currencyMask,
} from "../../../utils/masks";
import { ModalCreateCategory } from "./ModalCreateCategory";

interface FormData {
  id: string;
  title: string;
  value: string;
  type: string;
  transactionCategoryId: string;
}
export interface CategoriesI {
  id: string;
  title: string;
}

interface ModalUpdateWalletProps {
  isOpen: boolean;
  onClose: () => void;
  walletUpdate: IWallet;
  setWallet: React.Dispatch<React.SetStateAction<IWallet[]>>;
  categories: CategoriesI[];
  setCategories: React.Dispatch<React.SetStateAction<CategoriesI[]>>;
}
const schema = yup.object().shape({
  title: yup.string().required("Telefone é obrigatório"),
  // daily: yup.string().required("Telefone é obrigatório"),

  // name: yup
  //   .string()
  //   .required("Nome do entregador é obrigatório")
  //   .min(2, "Nome deve conter no mínimo 2 dígitos"),

  // licensePlate: yup
  //   .string()
  //   .matches(/[a-zA-Z]{3}[0-9][A-Za-z0-9][0-9]{2}/, "Placa inc")
  //   .min(7, "A placa deve conter 7 números/letras")
  //   .max(7, "A placa deve conter 7 números")
  //   .required("Número é obrigatório"),
});

function ModalUpdateWallet({
  isOpen,
  onClose,
  walletUpdate,
  setWallet,
  categories,
  setCategories,
}: ModalUpdateWalletProps): JSX.Element {
  // const [categories, setCategories] = useState<CategoriesI[]>([]);
  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });
  const toast = useToast();
  const { user } = useAuth();
  const { companyId } = user;
  useEffect(() => {
    if (Object.keys(walletUpdate).length !== 0) {
      setValue("id", walletUpdate.id);
      setValue("title", walletUpdate.title);
      setValue("value", currencyMask(walletUpdate.value.toString()));
      setValue("type", walletUpdate.type);
      setValue("transactionCategoryId", walletUpdate.transactionCategory.id);
    }
  });
  // console.log(categories);

  // useEffect(() => {
  //   api.get(`/companies/${companyId}/transactionsCategories`).then((resp) => {
  //     setCategories(resp.data);
  //   });
  // }, [companyId]);

  async function handleFormSubmit(data: FormData): Promise<void> {
    // console.log(data);
    const newData = {
      id: data.id,
      title: data.title,
      value: Number(undoCurrencyMask(data.value)),
      type: data.type,
      transactionCategoryId: data.transactionCategoryId,
    };
    // console.log(newData);
    api.put(`/transactions/${data.id}/`, newData).then((response) => {
      setWallet((state) =>
        state.map((transaction) => {
          if (transaction.id === response.data.id) {
            return {
              id: response.data.id,
              title: response.data.title,
              value: response.data.value,
              type: response.data.type,
              createdAt: response.data.createdAt,
              transactionCategory: {
                id: response.data.transactionCategory.id,
                title: response.data.transactionCategory.title,
              },
            };
          }
          return transaction;
        })
      );
    });
    reset();
    onClose();
  }

  const handleOnChangeCurrency = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const { value } = e.currentTarget;
      e.currentTarget.value = maskCurrency(value);
    },
    []
  );

  // const handleCreateCategory = () => {
  //   console.log("teste");
  // };
  const {
    isOpen: isOpenCreateCategory,
    onOpen: onOpenCreateCategory,
    onClose: onCloseCreateCategory,
  } = useDisclosure();
  return (
    <>
      <ModalCreateCategory
        isOpen={isOpenCreateCategory}
        onClose={onCloseCreateCategory}
        companyId={companyId}
        categories={categories}
        setCategories={setCategories}
      />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent as="form" onSubmit={handleSubmit(handleFormSubmit)}>
          <ModalHeader>Editar Transação</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box margin="10px">
              <FormControl isInvalid={!!errors.title}>
                <FormLabel margin="0" textColor="gray.400">
                  Título
                </FormLabel>
                <Input
                  {...register("title")}
                  margin="2px"
                  placeholder="title"
                />
                <FormErrorMessage marginTop="0px">
                  {errors.title?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.value} pt={2}>
                <FormLabel margin="0" textColor="gray.400">
                  Valor
                </FormLabel>
                <Input
                  {...register("value")}
                  margin="2px"
                  onChange={handleOnChangeCurrency}
                />
                <FormErrorMessage marginTop="0px">
                  {errors.title?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl pt={2}>
                <FormLabel margin="0" textColor="gray.400">
                  Categoria
                  <Button
                    ml={1}
                    // leftIcon={<SmallAddIcon />}
                    size="xs"
                    colorScheme="purple"
                    onClick={onOpenCreateCategory}
                  >
                    Adicionar
                  </Button>
                </FormLabel>

                <Stack spacing={3}>
                  <Select
                    pt={1}
                    placeholder="Selecione"
                    size="md"
                    {...register("transactionCategoryId")}
                  >
                    {categories.map((resultCategories) => (
                      <option
                        key={resultCategories.id}
                        value={resultCategories.id}
                      >
                        {resultCategories.title}
                      </option>
                    ))}
                  </Select>
                </Stack>
                <FormErrorMessage marginTop="0px">
                  {errors.transactionCategoryId?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl pt={2}>
                <FormLabel margin="0" textColor="gray.400">
                  Tipo de transação
                </FormLabel>

                {
                  // console.log(walletUpdate)
                  <Stack spacing={3} pt={1}>
                    <Select placeholder="Select option" {...register("type")}>
                      <option value="Income">Entrada</option>
                      <option value="Outcome">Saida</option>
                    </Select>
                  </Stack>
                }
                <FormErrorMessage marginTop="0px">
                  {errors.type?.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              type="submit"
              leftIcon={<CheckIcon />}
              colorScheme="blue"
              mt="24px"
              width="100%"
              marginBottom="8px"
            >
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export { ModalUpdateWallet };
