import {
  Avatar,
  useDisclosure,
  Center,
  Box,
  Container,
  Flex,
  SimpleGrid,
  Text,
  Badge,
  Button,
  Table,
  TableContainer,
  Tr,
  Th,
  Thead,
  Tfoot,
  Td,
  Tbody,
  TableCaption,
  useToast,
  Divider,
  filter,
} from "@chakra-ui/react";
import { MdOutlineAttachMoney } from "react-icons/md";
import { BsArrowUpCircle, BsArrowDownCircle } from "react-icons/bs";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import { toUnicode } from "punycode";
import { CategoriesI, ModalUpdateWallet } from "./Modals/ModalUpdateWallet";
import { ModalCreateWallet } from "./Modals/ModalCreateWallet";
import { api } from "../../services/api";
import { useAuth } from "../../hooks/auth";
import {
  maskCelPhone,
  maskCurrency,
  undoCurrencyMask,
  undoMask,
} from "../../utils/masks";
import { ModalCreateCategory } from "./Modals/ModalCreateCategory";

export interface IWallet {
  id: string;
  title: string;
  value: number;
  type: "Income" | "Outcome";
  createdAt: string;
  transactionCategory: {
    id: string;
    title: string;
    // createdAt: string;
  };
}
function Wallet(): JSX.Element {
  const toast = useToast();
  const { user } = useAuth();
  const { companyId } = user;
  const [walletUpdate, setWalletUpdate] = useState<IWallet>({} as IWallet);
  const [wallet, setWallet] = useState<IWallet[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [categories, setCategories] = useState<CategoriesI[]>([]);

  const {
    isOpen: isOpenCreate,
    onOpen: onOpenCreate,
    onClose: onCloseCreate,
  } = useDisclosure();

  useEffect(() => {
    api.get(`/companies/${companyId}/transactions`).then((resp) => {
      // console.log(resp.data);
      setWallet(resp.data);
    });
    api.get(`/companies/${companyId}/transactionsCategories`).then((resp) => {
      setCategories(resp.data);
    });
  }, [companyId]);

  async function handleDeleteWallet(data: any): Promise<void> {
    await api
      .delete(`/transactions/${data.id}`)
      .then((resp) => {
        toast({
          title: "Sucesso",
          description: `Registro deletado com sucesso`,
          status: "success",
          position: "top-right",
          isClosable: true,
        });
        // console.log(resp.data);
        setWallet(wallet.filter((todo) => todo.id !== data.id));
      })
      .catch((err) => console.log(err));
  }
  const {
    isOpen: isOpenCreateCategory,
    onOpen: onOpenCreateCategory,
    onClose: onCloseCreateCategory,
  } = useDisclosure();
  // async function handleUpdateWallet(data: IWallet): Promise<void> {
  //   console.log(data);
  //   // await api
  //   //   .put(`/transactions/${data.id}`, {
  //   //     ...data,
  //   //     value: Number(undoCurrencyMask(data.value)),
  //   //     type: data.type === "Saída" ? "Outcome" : "Income",
  //   //   })
  //   //   .then((response) => {
  //   //     toast({
  //   //       title: "Sucesso",
  //   //       description: `Cadastro realizado com sucesso`,
  //   //       status: "success",
  //   //       position: "top-right",
  //   //       isClosable: true,
  //   //     });

  //   //     onClose();
  //   //     setWallet((state) => [...state, response.data]);
  //   //   })
  //   //   .catch((err) => {
  //   //     toast({
  //   //       title: "Erro ao cadastrar",
  //   //       description: `${err.response.data.message}`,
  //   //       status: "warning",
  //   //       position: "top-right",
  //   //       isClosable: true,
  //   //     });
  //   //   });
  // }
  // console.log(wallet);
  return (
    <Container p="0" maxW="container.x1" marginBottom="0px" padding={4}>
      <ModalCreateCategory
        isOpen={isOpenCreateCategory}
        onClose={onCloseCreateCategory}
        companyId={companyId}
        categories={categories}
        setCategories={setCategories}
      />
      <ModalUpdateWallet
        categories={categories}
        isOpen={isOpen}
        onClose={onClose}
        walletUpdate={walletUpdate}
        // eslint-disable-next-line react/jsx-no-bind, react/jsx-no-bind
        setWallet={setWallet}
        setCategories={setCategories}
      />
      <ModalCreateWallet
        isOpen={isOpenCreate}
        onClose={onCloseCreate}
        setWallet={setWallet}
      />
      {/* <Flex width="100%"> */}
      <Flex justifyContent="flex-end" width="100%">
        <Button
          size="md"
          color="white"
          bgColor="purple.500"
          onClick={onOpenCreateCategory}
          mr={1}
        >
          Editar categoria
        </Button>
        <Button
          bgColor="orange.500"
          color="white"
          onClick={onOpenCreate}
          size="md"
        >
          Nova transação
        </Button>
      </Flex>
      <SimpleGrid gap={6} columns={{ sm: 1, md: 2, lg: 3 }} paddingTop={4}>
        <Box
          bg="blue.500"
          borderRadius="md"
          p="5px"
          textColor="whiteAlpha.900"
          padding={6}
        >
          <Flex>
            <Box>
              <BsArrowUpCircle size={50} />
            </Box>
            <Box ml="3">
              <Text fontWeight="bold" fontSize="lg">
                Receita
                <Badge ml="1" colorScheme="green">
                  hoje
                </Badge>
              </Text>
              <Text fontSize="2xl">R$ 25.210,10</Text>
            </Box>
          </Flex>
        </Box>
        <Box
          bg="#ff8c00"
          borderRadius="md"
          p="5px"
          textColor="whiteAlpha.900"
          padding={6}
        >
          <Flex>
            <Box>
              <BsArrowDownCircle size={50} />
            </Box>
            <Box ml="3">
              <Text fontWeight="bold" fontSize="lg">
                Despesa
                <Badge ml="1" colorScheme="green">
                  hoje
                </Badge>
              </Text>
              <Text fontSize="2xl">R$ 5.125,50</Text>
            </Box>
          </Flex>
        </Box>
        <Box
          bg="green.500"
          borderRadius="md"
          p="5px"
          textColor="whiteAlpha.900"
          padding={6}
        >
          <Flex>
            <Box>
              <MdOutlineAttachMoney size={50} />
            </Box>
            <Box ml="3">
              <Text fontWeight="bold" fontSize="lg">
                Saldo
                <Badge ml="1" colorScheme="green">
                  hoje
                </Badge>
              </Text>
              <Text fontSize="2xl">R$ 20.535,50</Text>
            </Box>
          </Flex>
        </Box>
      </SimpleGrid>
      <Box>
        <TableContainer
          marginTop="20px"
          border="1px solid"
          borderColor="gray.100"
          borderRadius="md"
          paddingTop={4}
        >
          <Table variant="striped" colorScheme="blue">
            <TableCaption>pag 1 - 2 - 3 - 4</TableCaption>
            <Thead>
              <Tr>
                <Th textColor="blue.600">Titulo</Th>
                <Th textColor="blue.600">Valor</Th>
                <Th textColor="blue.600">Tipo</Th>
                <Th textColor="blue.600">Categoria</Th>
                <Th textColor="blue.600">Data</Th>
                <Th textColor="blue.600">Ações</Th>
              </Tr>
            </Thead>
            <Tbody text-align="center">
              {wallet.map((result) => (
                <Tr key={result.id}>
                  <Td>{result.title}</Td>
                  <Td>
                    {new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(result.value)}
                  </Td>
                  <Td>
                    {result.type === "Outcome" ? (
                      <Badge bgColor="orange" color="white" pr={2} pl={2}>
                        Saída
                      </Badge>
                    ) : (
                      <Badge bgColor="blue.500" color="white">
                        Entrada
                      </Badge>
                    )}
                  </Td>
                  <Td>{result.transactionCategory.title}</Td>
                  <Td>
                    {/* {result.createdAt} */}
                    {new Intl.DateTimeFormat("pt-BR").format(
                      new Date(result.createdAt)
                    )}
                  </Td>
                  <Td>
                    <Button
                      onClick={() => {
                        setWalletUpdate(result);
                        onOpen();
                      }}
                      leftIcon={<EditIcon />}
                      size="xs"
                      border="1px"
                      marginRight="5px"
                      colorScheme="linkedin"
                      paddingRight="2px"
                    />

                    <Button
                      onClick={() => handleDeleteWallet(result)}
                      leftIcon={<DeleteIcon />}
                      size="xs"
                      border="1px"
                      marginRight="5px"
                      colorScheme="red"
                      paddingRight="2px"
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
            <Tfoot>
              <Tr>
                <Th textColor="blue.600">Titulo</Th>
                <Th textColor="blue.600">Valor</Th>
                <Th textColor="blue.600">Tipo</Th>
                <Th textColor="blue.600">Categoria</Th>
                <Th textColor="blue.600">Data</Th>
                <Th textColor="blue.600">Ações</Th>
              </Tr>
            </Tfoot>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
}

export { Wallet };
