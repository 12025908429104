/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  ModalFooter,
  Button,
  FormControl,
  Input,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { FormProvider } from "react-hook-form";
import { api } from "../../../services/api";

import { Complements } from "../components/Complements";
import { ItemDetails } from "../components/ItemDetails";

import { TComplements, TItem } from "../../../types";
import { formatStringInputToNumber } from "../../../utils/formatStringInputToNumber";
import { useAuth } from "../../../hooks/auth";

interface CreateItemModalProps {
  isOpen: boolean;
  onClose: () => void;
  categoryId: string;
  setItemsState: React.Dispatch<React.SetStateAction<TItem[]>>;
}

export interface CreateItemFormData {
  name: string;
  description: string;
  additionnalInformation: string;
  urlImage: string;
  cod: string;
  type: "DEFAULT" | "PIZZA";
  status: "AVAILABLE" | "UNAVAILABLE";
  originalPrice: string;
  discountPrice: string;
  discountPercentage: number;
  promotionIsActived: boolean;
  complements: {
    name: string;
    min: number;
    max: number;
    options: {
      name: string;
      description: string;
      cod: string;
      originalPrice: string;
      discountPrice: string;
    }[];
  }[];
  teste: string;
}

const optionSchema = {
  name: Yup.string().required("Campo obrigatório"),
  originalPrice: Yup.string().required("Campo obrigatório"),
};

const complementSchema = {
  name: Yup.string().required("Campo obrigatório"),
  options: Yup.array()
    .of(Yup.object().shape(optionSchema))
    .required()
    .min(1, "Complemento deve ter no mínimo uma opção"),
};

const schema = Yup.object().shape({
  name: Yup.string().required("Campo obrigatório"),
  originalPrice: Yup.string().required("Campo Obrigatório"),
  complements: Yup.array().of(Yup.object().shape(complementSchema)),
});

function CreateItemModal({
  isOpen,
  onClose,
  categoryId,
  setItemsState,
}: CreateItemModalProps): JSX.Element {
  const metods = useForm<CreateItemFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      type: "DEFAULT",
      status: "UNAVAILABLE",
      promotionIsActived: false,
    },
  });

  const { user } = useAuth();

  const [tabIndex, setTabIndex] = useState(0);

  async function handleFormSubmit(data: CreateItemFormData): Promise<void> {
    try {
      const newItem = {} as TItem;
      // cria produto
      const formData = new FormData();
      formData.append("urlImage", data.urlImage[0]);
      formData.append(
        "obj",
        JSON.stringify({
          name: data.name,
          description: data.description,
          additionnalInformation: data.additionnalInformation,
          type: data.type,
          cod: data.cod,
        })
      );
      // ATENCAO; COMPANYID FIXO!!!!!!!
      const response = await api.post(
        `companies/${user.companyId}/products`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const product = response.data;

      /// //////////////////////////////////////////////////////
      newItem.name = product.name;
      newItem.description = product.description;
      newItem.additionalInformation = product.additionalInformation;
      newItem.urlImage = product.urlImage;

      // cria item
      const responseItem = await api.post(
        `/categories/${categoryId}/products/${product.id}`,
        {
          originalPrice: formatStringInputToNumber(data.originalPrice),
          discountPrice:
            data.discountPrice && formatStringInputToNumber(data.discountPrice),
          status: data.status,
        }
      );

      Object.assign(newItem, { ...responseItem.data, complements: [] });

      // Se o  item tem complementos
      if (data.complements.length !== 0) {
        let complementIndex = 0; // ordem que vai ser mostrado(controlado no frontend)
        for (const complement of data.complements) {
          // cria o complemento
          const complementResponse = await api.post("/complements", {
            name: complement.name,
          });

          // associa o complemnto ao producto
          const complementProductResponse = await api.post(
            `/complements/${complementResponse.data.id}/products/${product.id}`,
            {
              max: complement.max,
              min: complement.min,
              index: complementIndex,
            }
          );
          complementIndex += 1;

          const complementInsert: TComplements = {
            id: complementProductResponse.data.id,
            min: complementProductResponse.data.min,
            max: complementProductResponse.data.max,
            index: complementProductResponse.data.index,
            complementId: complementResponse.data.id,
            name: complementResponse.data.name,
            options: [],
          };

          let optionIndex = 0;
          for (const option of complement.options) {
            const formData2 = new FormData();
            formData2.append(
              "obj",
              JSON.stringify({
                name: option.name,
                description: option.description,
                // additionnalInformation: data.additionnalInformation,
                type: "DEFAULT",
                cod: option.cod,
              })
            );
            // ATENCAO; COMPANYID FIXO!!!!!!!
            // cria produto
            const responseProductOption = await api.post(
              `companies/${user.companyId}/products`,
              formData2,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            // associa option ao complemto e product
            const optionResponse = await api.post(
              `/complements/${complementResponse.data.id}/products/${responseProductOption.data.id}/options`,
              {
                originalPrice: formatStringInputToNumber(option.originalPrice),
                cod: option.cod,
                index: optionIndex,
              }
            );
            optionIndex += 1;

            complementInsert.options.push({
              id: optionResponse.data.id,
              index: optionResponse.data.index,
              productId: optionResponse.data.productId,
              complementId: optionResponse.data.complementId,
              description: responseProductOption.data.description,
              cod: optionResponse.data.cod,
              originalPrice: optionResponse.data.originalPrice,
              discountPrice: optionResponse.data.discountPrice,
              name: responseProductOption.data.name,
            });
          }

          newItem.complements.push(complementInsert);
        }
      }
      setItemsState((state) => [...state, newItem]);

      // reset
      metods.reset();
      onClose();
      setTabIndex(0);
    } catch (error) {
      console.log(error);
    }
  }

  // console.log(metods.formState.errors);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl" scrollBehavior="inside">
      <ModalOverlay />
      <FormProvider {...metods}>
        <ModalContent
          as="form"
          onSubmit={metods.handleSubmit(handleFormSubmit)}
          overflow="hidden"
        >
          <ModalHeader>Cadastrar Item</ModalHeader>
          <ModalCloseButton />
          <ModalBody py={0}>
            <Tabs index={tabIndex} onChange={(index) => setTabIndex(index)}>
              <TabList position="sticky" top="0" bg="white" zIndex="sticky">
                <Tab>Detalhes</Tab>
                <Tab>Complementos</Tab>
              </TabList>

              <TabPanels overflow="auto" py={4}>
                {/* Detalhes */}
                <TabPanel py={0}>
                  <ItemDetails />
                </TabPanel>
                {/* Complementos */}
                <TabPanel py={0}>
                  <Complements />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              variant="outline"
              mr={3}
              onClick={() => {
                metods.reset();
                setTabIndex(0);
                onClose();
              }}
            >
              Close
            </Button>

            {tabIndex < 1 && (
              <Button
                colorScheme="blue"
                onClick={() => setTabIndex(tabIndex + 1)}
              >
                Próximo
              </Button>
            )}
            {tabIndex === 1 && (
              <Button
                colorScheme="blue"
                type="submit"
                isLoading={metods.formState.isSubmitting}
              >
                Salvar
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </FormProvider>
    </Modal>
  );
}

export { CreateItemModal };

/**
 *
 */
