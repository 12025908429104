import { useForm } from "react-hook-form";
import { useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Input,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  useToast,
  Tabs,
  Text,
  Img,
  FormControl,
  FormLabel,
  VStack,
  Switch,
} from "@chakra-ui/react";
import { BiRectangle, BiCircle } from "react-icons/bi";

import { MdOutlineSubtitles } from "react-icons/md";

import { FiCamera } from "react-icons/fi";
import { useEffect } from "react";
import { api } from "../../services/api";
import { useAuth } from "../../hooks/auth";

interface IConfiguration {
  id: string | null;
  title: string | null;
  description: string | null;
  minimumOrder: string | null;
  segment: string | null;
  logo: {
    patch: string | null;
    url: string | null;
  };
  coverImage: {
    patch: null;
    url: null;
  };
}

interface FormConfiguration {
  id: string | null;
  title: string | null;
  description: string | null;
  minimumOrder: string | null;
  segment: string | null;
}

function Configuration(): JSX.Element {
  const toast = useToast();
  const { user } = useAuth();
  const [imgLogo, setImgLogo] = useState<null | string>(null);
  const [imgCover, setCoverImage] = useState<null | string>(null);
  const [configuration, setConfiguration] = useState<IConfiguration | null>(
    null
  );
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<FormConfiguration>();

  useEffect(() => {
    api.get(`/companies/${user.companyId}/configurations`).then((resp) => {
      setConfiguration(resp.data);
      if (resp.data) {
        setValue("id", resp.data.id);
        setValue("title", resp.data.title);
        setValue("description", resp.data.description);
        setValue("minimumOrder", resp.data.minimumOrder);
        setValue("segment", resp.data.segment);
        setImgLogo(resp.data.logo.url);
        setCoverImage(resp.data.coverImage.url);
      }
    });
  }, [setValue, user.companyId]);

  async function handleFormSubmit(data: FormConfiguration): Promise<void> {
    api
      .post(`/companies/${user.companyId}/configurations`, {
        title: data.title,
        description: data.description,
        minimumOrder: data.minimumOrder,
        segment: data.segment,
      })
      .then((resp) => {
        toast({
          title: "Sucesso",
          description: `Atualização realizada com sucesso`,
          status: "success",
          position: "top-right",
          isClosable: true,
        });
      })
      .catch((err) => console.log(err));
  }

  const onImageChangeLogo = async (e: any): Promise<void> => {
    try {
      if (e.target.files) {
        const data = new FormData();
        data.append("logoPatch", e.target.files[0]);
        const response = await api.patch(
          `/configurations/${configuration?.id}/logo`,
          data
        );
        const [file] = e.target.files;
        setImgLogo(URL.createObjectURL(file));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onImageChangeCover = async (e: any): Promise<void> => {
    try {
      if (e.target.files) {
        const data = new FormData();
        data.append("coverImagePatch", e.target.files[0]);
        const response = await api.patch(
          `/configurations/${configuration?.id}/image-cover`,
          data
        );
        const [file] = e.target.files;
        setCoverImage(URL.createObjectURL(file));
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Center>
        <Heading textColor="primary.500" marginTop="20px">
          Configuração da empresa
        </Heading>
      </Center>
      <Center>
        <Text textColor="gray.400">
          Personalize as configurações da sua empresa
        </Text>
      </Center>
      <Center marginTop="20px">
        <Box>
          <Tabs>
            <TabList p="4px">
              <Tab>
                <MdOutlineSubtitles color="purple" />
                <Text marginLeft="4px">Configurações</Text>
              </Tab>
              {configuration && (
                <>
                  <Tab>
                    <BiCircle color="orange" />
                    <Text marginLeft="4px">Logomarca</Text>
                  </Tab>
                  <Tab>
                    <BiRectangle color="blue" />
                    <Text marginLeft="4px">Capa</Text>
                  </Tab>
                </>
              )}
            </TabList>
            <TabPanels p="10px">
              <TabPanel>
                <VStack
                  spacing="8px"
                  as="form"
                  onSubmit={handleSubmit(handleFormSubmit)}
                >
                  <FormControl>
                    <FormLabel>Título:</FormLabel>
                    <Input
                      placeholder="Digite o Nome da sua Loja"
                      {...register("title")}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Descrição: </FormLabel>
                    <Input
                      placeholder="Digite uma decrição da sua loja"
                      {...register("description")}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Pedido Mínimo: </FormLabel>
                    <Input
                      placeholder="Digite o seu pedido mínimo"
                      {...register("minimumOrder")}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Seguimento: </FormLabel>
                    <Input
                      placeholder="Qual seu seguimento?"
                      {...register("segment")}
                    />
                  </FormControl>

                  <Switch>Retirada na Loja</Switch>

                  <Button type="submit" colorScheme="primary" width="70%">
                    Salvar Configurações
                  </Button>
                  <Button type="submit" colorScheme="blue" width="70%">
                    Atualizar Configurações
                  </Button>
                </VStack>
              </TabPanel>
              {configuration && (
                <TabPanel>
                  <Flex>
                    <Box flex="1" p={4} width="150px">
                      <Input type="file" onChange={onImageChangeLogo} />
                      {imgLogo && <Img src={imgLogo as string} />}
                    </Box>
                  </Flex>
                </TabPanel>
              )}
              {configuration && (
                <TabPanel>
                  <Flex>
                    <Box flex="1" p={4} width="150px">
                      <Input type="file" onChange={onImageChangeCover} />
                      {imgCover && <Img src={imgCover as string} />}
                    </Box>
                  </Flex>
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
        </Box>
      </Center>
    </>
  );
}
export { Configuration };
