import { Box, Button, Stack } from "@chakra-ui/react";

import { useFieldArray } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import { CreateItemFormData } from "../modals/CreateItemModal";
import { ComplementDetails2 } from "./ComplementDetails2";
import { Options } from "./Options";

// interface ComplementsProps {
//   register: UseFormRegister<CreateItemFormData>;
//   control: Control<CreateItemFormData>;
//   setValue: UseFormSetValue<CreateItemFormData>;
//   getValues: UseFormGetValues<CreateItemFormData>;
// }
function Complements(): JSX.Element {
  const { control } = useFormContext<CreateItemFormData>();
  const {
    fields: complements,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "complements",
  });

  return (
    <Stack spacing={4}>
      <Button
        w="30%"
        colorScheme="blue"
        onClick={() => {
          append({
            name: "",
            min: 0,
            max: 0,
          });
        }}
      >
        Adicionar complementos
      </Button>
      {complements.map((complement, index) => (
        <Box key={complement.id} border="1px solid #d5d5d5" rounded="md" p={4}>
          <ComplementDetails2 {...{ index, remove }} />
          <Options {...{ index }} />
        </Box>
      ))}
    </Stack>
  );
}

export { Complements };
