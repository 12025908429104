import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  Badge,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { string } from "yup";
import { useAuth } from "../../../hooks/auth";
import { api } from "../../../services/api";
import { TCategory } from "../../../types";
import { categoriesState } from "../store/atom";

interface EditCategoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  category: TCategory;
}

interface Formdata {
  name: string;
  cod: string;
}

function EditCategoryModal({
  isOpen,
  onClose,
  category,
}: EditCategoryModalProps): JSX.Element {
  const setCategories = useSetRecoilState(categoriesState);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<Formdata>();

  const { user } = useAuth();
  const toast = useToast();

  const handleClose = (): void => {
    reset();
    onClose();
  };

  const handleFormSubmit = async (data: Formdata): Promise<void> => {
    try {
      const response = await api.patch(
        `/companies/${user.companyId}/categories/${category.id}`,
        data
      );

      setCategories((state) =>
        state.map((cat) => {
          if (cat.id === category.id) {
            return {
              ...cat,
              ...response.data,
            };
          }
          return cat;
        })
      );

      handleClose();
      toast({
        title: "Categoria alterada com sucesso!",
        status: "success",
        position: "top-right",
        isClosable: true,
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Algo de errado ocorreu, tente novamente!",
        status: "error",
        position: "top-right",
        isClosable: true,
      });
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(handleFormSubmit)}>
        <ModalHeader>Editar Categoria</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={4} isInvalid={!!errors.name}>
            <FormLabel>Nome</FormLabel>
            <Input
              {...register("name", { required: "Campo obrigatório" })}
              defaultValue={category.name}
            />
            <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
          </FormControl>
          <Text>
            Tipo:
            <Badge p="0.5rem 1rem" ml={4} colorScheme="blue" borderRadius="md">
              {category.type}
            </Badge>
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="primary"
            mr={3}
            variant="outline"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button colorScheme="primary" type="submit" isLoading={isSubmitting}>
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export { EditCategoryModal };
