import { useCallback, FormEvent, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  HStack,
  InputGroup,
  InputLeftElement,
  Checkbox,
  Stack,
  InputRightElement,
  FormErrorMessage,
  Img,
} from "@chakra-ui/react";
import { useFormContext, Controller, useWatch } from "react-hook-form";

// import { InputView } from "../../../Components/InputView";
import { formatStringInputToNumber } from "../../../utils/formatStringInputToNumber";
import { currencyMask } from "../../../utils/masks";
import { CreateItemFormData } from "../modals/CreateItemModal";

function ItemDetails(): JSX.Element {
  const {
    register,
    unregister,
    control,
    setValue,
    resetField,
    formState: { errors },
  } = useFormContext<CreateItemFormData>();

  const promotionIsActive = useWatch({
    control,
    name: "promotionIsActived",
  });
  useEffect(() => {
    if (promotionIsActive) {
      register("discountPrice");
    } else {
      unregister("discountPrice", { keepValue: false });
      resetField("discountPercentage");
    }
  }, [promotionIsActive, register, resetField, unregister]);

  const handleOnchange = useCallback((e: FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    e.currentTarget.value = currencyMask(value);
    return e;
  }, []);

  const originalPrice = useWatch({
    control,
    name: "originalPrice",
  });
  const discountPrice = useWatch({
    control,
    name: "discountPrice",
  });

  if (originalPrice && discountPrice) {
    let discount =
      1 -
      formatStringInputToNumber(discountPrice) /
        formatStringInputToNumber(originalPrice);

    discount = Math.floor(discount * 100);
    setValue("discountPercentage", discount);
  }

  /// /////////////Input //////////////////////////////
  const [imgSrc, setImgSrc] = useState<undefined | string>(undefined);
  const onImageChange = (e: any): void => {
    // fix any ??? filelist | null not type array( e: handlechange<HTMLINPUTELEMENT>)
    const [file] = e.target.files;
    setImgSrc(URL.createObjectURL(file));
  };

  return (
    <Box display="flex" flexDirection="row">
      {/* inputs */}
      <Box w="60%" p="1rem">
        <Stack spacing="1rem">
          <FormControl isInvalid={!!errors.name}>
            <FormLabel className="required">Nome</FormLabel>
            <Input {...register("name")} />
            {errors.name && (
              <FormErrorMessage>{errors.name.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl>
            <FormLabel>Descrição</FormLabel>
            <Textarea {...register("description")} />
          </FormControl>
          <HStack spacing="4rem">
            <FormControl>
              <FormLabel>Cod</FormLabel>
              <Input {...register("cod")} />
            </FormControl>
            <FormControl isInvalid={!!errors.originalPrice}>
              <FormLabel className="required">Preço</FormLabel>
              <Controller
                control={control}
                name="originalPrice"
                render={({ field: { onChange } }) => (
                  // <Input
                  //   onChange={(e) => onChange(currency(e))} // send value to hook form
                  // />
                  <InputGroup>
                    <InputLeftElement pointerEvents="none">R$</InputLeftElement>
                    <Input
                      placeholder="0,00"
                      onChange={(e) => onChange(handleOnchange(e))}
                    />
                  </InputGroup>
                )}
              />
              {errors.originalPrice && (
                <FormErrorMessage>
                  {errors.originalPrice.message}
                </FormErrorMessage>
              )}
            </FormControl>
          </HStack>

          <Checkbox
            {...register("promotionIsActived")}
            checked={promotionIsActive}
          >
            Ativar Promoçao
          </Checkbox>
          {promotionIsActive && (
            <HStack mt="1rem" spacing="4rem">
              <FormControl>
                <FormLabel>Preço promocional</FormLabel>
                {/* <Input
                {...register("discountPrice", {
                  valueAsNumber: true,
                })}
                onChange={currency}
              /> */}
                <Controller
                  control={control}
                  name="discountPrice"
                  render={({ field: { onChange } }) => (
                    // <Input
                    //   onChange={(e) => onChange(currency(e))} // send value to hook form
                    // />
                    <InputGroup>
                      <InputLeftElement pointerEvents="none">
                        R$
                      </InputLeftElement>
                      <Input
                        placeholder="0,00"
                        onChange={(e) => onChange(handleOnchange(e))}
                      />
                    </InputGroup>
                  )}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Desconto</FormLabel>
                <InputGroup>
                  <Input readOnly {...register("discountPercentage")} />
                  <InputRightElement pointerEvents="none">%</InputRightElement>
                </InputGroup>
              </FormControl>
            </HStack>
          )}
        </Stack>
      </Box>
      {/*  foto do produto */}
      <Box flex="1" p="1rem">
        <FormControl>
          <FormLabel>Escolha uma foto</FormLabel>
          {/* <InputView {...register("urlImage")} /> */}
          <Input
            type="file"
            {...register("urlImage")}
            onChange={onImageChange}
          />
        </FormControl>
        {imgSrc && <Img src={imgSrc} />}
      </Box>
    </Box>
  );
}

export { ItemDetails };
