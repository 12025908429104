import {
  AddIcon,
  ArrowBackIcon,
  CheckIcon,
  DeleteIcon,
  EditIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Text,
  Heading,
  Button,
  Center,
  Table,
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Input,
  useDisclosure,
  VStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Switch,
  useToast,
} from "@chakra-ui/react";
import { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ICourier } from "../../types";
import { api } from "../../services/api";
import { ModalUpdateCourier } from "./Modals/ModalUpdateCourier";
import { useAuth } from "../../hooks/auth";
import {
  maskCelPhone,
  maskCurrency,
  undoCurrencyMask,
  undoMask,
} from "../../utils/masks";

interface FormData {
  name: string;
  licensePlate: string;
  daily: string;
  phoneNumber: string;
}

const schema = yup.object().shape({
  phoneNumber: yup.string().required("Telefone é obrigatório"),
  daily: yup.string().required("Telefone é obrigatório"),

  name: yup
    .string()
    .required("Nome do entregador é obrigatório")
    .min(2, "Nome deve conter no mínimo 2 dígitos"),

  licensePlate: yup
    .string()
    .matches(/[a-zA-Z]{3}[0-9][A-Za-z0-9][0-9]{2}/, "Placa incorreta")
    .min(7, "A placa deve conter 7 números/letras")
    .max(7, "A placa deve conter 7 números")
    .required("Número é obrigatório"),
  // daily: yup
  //   .string()
  //   .matches(/[0-9]+/gi, "Digite apenas números")
  //   .required("Número é obrigatório"),
});

function Couriers(): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [showElement, setShowElement] = useState(false);
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({ resolver: yupResolver(schema) });

  // mascara telefone
  const handleOnChangePhone = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const { value } = e.currentTarget;
      e.currentTarget.value = maskCelPhone(value);
    },
    []
  );
  const handleOnChangeCurrency = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const { value } = e.currentTarget;
      e.currentTarget.value = maskCurrency(value);
    },
    []
  );

  const [couriers, setCouriers] = useState<ICourier[]>([]);
  const { user } = useAuth();
  const { companyId } = user;
  const [courierUpdate, setCourierUpdate] = useState<ICourier>({} as ICourier);

  const functionButton = (): void => setShowElement(!showElement);

  useEffect(() => {
    api.get(`/companies/${companyId}/couriers`).then((resp) => {
      // const courriersData = resp.data as ICourier[];
      setCouriers(resp.data);
    });
  }, [companyId]);

  async function handleUpdateCourier(courierData: ICourier): Promise<void> {
    await api
      .put(`/companies/${companyId}/couriers/${courierData.id}`, courierData)
      .then((response1) => {
        toast({
          title: "Sucesso",
          description: `Entregador editado`,
          status: "success",
          position: "top-right",
          isClosable: true,
        });

        setCouriers((state) =>
          state.map((courrier) => {
            if (courrier.id === courierData.id) {
              return response1.data;
            }
            return courrier;
          })
        );
        reset();
      })
      .catch((err) => {
        toast({
          title: "Erro",
          description: `${err.response.data.message}`,
          status: "warning",
          position: "top-right",
          isClosable: true,
        });
      });
  }

  async function handleFormSubmit(data: FormData): Promise<void> {
    // eslint-disable-next-line no-param-reassign
    data = {
      name: data.name,
      licensePlate: data.licensePlate,
      daily: data.daily.replace(",", "."),
      phoneNumber: undoMask(data.phoneNumber),
    };

    // console.log(data);
    await api
      .post(`/companies/${companyId}/couriers`, data)
      .then((response1) => {
        toast({
          title: "Sucesso",
          description: `Entregador Cadastrado`,
          status: "success",
          position: "top-right",
          isClosable: true,
        });
        setCouriers((state) => [...state, response1.data]);
        functionButton();
        reset();
      })
      .catch((err) => {
        toast({
          title: "Erro",
          description: `${err.response.data.message}`,
          status: "warning",
          position: "top-right",
          isClosable: true,
        });
      });
  }

  async function handleDeleteCourier(
    courierId: string,
    isActive?: boolean
  ): Promise<void> {
    try {
      const statusChange = !isActive;
      const response = await api.post(
        `/companies/${companyId}/couriers/${courierId}/status`,
        {
          isActive: statusChange,
        }
      );

      setCouriers((state) =>
        state.map((courierNew) => {
          if (courierNew.id === courierId) {
            // console.log(courierNew.id);
            return { ...courierNew, isActive: response.data.isActive };
          }
          return courierNew;
        })
      );
    } catch (err) {
      console.log(err);
    }
  }
  // console.log(couriers);
  // async function handleDeleteCourier{
  //  //falta fazer
  // }

  return (
    <VStack spacing={4} align="stretch" margin="20px">
      <ModalUpdateCourier
        isOpen={isOpen}
        onClose={onClose}
        courierUpdate={courierUpdate}
        // eslint-disable-next-line react/jsx-no-bind
        handleUpdateCourier={handleUpdateCourier}
      />
      <Box margin="25px">
        <Center>
          <Heading textColor="blue.300" marginTop="20px">
            Aqui você cadastra os entregadores da sua loja.
          </Heading>
        </Center>
        <Center>
          <Text textColor="gray.400">
            Caso não tenha cadastrado nenhum entregador, seu relatório sairá
            como Entregador Padrão
          </Text>
        </Center>
        {!showElement && (
          <Center>
            <Button
              leftIcon={<AddIcon />}
              onClick={functionButton}
              colorScheme="orange"
              mt="24px"
              size="sm"
            >
              Cadastrar
            </Button>
          </Center>
        )}

        <Center>
          {showElement && (
            <Box
              margin="10px"
              as="form"
              onSubmit={handleSubmit(handleFormSubmit)}
              border="1px solid"
              borderColor="gray.100"
              borderRadius="md"
              padding="30px"
            >
              <Box>
                <FormControl isInvalid={!!errors.name}>
                  <FormLabel margin="0" textColor="gray.400">
                    Nome
                  </FormLabel>
                  <Input {...register("name")} margin="2px" />
                  <FormErrorMessage marginTop="0px">
                    {errors.name?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.phoneNumber}>
                  <FormLabel margin="0" textColor="gray.400">
                    Telefone
                  </FormLabel>
                  <Input
                    {...register("phoneNumber")}
                    margin="2px"
                    placeholder="(__)9____-____"
                    autoComplete="cc-number"
                    onChange={handleOnChangePhone}
                  />
                  <FormErrorMessage marginTop="0px">
                    {errors.phoneNumber?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.licensePlate}>
                  <FormLabel margin="0" textColor="gray.400">
                    Placa
                  </FormLabel>
                  <Input {...register("licensePlate")} margin="2px" />
                  <FormErrorMessage marginTop="0px">
                    {errors.licensePlate?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.daily}>
                  <FormLabel margin="0" textColor="gray.400">
                    Diária
                  </FormLabel>
                  <Input
                    {...register("daily")}
                    margin="2px"
                    placeholder="120,00"
                    autoComplete="cc-number"
                    onChange={handleOnChangeCurrency}
                  />
                  <FormErrorMessage marginTop="0px">
                    {errors.daily?.message}
                  </FormErrorMessage>
                </FormControl>
              </Box>

              <Center marginTop="30px">
                <Button
                  type="submit"
                  leftIcon={<CheckIcon />}
                  colorScheme="blue"
                  size="sm"
                  marginRight="10px"
                  isLoading={isSubmitting}
                >
                  Salvar
                </Button>
                <Button
                  leftIcon={<ArrowBackIcon />}
                  onClick={functionButton}
                  colorScheme="red"
                  size="sm"
                >
                  Cancelar
                </Button>
              </Center>
            </Box>
          )}
        </Center>
      </Box>

      <Box>
        <TableContainer
          border="1px solid"
          borderColor="gray.100"
          borderRadius="md"
        >
          <Table variant="striped" colorScheme="blue">
            <Thead>
              <Tr>
                <Th>Nome</Th>
                <Th>Status</Th>
                <Th>Telefone</Th>
                <Th>Placa</Th>
                <Th>Diaria</Th>
                <Th>Açoes</Th>
              </Tr>
            </Thead>
            <Tbody>
              {couriers.map((courier) => (
                <Tr key={courier.id}>
                  <Td>{courier.name}</Td>
                  <Td>{courier.isActive === true ? "Ativo" : "Desativado"}</Td>
                  <Td>{courier.phone.phoneNumber}</Td>
                  <Td>{courier.licensePlate}</Td>
                  <Td>{courier.daily}</Td>
                  <Td>
                    <Button
                      onClick={() => {
                        setCourierUpdate(courier);
                        onOpen();
                      }}
                      leftIcon={<EditIcon />}
                      size="xs"
                      border="1px"
                      marginRight="5px"
                      colorScheme="linkedin"
                      paddingRight="2px"
                    />
                    {/* <Button
                        leftIcon={<DeleteIcon />}
                        size="xs"
                        border="1px"
                        marginRight="5px"
                        colorScheme="red"
                        paddingRight="2px"
                        onClick={() => handleDeleteCourier(courier.id)}
                      /> */}
                    <Switch
                      defaultChecked={!courier.isActive}
                      colorScheme="red"
                      onChange={() =>
                        handleDeleteCourier(courier.id, courier.isActive)
                      }
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </VStack>
  );
}

export { Couriers };
