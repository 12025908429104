// import axios from "axios";

// export const api = axios.create({
//   baseURL: "http://localhost:3333",
// });

import axios from "axios";

export const api = axios.create({
  // baseURL: "https://projeto-pizzaria-backend-v1.herokuapp.com",
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "The-Timezone-IANA": Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
});
