import { CheckIcon } from "@chakra-ui/icons";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  Select,
  Checkbox,
  Input,
  Button,
  ModalFooter,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Flex,
  RadioGroup,
  Stack,
  HStack,
  Radio,
  useRadioGroup,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { api } from "../../../services/api";
import { IWallet } from "..";
import { useAuth } from "../../../hooks/auth";
import {
  maskCelPhone,
  maskCurrency,
  undoCurrencyMask,
  undoMask,
} from "../../../utils/masks";
import { CustomRadio } from "../Components/CustomRadio";

interface FormData {
  // id?: string;
  title: string;
  value: string;
  type: string;
  transactionCategoryId: string;
  // transactionCategory: {
  //   id: string;
  //   title: string;
  // };
}

interface CategoriesI {
  id: string;
  title: string;
}

interface ModalCreateWalletProps {
  isOpen: boolean;
  onClose: () => void;
  setWallet: React.Dispatch<React.SetStateAction<IWallet[]>>;
}
const schema = yup.object().shape({
  title: yup.string().required("Telefone é obrigatório"),
  // daily: yup.string().required("Telefone é obrigatório"),

  // name: yup
  //   .string()
  //   .required("Nome do entregador é obrigatório")
  //   .min(2, "Nome deve conter no mínimo 2 dígitos"),

  // licensePlate: yup
  //   .string()
  //   .matches(/[a-zA-Z]{3}[0-9][A-Za-z0-9][0-9]{2}/, "Placa inc")
  //   .min(7, "A placa deve conter 7 números/letras")
  //   .max(7, "A placa deve conter 7 números")
  //   .required("Número é obrigatório"),
});

function ModalCreateWallet({
  isOpen,
  onClose,
  setWallet,
}: ModalCreateWalletProps): JSX.Element {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });
  const handleOnChangeCurrency = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const { value } = e.currentTarget;
      e.currentTarget.value = maskCurrency(value);
    },
    []
  );
  const toast = useToast();
  const { user } = useAuth();
  const { companyId } = user;
  const [categories, setCategories] = useState<CategoriesI[]>([]);
  // entrada e saida
  const options: ("Income" | "Outcome")[] = ["Income", "Outcome"];
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "framework",
    defaultValue: "react",
    onChange: console.log,
  });
  const group = getRootProps();
  // entrada e saida

  useEffect(() => {
    api.get(`/companies/${companyId}/transactionsCategories`).then((resp) => {
      setCategories(resp.data);
    });
  }, [companyId]);

  async function handleFormSubmit(data: FormData): Promise<void> {
    await api
      .post(`/companies/${companyId}/transactions`, {
        ...data,
        value: Number(undoCurrencyMask(data.value)),
      })
      .then((response) => {
        toast({
          title: "Sucesso",
          description: `Cadastro realizado com sucesso`,
          status: "success",
          position: "top-right",
          isClosable: true,
        });
        reset();
        onClose();
        setWallet((state) => [...state, response.data]);
      })
      .catch((err) => {
        toast({
          title: "Erro ao cadastrar",
          description: `${err.response.data.message}`,
          status: "warning",
          position: "top-right",
          isClosable: true,
        });
      });
  }
  // console.log(new Date().toISOString());
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(handleFormSubmit)}>
        <ModalHeader>Editar Entregador wall</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box margin="10px">
            <FormControl isInvalid={!!errors.title}>
              <FormLabel margin="0" textColor="gray.400">
                Título
              </FormLabel>
              <Input
                {...register("title")}
                margin="2px"
                placeholder="Nome da transação"
              />
              <FormErrorMessage marginTop="0px">
                {errors.title?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.value}>
              <FormLabel margin="0" textColor="gray.400">
                Valor
              </FormLabel>
              <Input
                {...register("value")}
                margin="2px"
                placeholder="121,50"
                onChange={handleOnChangeCurrency}
              />
              <FormErrorMessage marginTop="0px">
                {errors.title?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl>
              <FormLabel margin="0" textColor="gray.400">
                Categoria
              </FormLabel>

              <Stack spacing={3}>
                <Select
                  placeholder="Selecione"
                  size="md"
                  {...register("transactionCategoryId")}
                >
                  {categories.map((resultCategories) => (
                    <option
                      key={resultCategories.id}
                      value={resultCategories.id}
                    >
                      {resultCategories.title}
                    </option>
                  ))}
                </Select>
              </Stack>
              <FormErrorMessage marginTop="0px">
                {errors.transactionCategoryId?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.transactionCategoryId}>
              <Controller
                control={control}
                name="type"
                render={({ field }) => (
                  <Flex
                    {...group}
                    {...field}
                    justifyContent="center"
                    // bg="blue"
                    // width="100%"
                    paddingTop={4}
                  >
                    {options.map((value) => {
                      const props = getRadioProps({ value });
                      return (
                        // <CustomRadio key={value} props={props}>
                        //   {value}
                        // </CustomRadio>
                        <CustomRadio key={value} props={props} value={value} />
                      );
                    })}
                  </Flex>
                )}
              />
            </FormControl>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            type="submit"
            leftIcon={<CheckIcon />}
            colorScheme="purple"
            mt="24px"
            width="100%"
            marginBottom="8px"
          >
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export { ModalCreateWallet };
