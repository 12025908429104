import {
  Box,
  Center,
  Flex,
  Heading,
  Text,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
  Image,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/auth";
import { api } from "../../services/api";
import paymentsImg from "../../download/payments.png";

interface PaymentI {
  id: string;
  name: string;
  type: string;
  method: string;
  brand: string;
  enable: boolean;
}

function Payment(): JSX.Element {
  const [payments, setPayments] = useState<PaymentI[]>([]);
  const { user } = useAuth();
  const { companyId } = user;
  const toast = useToast();
  useEffect(() => {
    api
      .get(`/companies/${companyId}/payment-methods`)
      .then((resp) => setPayments(resp.data));
  }, []);

  // const paymentsOnline = payments.filter(
  //   (paymentCheck) => paymentCheck.type === "ONLINE"
  // );
  const paymentsOffline = payments.filter(
    (paymentCheck) => paymentCheck.type === "OFFLINE"
  );

  // console.log(paymentsOffline);

  async function handleChangeStatus(
    paymentId: string,
    paymentEnable: boolean
  ): Promise<void> {
    // console.log(paymentEnable, paymentId);
    try {
      const response = await api.patch(
        `/companies/${companyId}/payment-methods/${paymentId}`,
        { enable: !paymentEnable }
      );

      setPayments((state) =>
        state.map((item) => {
          if (item.id === paymentId) {
            return response.data;
          }
          return item;
        })
      );
      toast({
        title: "Sucesso",
        description: `Pagamento Atualizado`,
        status: "success",
        position: "top-right",
        isClosable: true,
      });
    } catch (err) {
      // console.log(err);
    }
  }

  return (
    <>
      <Box>
        <Center>
          <Flex
            border="1px solid"
            borderColor="gray.300"
            borderRadius="md"
            marginTop="4px"
            padding="2px"
          >
            <Image maxW="100px" alt="login img" src={paymentsImg} />
            <Heading textColor="#FA7532" marginTop="20px">
              Formas de pagamento
            </Heading>
          </Flex>
        </Center>

        <Center>
          <Box padding={4}>
            <Center>
              <Heading textColor="#F95706" marginTop="20px">
                Pagamentos da Loja
              </Heading>
            </Center>

            <Tabs
              colorScheme="orange"
              border="solid 1px #f5f5f5"
              borderRadius="md"
              size="lg"
              marginTop="20px"
            >
              <TabList>
                <Tab>Crédito</Tab>
                <Tab>Débito</Tab>
                <Tab>Dinheiro</Tab>
                <Tab>Pix</Tab>
                <Tab>Vale Refeição</Tab>
              </TabList>

              <TabPanels>
                <TabPanel paddingLeft="0px" paddingRight="0px">
                  {paymentsOffline
                    .filter((payment) => payment.method === "CREDIT_CARD")
                    .map((payment) => (
                      <Center>
                        <Flex
                          key={payment.id}
                          w="80%"
                          justifyContent="space-between"
                        >
                          <span>{payment.name}</span>
                          <Switch
                            defaultChecked={payment.enable}
                            colorScheme="orange"
                            onChange={() =>
                              handleChangeStatus(payment.id, payment.enable)
                            }
                          />
                        </Flex>
                      </Center>
                    ))}
                </TabPanel>
                <TabPanel paddingLeft="0px" paddingRight="0px">
                  {paymentsOffline
                    .filter((payment) => payment.method === "DEBIT_CARD")
                    .map((payment) => (
                      <Center>
                        <Flex
                          key={payment.id}
                          w="60%"
                          justifyContent="space-between"
                        >
                          <span>{payment.name}</span>
                          <Switch
                            defaultChecked={payment.enable}
                            colorScheme="orange"
                            onChange={() =>
                              handleChangeStatus(payment.id, payment.enable)
                            }
                          />
                        </Flex>
                      </Center>
                    ))}
                </TabPanel>
                <TabPanel paddingLeft="0px" paddingRight="0px">
                  {paymentsOffline
                    .filter((payment) => payment.method === "CASH")
                    .map((payment) => (
                      <Center>
                        <Flex
                          key={payment.id}
                          w="60%"
                          justifyContent="space-between"
                        >
                          <span>{payment.name}</span>
                          <Switch
                            defaultChecked={payment.enable}
                            colorScheme="orange"
                            onChange={() =>
                              handleChangeStatus(payment.id, payment.enable)
                            }
                          />
                        </Flex>
                      </Center>
                    ))}
                </TabPanel>
                <TabPanel paddingLeft="0px" paddingRight="0px">
                  {paymentsOffline
                    .filter((payment) => payment.method === "PIX")
                    .map((payment) => (
                      <Center>
                        <Flex
                          key={payment.id}
                          w="60%"
                          justifyContent="space-between"
                        >
                          <span>{payment.name}</span>
                          <Switch
                            defaultChecked={payment.enable}
                            colorScheme="orange"
                            onChange={() =>
                              handleChangeStatus(payment.id, payment.enable)
                            }
                          />
                        </Flex>
                      </Center>
                    ))}
                </TabPanel>
                <TabPanel paddingLeft="0px" paddingRight="0px">
                  {paymentsOffline
                    .filter((payment) => payment.method === "MEAL_TICKET")
                    .map((payment) => (
                      <Center>
                        <Flex
                          key={payment.id}
                          w="60%"
                          justifyContent="space-between"
                        >
                          <span>{payment.name}</span>
                          <Switch
                            defaultChecked={payment.enable}
                            colorScheme="orange"
                            onChange={() =>
                              handleChangeStatus(payment.id, payment.enable)
                            }
                          />
                        </Flex>
                      </Center>
                    ))}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Center>
      </Box>
      <Box margin="25px" />
      <Center>
        <Box padding={4}>
          <Center>
            <Heading textColor="#F95706" marginTop="20px">
              Pagamento Online
            </Heading>
          </Center>
          <Center>
            <Text textColor="gray.400">Em breve...</Text>
          </Center>
          <Tabs
            colorScheme="orange"
            border="solid 1px #f5f5f5"
            borderRadius="md"
            size="lg"
            marginTop="20px"
          >
            <TabList>
              <Tab>Crédito</Tab>
              <Tab>Débito</Tab>
              <Tab>Dinheiro</Tab>
              <Tab>Pix</Tab>
              <Tab>Vale Refeição</Tab>
            </TabList>

            <TabPanels>
              <TabPanel paddingLeft="0px" paddingRight="0px">
                <Text textColor="gray.400">Em breve...</Text>
              </TabPanel>
              <TabPanel paddingLeft="0px" paddingRight="0px">
                <Text textColor="gray.400">Em breve...</Text>
              </TabPanel>
              <TabPanel paddingLeft="0px" paddingRight="0px">
                <Text textColor="gray.400">Em breve...</Text>
              </TabPanel>
              <TabPanel paddingLeft="0px" paddingRight="0px">
                <Text textColor="gray.400">Em breve...</Text>
              </TabPanel>
              <TabPanel paddingLeft="0px" paddingRight="0px">
                <Text textColor="gray.400">Em breve...</Text>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Center>
    </>
  );
}

export { Payment };
