import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
import { api } from "../services/api";

// import api from "../services/api";

interface AuthProviderProps {
  children: ReactNode;
}

interface User {
  name: string;
  email: string;
  companyId: string;
  coords: { lat: number; lng: number }; // MUDAR ISSO AQUI URGENTE
  avatar_url: string;
}

interface AuthState {
  token: string;
  user: User;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: User;
  signIn: (credentials: SignInCredentials) => Promise<void>;
  signOut: () => void;
  updateUser: (user: User) => void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

function AuthProvider({ children }: AuthProviderProps): JSX.Element {
  const [state, setState] = useState<AuthState>(() => {
    const token = localStorage.getItem("@Projeto_pizzaria:token");
    const user = localStorage.getItem("@Projeto_pizzaria:user");
    if (token && user) {
      api.defaults.headers.common.Authorization = `Bearer ${token}`;
      return { token, user: JSON.parse(user) };
    }
    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post<{ token: string; user: User }>("/login", {
      email,
      password,
    });

    const { token, user } = response.data;

    user.coords = { lat: -21.692187635703263, lng: -43.43235181639841 };

    localStorage.setItem("@Projeto_pizzaria:token", token);
    localStorage.setItem("@Projeto_pizzaria:user", JSON.stringify(user));
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    setState({ token, user });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem("@Projeto_pizzaria:token");
    localStorage.removeItem("@Projeto_pizzaria:user");
    setState({} as AuthState);
  }, []);

  const updateUser = useCallback(
    (user: User) => {
      localStorage.setItem("@Projeto_pizzaria:user", JSON.stringify(user));
      setState({
        token: state.token,
        user,
      });
    },
    [state.token]
  );
  return (
    <AuthContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ user: state?.user, signIn, signOut, updateUser }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within a AuthProvider");
  }

  return context;
}

export { AuthProvider, useAuth };
