import { CheckIcon } from "@chakra-ui/icons";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  Input,
  Button,
  ModalFooter,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/react";
import React, { useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ICourier } from "../../../types";
import { maskCelPhone, maskCurrency } from "../../../utils/masks";

interface FormData {
  id: string;
  name: string;
  licensePlate: string;
  daily: string;
  phoneNumber: string;
}

interface ModalUpdateCourierProps {
  isOpen: boolean;
  onClose: () => void;
  courierUpdate: ICourier;
  handleUpdateCourier: (courier: ICourier) => Promise<void>;
}

const schema = yup.object().shape({
  phoneNumber: yup.string().required("Telefone é obrigatório"),
  daily: yup.string().required("Telefone é obrigatório"),

  name: yup
    .string()
    .required("Nome do entregador é obrigatório")
    .min(2, "Nome deve conter no mínimo 2 dígitos"),

  licensePlate: yup
    .string()
    .matches(/[a-zA-Z]{3}[0-9][A-Za-z0-9][0-9]{2}/, "Placa inc")
    .min(7, "A placa deve conter 7 números/letras")
    .max(7, "A placa deve conter 7 números")
    .required("Número é obrigatório"),
  // daily: yup
  //   .string()
  //   .matches(/[0-9]+/gi, "Digite apenas números")
  //   .required("Número é obrigatório"),
});

function ModalUpdateCourier({
  isOpen,
  onClose,
  courierUpdate,
  handleUpdateCourier,
}: ModalUpdateCourierProps): JSX.Element {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  // mascara telefone
  const handleOnChangePhone = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const { value } = e.currentTarget;
      e.currentTarget.value = maskCelPhone(value);
    },
    []
  );
  const handleOnChangeDaily = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const { value } = e.currentTarget;
      e.currentTarget.value = maskCurrency(value);
    },
    []
  );

  // console.log(courierUpdate);

  useEffect(() => {
    if (Object.keys(courierUpdate).length !== 0) {
      setValue("id", courierUpdate.id);
      setValue("name", courierUpdate.name);
      setValue("phoneNumber", maskCelPhone(courierUpdate.phone.phoneNumber));
      setValue("licensePlate", courierUpdate.licensePlate);
      setValue("daily", maskCurrency(courierUpdate.daily));
    }
  });

  async function handleFormSubmit(data: FormData): Promise<void> {
    // await handleUpdateCourier(data);
    await handleUpdateCourier({
      ...data,
      phone: { phoneNumber: data.phoneNumber },
    });

    // console.log(data);

    reset();
    onClose();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(handleFormSubmit)}>
        <ModalHeader>Editar Entregador xsxsxsxsxs</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box margin="10px">
            <FormControl isInvalid={!!errors.name}>
              <FormLabel margin="0" textColor="gray.400">
                Nome
              </FormLabel>
              <Input {...register("name")} margin="2px" placeholder="Nome" />
              <FormErrorMessage marginTop="0px">
                {errors.name?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.phoneNumber}>
              <FormLabel margin="0" textColor="gray.400">
                Telefone
              </FormLabel>
              <Input
                {...register("phoneNumber")}
                margin="2px"
                placeholder="(__)9____-____"
                autoComplete="cc-number"
                onChange={handleOnChangePhone}
              />
              <FormErrorMessage marginTop="0px">
                {errors.phoneNumber?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.licensePlate}>
              <FormLabel margin="0" textColor="gray.400">
                Placa
              </FormLabel>
              <Input
                {...register("licensePlate")}
                margin="2px"
                placeholder="Placa"
              />
              <FormErrorMessage marginTop="0px">
                {errors.licensePlate?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.daily}>
              <FormLabel margin="0" textColor="gray.400">
                Diaria
              </FormLabel>
              <Input
                {...register("daily")}
                margin="2px"
                placeholder="Diária"
                autoComplete="cc-number"
                onChange={handleOnChangeDaily}
              />
              <FormErrorMessage marginTop="0px">
                {errors.daily?.message}
              </FormErrorMessage>
            </FormControl>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            type="submit"
            leftIcon={<CheckIcon />}
            colorScheme="blue"
            mt="24px"
            width="100%"
            marginBottom="8px"
          >
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export { ModalUpdateCourier };
