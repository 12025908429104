/* eslint-disable react/function-component-definition */
/* eslint-disable import/no-unresolved */
import React, { FC, Suspense } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { RecoilRoot } from "recoil";

import { Dashboard } from "./Pages/Dashboard";
import { Order } from "./Pages/Order";
import { TestePrint } from "./Pages/TestePrint";
import { Hours } from "./Pages/Hours";
import { Areas } from "./Pages/Areas";
import { Payment } from "./Pages/Payment";
import { Wallet } from "./Pages/Wallet";
import { Reports } from "./Pages/Reports";
import { Couriers } from "./Pages/Courier";

import { AuthProvider } from "./hooks/auth";
import { Layout } from "./Components/Layout";
import { Integration } from "./Pages/Integration";
import { Configuration } from "./Pages/Configuration";
import { OpeningHour } from "./Pages/OpeningHour";
import { Menu } from "./Pages/Menu";
import { RequireAuth } from "./Components/RequireAuth";
import { SignIn } from "./Pages/Authentication/SignIn";
import { Forgot } from "./Pages/Authentication/Forgot";
import { Reset } from "./Pages/Authentication/Reset";
import { DeliveryArea } from "./Pages/DeliveryArea";
import { Create } from "./Pages/DeliveryArea/pages/Create";
import { FeeTime } from "./Pages/DeliveryArea/pages/FeeTime";

// path / é a pagina principal

function Loading(): JSX.Element {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        marginTop: "200px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="loading">
        <span />
        <span />
        <span />
      </div>

      <p>Buscando os dados...</p>
    </div>
  );
}
const theme = extendTheme({
  colors: {
    primary: {
      "50": "#FEEEE6",
      "100": "#FDD0B9",
      "200": "#FCB28C",
      "300": "#FB945F",
      "400": "#FA7532",
      "500": "#F95706",
      "600": "#C84604",
      "700": "#963403",
      "800": "#642302",
      "900": "#321101",
    },
  },
  styles: {
    global: {
      // styles for the `body`
      body: {
        color: "gray.600",
        // display: "flex",
        // justifyContent: "center",
      },
      // styles for the `a`
    },
  },
  components: {
    Button: {
      variants: {
        "with-shadow": {
          bg: "red.400",
          boxShadow: "0 0 2px 2px #efdfde",
        },
      },
    },
  },
});

const App: FC = () => (
  // const { user } = useAuth();
  // console.log(process.env.REACT_APP_API_URL);
  <ChakraProvider theme={theme}>
    <AuthProvider>
      <RecoilRoot>
        <Router>
          <Routes>
            <Route path="/" element={<SignIn />} />
            <Route path="/Forgot" element={<Forgot />} />
            <Route path="/reset-password" element={<Reset />} />
            <Route
              path="/"
              element={
                <RequireAuth>
                  <Layout />
                </RequireAuth>
              }
            >
              <Route path="Dashboard" element={<Dashboard />} />
              <Route path="Order" element={<Order />} />

              <Route
                path="OpeningHour"
                element={
                  <Suspense fallback={<Loading />}>
                    <OpeningHour />
                  </Suspense>
                }
              />
              <Route
                path="Menu"
                element={
                  <Suspense fallback={<Loading />}>
                    <Menu />
                  </Suspense>
                }
              />
              <Route path="Hours" element={<Hours />} />
              <Route path="Areas" element={<Areas />} />
              <Route path="Payment" element={<Payment />} />
              <Route path="Wallet" element={<Wallet />} />
              <Route path="Reports" element={<Reports />} />
              <Route path="Couriers" element={<Couriers />} />
              <Route path="TestePrint" element={<TestePrint />} />
              <Route
                path="delivery-area"
                element={
                  <Suspense fallback={<Loading />}>
                    <DeliveryArea />
                  </Suspense>
                }
              />
              <Route
                path="delivery-area/fee-time"
                element={
                  <Suspense fallback={<Loading />}>
                    <FeeTime />
                  </Suspense>
                }
              />
              <Route
                path="delivery-area/create"
                element={
                  <Suspense fallback={<Loading />}>
                    <Create />
                  </Suspense>
                }
              />

              <Route path="Integration" element={<Integration />} />
              <Route path="Configuration" element={<Configuration />} />
            </Route>
          </Routes>
        </Router>
      </RecoilRoot>
    </AuthProvider>
  </ChakraProvider>
);
export default App;
