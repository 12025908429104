import { Box, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { Schedule } from "../../../types";
import { UpdateModal } from "../modals/UpdateModal";

const ROW = 25;
const COL = 8;

function RangeBox({ id, startAt, endAt, weekDay }: Schedule): JSX.Element {
  const [updateId, setUpdateId] = useState("");
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [hi, mi] = startAt;
  const [hf, mf] = endAt;
  const startAtFormatted = hi + mi / 60;
  const endAtFormatted = hf + mf / 60;

  function updateOpeningHour(scheduleId: string): void {
    onOpen();
    setUpdateId(scheduleId);
  }
  return (
    <>
      <Box
        p="0.5rem"
        bg="green.100"
        position="absolute"
        top={`calc(100% / ${ROW} * (${startAtFormatted} + 1))`} // +1  pula primeira linha
        left={`calc(100% / ${COL} * (${weekDay} + 1))`} // +1 pula primeira coluna
        h={`calc(100% / ${ROW} * ${endAtFormatted - startAtFormatted})`}
        w={`calc(100% / ${COL} - 8px)`}
        borderRadius="0.5rem"
        opacity={0.8}
        cursor="pointer"
        onClick={() => updateOpeningHour(id)}
      >
        {`${hi.toString().padStart(2, "0")}:${mi.toString().padStart(2, "0")}
           - 
          ${hf.toString().padStart(2, "0")}:${mf.toString().padStart(2, "0")}`}
      </Box>

      <UpdateModal id={updateId} isOpen={isOpen} onClose={onClose} />
    </>
  );
}

export { RangeBox };
