/** funcao para formatar numero para moeda
 * entrada: price
 * saida: string com o valor formatado para moedaBRL
 */
export function formatPrice(price: number): string {
  return new Intl.NumberFormat("pt-br", {
    style: "currency",
    currency: "BRL",
  }).format(price);
}
