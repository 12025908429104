import {
  Text,
  Grid,
  GridItem,
  Container,
  Avatar,
  Flex,
  Badge,
  Select,
  Box,
  SimpleGrid,
} from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { MdOutlineAttachMoney } from "react-icons/md";
import { BsArrowUpCircle, BsArrowDownCircle } from "react-icons/bs";

import RevenueExpense from "./components/RevenueExpense";
import { useAuth } from "../../hooks/auth";
import RevenueLine from "./components/RevenueLine";
import Payment from "./components/Payment";
import ExpensiveCategories from "./components/ExpensiveCategories";

function newDate(days: number): { startDate: string; endDate: string } {
  const initialDay = new Date();
  initialDay.setDate(initialDay.getDate() - 1);
  const today = new Date();
  const finalDay = new Date(today.setDate(today.getDate() - days));

  return {
    endDate: initialDay.toISOString().split("T")[0],
    startDate: finalDay.toISOString().split("T")[0],
  };
}

function Dashboard(): JSX.Element {
  const { user } = useAuth();
  const url = `/companies/${user.companyId}`;

  const [numberDay, setNumberDay] = useState(7);
  const obj = useMemo(() => newDate(numberDay), [numberDay]);

  return (
    <Container p="0" maxW="container.x1" marginBottom="0px" padding={4}>
      <SimpleGrid gap={6} columns={{ sm: 1, md: 2, lg: 4 }}>
        <Box bg="blue.500" borderRadius="md" p="5px" textColor="whiteAlpha.900">
          <Flex>
            <Box>
              <BsArrowDownCircle size={50} />
            </Box>
            <Box ml="3">
              <Text fontWeight="bold">
                Receita
                <Badge ml="1" colorScheme="green">
                  hoje
                </Badge>
              </Text>
              <Text fontSize="sm">XXXXXXXXXXXX</Text>
            </Box>
          </Flex>
        </Box>
        <Box bg="#ff8c00" borderRadius="md" p="5px" textColor="whiteAlpha.900">
          <Flex>
            <Box>
              <BsArrowDownCircle size={50} />
            </Box>
            <Box ml="3">
              <Text fontWeight="bold">
                Despesa
                <Badge ml="1" colorScheme="green">
                  hoje
                </Badge>
              </Text>
              <Text fontSize="sm">xxxxxxxxxxxxxx</Text>
            </Box>
          </Flex>
        </Box>
        <Box
          bg="green.500"
          borderRadius="md"
          p="5px"
          textColor="whiteAlpha.900"
        >
          <Flex>
            <Box>
              <MdOutlineAttachMoney size={50} />
            </Box>
            <Box ml="3">
              <Text fontWeight="bold">
                Saldo
                <Badge ml="1" colorScheme="green">
                  hoje
                </Badge>
              </Text>
              <Text fontSize="sm">xxxx</Text>
            </Box>
          </Flex>
        </Box>
        <Box bg="blue.500" borderRadius="md" p="5px" textColor="whiteAlpha.900">
          <Flex>
            <Avatar src="https://bit.ly/sage-adebayo" />
            <Box ml="3">
              <Text fontWeight="bold">
                Receita
                <Badge ml="1" colorScheme="green">
                  hoje
                </Badge>
              </Text>
              <Text fontSize="sm">xxx</Text>
            </Box>
          </Flex>
        </Box>
      </SimpleGrid>

      <Box marginTop={4}>
        <Select
          borderColor="aliceblue.200"
          placeholder="Selecione o período"
          minW="200"
          maxW="250px"
          value={numberDay}
          onChange={(e) => setNumberDay(Number(e.target.value))}
        >
          <option value="1">Ontem </option>
          <option value="7">7 dias</option>
          <option value="14">14 dias</option>
          <option value="30">30 dias</option>
        </Select>
      </Box>
      <Grid
        marginTop={4}
        templateAreas={{
          base: `
        "area1"
      "area2"
      "area3"
      "area4"
      `,

          md: `
        "area1 area2"
      "area3 area4"
      `,
        }}
        gridTemplateRows={{ base: "1fr 1fr 1fr 1fr", md: "1fr 1fr" }}
        gridTemplateColumns={{ base: "1fr ", md: "1fr 1fr" }}
        gap="1px"
        color="blackAlpha.700"
        fontWeight="bold"
      >
        <GridItem
          area="area1"
          w="100%"
          borderWidth="2px"
          borderRadius="lg"
          display="grid"
          placeItems="center"
        >
          <Text
            bgGradient="linear(to-l, #4682b4,#008ffbd9)"
            bgClip="text"
            fontSize="2xl"
            fontWeight="extrabold"
            textAlign="center"
          >
            Pagamentos
          </Text>
          <Payment url={url} startDate={obj.startDate} endDate={obj.endDate} />
        </GridItem>
        <GridItem
          pl="2"
          area="area2"
          w="100%"
          borderWidth="2px"
          borderRadius="lg"
          display="grid"
          placeItems="center"
        >
          <Text
            bgGradient="linear(to-l, #4682b4,#008ffbd9)"
            bgClip="text"
            fontSize="2xl"
            fontWeight="extrabold"
            textAlign="center"
          >
            Receitas x Despesas
          </Text>
          <RevenueExpense
            url={url}
            startDate={obj.startDate}
            endDate={obj.endDate}
          />
        </GridItem>
        <GridItem
          pl="2"
          // bg="blue.300"
          area="area3"
          borderWidth="2px"
          borderRadius="lg"
          display="grid"
          placeItems="center"
        >
          <Text
            bgGradient="linear(to-l,#4682b4,#008ffbd9)"
            bgClip="text"
            fontSize="2xl"
            fontWeight="extrabold"
            textAlign="center"
          >
            Despesas por caregoria
          </Text>

          <div className="app">
            <div className="row">
              <div className="mixed-chart">
                <ExpensiveCategories
                  url={url}
                  startDate={obj.startDate}
                  endDate={obj.endDate}
                />
              </div>
            </div>
          </div>
        </GridItem>
        <GridItem
          pl="2"
          // bg="blue.300"
          area="area4"
          borderWidth="2px"
          borderRadius="lg"
          display="grid"
          placeItems="center"
        >
          <Text
            bgGradient="linear(to-l, #4682b4,#008ffbd9)"
            bgClip="text"
            fontSize="2xl"
            fontWeight="extrabold"
            textAlign="center"
          >
            Receita Pedidos x Pedidos
          </Text>

          <RevenueLine
            url={url}
            startDate={obj.startDate}
            endDate={obj.endDate}
          />
        </GridItem>
      </Grid>
    </Container>
  );
}

export { Dashboard };
