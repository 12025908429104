import { useCallback, FormEvent, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  HStack,
  InputGroup,
  InputLeftElement,
  Checkbox,
  Stack,
  InputRightElement,
  FormErrorMessage,
  Switch,
  Img,
} from "@chakra-ui/react";
import { useFormContext, Controller, useWatch } from "react-hook-form";

// import { InputView } from "../../../Components/InputView";
import { formatStringInputToNumber } from "../../../../utils/formatStringInputToNumber";
import { currencyMask } from "../../../../utils/masks";
import { EditItemFormData } from "./index";
import { formatNumberToStringInput } from "../../../../utils/formatNumberToStringInput";

function ItemDetails(): JSX.Element {
  const {
    register,
    unregister,
    control,
    getValues,
    setValue,
    resetField,
    trigger,
    formState: { errors },
  } = useFormContext<EditItemFormData>();

  const promotionIsActive = useWatch({
    control,
    name: "promotionIsActived",
  });

  const originalPrice = useWatch({
    control,
    name: "originalPrice",
  });
  useEffect(() => {
    if (promotionIsActive) {
      register("discountPrice");
    } else {
      unregister("discountPrice", { keepValue: false });
      resetField("discountPercentage");
    }
  }, [promotionIsActive, register, resetField, unregister]);

  const handleOnchange = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      const { value } = e.currentTarget;
      e.currentTarget.value = currencyMask(value);
      // Gambiarra!!!!
      setValue("discountPrice", "");
      setValue("discountPercentage", 0);

      return e;
    },
    [resetField]
  );

  function handleOnchangeInputDiscountPrice(
    e: FormEvent<HTMLInputElement>
  ): FormEvent<HTMLInputElement> {
    let { value: discountPriceString } = e.currentTarget;
    discountPriceString = currencyMask(discountPriceString);
    const originalPriceString = getValues("originalPrice");

    const originalPriceNumber = formatStringInputToNumber(originalPriceString);
    const discountPriceNumber = formatStringInputToNumber(discountPriceString);
    if (originalPriceNumber < discountPriceNumber) {
      trigger("name");
      console.log("error");
    }

    let discount = 1 - discountPriceNumber / originalPriceNumber;
    discount *= 100;

    setValue("discountPercentage", Number(discount.toFixed(2)));
    e.currentTarget.value = discountPriceString;
    return e;
  }

  function handleOnchangeInputDiscountPercentage(
    e: FormEvent<HTMLInputElement>
  ): FormEvent<HTMLInputElement> {
    const { value: discountPercentageString } = e.currentTarget;
    // discountPercentageString = formatStringInputToNumber(
    //   discountPercentageString
    // );
    const originalPriceString = getValues("originalPrice");

    const originalPriceNumber = formatStringInputToNumber(originalPriceString);
    const discountPercentageNumber = formatStringInputToNumber(
      discountPercentageString
    );

    const discountPriceString = formatNumberToStringInput(
      (1 - discountPercentageNumber / 100) * originalPriceNumber
    );

    setValue("discountPrice", currencyMask(discountPriceString));
    e.currentTarget.value = discountPercentageString;
    return e;
  }

  /// /////////////Input //////////////////////////////
  const urlImage = useWatch({
    control,
    name: "urlImage",
  });
  const [imgSrc, setImgSrc] = useState<undefined | string>(urlImage);
  const onImageChange = (e: any): void => {
    const [file] = e.target.files;
    setImgSrc(URL.createObjectURL(file));
  };

  return (
    <Box display="flex" flexDirection="row">
      {/* inputs */}
      <Box w="60%" p="1rem">
        <Stack spacing="1rem">
          <FormControl isInvalid={!!errors.name}>
            <FormLabel>Nome</FormLabel>
            <Input {...register("name")} />
            {errors.name && (
              <FormErrorMessage>{errors.name.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl>
            <FormLabel>Descrição</FormLabel>
            <Textarea {...register("description")} />
          </FormControl>
          <HStack spacing="4rem">
            <FormControl>
              <FormLabel>Cod</FormLabel>
              <Input {...register("cod")} />
            </FormControl>
            <FormControl isInvalid={!!errors.originalPrice}>
              <FormLabel>Preço</FormLabel>
              {/* <Controller
                control={control}
                name="originalPrice"
                render={({ field: { onChange } }) => ( */}
              <InputGroup>
                <InputLeftElement pointerEvents="none">R$</InputLeftElement>
                <Input
                  placeholder="0,00"
                  {...register("originalPrice")}
                  onChange={(e) => handleOnchange(e)}
                />
              </InputGroup>
              {/* )}
              /> */}
              {errors.originalPrice && (
                <FormErrorMessage>
                  {errors.originalPrice.message}
                </FormErrorMessage>
              )}
            </FormControl>
          </HStack>

          {/* <Checkbox
            {...register("promotionIsActived")}
            checked={promotionIsActive}
          >
            Ativar Promoçao
          </Checkbox> */}
          <Switch
            checked={promotionIsActive}
            {...register("promotionIsActived")}
          >
            {promotionIsActive ? "Desativar Promoção" : "Ativar Promoção"}
          </Switch>
          {promotionIsActive && (
            <HStack mt="1rem" spacing="4rem">
              <FormControl>
                <FormLabel>Preço promocional</FormLabel>
                {/* <Input
                  {...register("discountPrice")}
                  onChange={(e) => handleOnchange(e)}
                /> */}
                {/* <Controller
                  control={control}
                  name="discountPrice"
                  render={({ field: { onChange } }) => ( */}

                <InputGroup>
                  <InputLeftElement pointerEvents="none">R$</InputLeftElement>
                  <Input
                    {...register("discountPrice")}
                    placeholder="0,00"
                    onChange={(e) => handleOnchangeInputDiscountPrice(e)}
                  />
                </InputGroup>
                {/* )}
                /> */}
              </FormControl>
              <FormControl>
                <FormLabel>Desconto</FormLabel>
                <InputGroup>
                  <Input
                    {...register("discountPercentage")}
                    onChange={(e) => handleOnchangeInputDiscountPercentage(e)}
                  />
                  <InputRightElement pointerEvents="none">%</InputRightElement>
                </InputGroup>
              </FormControl>
            </HStack>
          )}
        </Stack>
      </Box>
      {/*  foto do produto */}
      <Box flex="1" p="1rem">
        <FormControl>
          <FormLabel>Escolha uma foto</FormLabel>
          {/* <InputView {...register("urlImage")} /> */}
          <Input
            type="file"
            {...register("urlImage")}
            onChange={onImageChange}
          />
        </FormControl>
        {imgSrc && <Img src={imgSrc} />}
      </Box>
    </Box>
  );
}

export { ItemDetails };
