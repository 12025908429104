import { v4 as uuid } from "uuid";
import { Schedule, TOpeningHour } from "../types";

export function transformOpeningHours(
  item: Omit<TOpeningHour, "id">,
  array: Schedule[]
): void {
  const [hi, mi] = item.startAt.split(":");
  const [hf, mf] = item.endAt.split(":");
  if (item.sunday === 1) {
    array.push({
      id: uuid(),
      startAt: [Number(hi), Number(mi)],
      endAt: [Number(hf), Number(mf)],
      weekDay: 0,
    });
  }
  if (item.monday === 1) {
    array.push({
      id: uuid(),
      startAt: [Number(hi), Number(mi)],
      endAt: [Number(hf), Number(mf)],
      weekDay: 1,
    });
  }
  if (item.tuesday === 1) {
    array.push({
      id: uuid(),
      startAt: [Number(hi), Number(mi)],
      endAt: [Number(hf), Number(mf)],
      weekDay: 2,
    });
  }
  if (item.wednesday === 1) {
    array.push({
      id: uuid(),
      startAt: [Number(hi), Number(mi)],
      endAt: [Number(hf), Number(mf)],
      weekDay: 3,
    });
  }
  if (item.thursday === 1) {
    array.push({
      id: uuid(),
      startAt: [Number(hi), Number(mi)],
      endAt: [Number(hf), Number(mf)],
      weekDay: 4,
    });
  }
  if (item.friday === 1) {
    array.push({
      id: uuid(),
      startAt: [Number(hi), Number(mi)],
      endAt: [Number(hf), Number(mf)],
      weekDay: 5,
    });
  }
  if (item.saturday === 1) {
    array.push({
      id: uuid(),
      startAt: [Number(hi), Number(mi)],
      endAt: [Number(hf), Number(mf)],
      weekDay: 6,
    });
  }
}
