/* eslint-disable jsx-a11y/media-has-caption */
import {
  AddIcon,
  ArrowBackIcon,
  CheckIcon,
  DeleteIcon,
  EditIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Text,
  Heading,
  Button,
  Center,
  Table,
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Input,
  useDisclosure,
  VStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Switch,
  useToast,
} from "@chakra-ui/react";
import { useState, useEffect, useCallback } from "react";
import useSWR from "swr";
import ReactHowler from "react-howler";
import CardsOrder from "./components/CardsOrder";
import { useAuth } from "../../hooks/auth";
import { api } from "../../services/api";
import { ModalOrder } from "./Modals/ModalOrder";
import bell from "../../audio/bell.wav";
import { formatPrice } from "../../utils/formatPrice";

// Entrega ou retirada no local
type OrderType = "DELIVERY" | "PICKED_UP";
// metodos de pagamento( pode ter mais)
type PaymentMethod = "CREDIT_CARD" | "CASH" | "DEBIT_CARD";
type OrderStatus =
  | "PLACED"
  | "CONFIRMED"
  | "DISPATCHED"
  | "CONCLUDED"
  | "CANCELLED"
  | "READ_TO_PICKUP";

// importacao
export type TOrder = {
  id: string;
  createdAt: string;
  orderNumber: string;
  status: OrderStatus;
  orderType: OrderType;
  paymentMethods: PaymentMethod;
  additionalInfo: string; // ex: levar troco

  delivery: {
    deliveryInformation: string;
    address: {
      neighborhood: string;
      city: string;
      zipCode: string;
      reference: string;
      addressFormatted: string;
    };
  };

  customer: {
    name: string;
    documentNumber?: string; // se precisar de cpf na nota
    phoneNumber: string;
  };
  items: {
    id: string;
    observations: string;
    quantity: number;
    unitPrice: number;
    price: number; // price = quantity * unitPrice
    totalOptions: string;
    name: string;
    options: {
      id: string;
      unitPrice: number;
      quantity: number;
      price: number; // price = quantity * unitPrice
      name: string;
    }[];
  }[];

  address: {
    street: string;
    number: string;
    neighborhood: string;
    complement: string;
    reference: string;
    formattedAddress: string;
  };
  subTotal: number;
  deliveryFee: number;
  total: number;
};

type GetStatusButton = {
  label: string;
  color: string;
  info: string;
  statusColor?: string;
};

function getStatusButton(
  statu: OrderStatus,
  orderType: OrderType
): GetStatusButton {
  const statusButton = {} as GetStatusButton;
  if (statu === "PLACED") {
    statusButton.label = "Confirmar";
    statusButton.color = "green";
    statusButton.info = "Pendente";
    statusButton.statusColor = "pink.600";
  }
  if (statu === "CONFIRMED") {
    if (orderType === "DELIVERY") {
      statusButton.label = "Despachar";
      statusButton.color = "yellow";
      statusButton.info = "Em preparacao";
      statusButton.statusColor = "green.500";
    } else {
      statusButton.label = "Liberar Retirada";
      statusButton.color = "yellow";
      statusButton.info = "Em preparacao";
      statusButton.statusColor = "green.500";
    }
  }
  if (statu === "DISPATCHED") {
    statusButton.label = "Finalizar";
    statusButton.color = "blue";
    statusButton.info = "Em entrega";
    statusButton.statusColor = "blue.400";
  }
  if (statu === "READ_TO_PICKUP") {
    statusButton.label = "Finalizar";
    statusButton.color = "blue";
    statusButton.info = "Para Retirar";
    statusButton.statusColor = "blue.400";
  }
  if (statu === "DISPATCHED" || statu === "READ_TO_PICKUP") {
    statusButton.label = "Finalizar";
    statusButton.color = "blue";
    statusButton.info = "Concluído";
    statusButton.statusColor = "yellow.500";
  }
  if (statu === "CONCLUDED") {
    statusButton.label = "red";
    statusButton.color = "red";
    statusButton.info = "Finalizado";
    statusButton.statusColor = "blue.800";
  }
  if (statu === "CANCELLED") {
    statusButton.label = "red";
    statusButton.color = "red";
    statusButton.info = "Cancelado";
    statusButton.statusColor = "red";
  }

  return statusButton;
}

// importacao

function Order(): JSX.Element {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const [print, setPrint] = useState<any>(null);
  // const [print, setPrint] = useState<any | null>(null);
  // const [dataOrder, setdataOrder] = useState<any>([]);
  const [orderUpdate, setOrderUpdate] = useState<any | null>(null);
  const { user } = useAuth();
  const { companyId } = user;
  const url = `/companies/${companyId}/orders`;
  const { data, error, mutate } = useSWR<TOrder[], Error>(
    url,
    async () => {
      const response = await api.get(url);
      return response.data;
    },
    { refreshInterval: 30000 }
  );

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function audioOrder() {
    const audio = new Audio(bell);

    audio.muted = false;
    audio.play();
  }

  if (!data) {
    return <div>carregando</div>;
  }

  const isNewOrder = data.some((order) => order.status === "PLACED");

  if (isNewOrder) {
    // setTimeout(audioOrder, 5000);
    // audioOrder();
  }

  async function handleChangeStatus(
    orderId: string,
    statusOder: OrderStatus,
    orderType: OrderType
  ): Promise<void> {
    switch (statusOder) {
      case "PLACED": {
        try {
          api.post(`/companies/${companyId}/orders/${orderId}/confirm`);
          const newData = data?.map((order) => {
            if (order.id === orderId) {
              return {
                ...order,
                status: "CONFIRMED" as OrderStatus,
              };
            }
            return order;
          });
          if (newData) {
            mutate(newData, false);
          }
        } catch (err) {
          console.log(err);
        }

        break;
      }
      case "CONFIRMED": {
        try {
          if (orderType === "DELIVERY") {
            api.post(`/companies/${companyId}/orders/${orderId}/dispatch`);
          } else {
            api.post(
              `/companies/${companyId}/orders/${orderId}/ready-to-pickup`
            );
          }
          const newData = data?.map((order) => {
            if (order.id === orderId) {
              return {
                ...order,
                status:
                  orderType === "DELIVERY"
                    ? ("DISPATCHED" as OrderStatus)
                    : ("READ_TO_PICKUP" as OrderStatus),
              };
            }
            return order;
          });
          if (newData) {
            mutate(newData, false);
          }
        } catch (err) {
          console.log(err);
        }

        break;
      }
      case "DISPATCHED": {
        try {
          api.post(`/companies/${companyId}/orders/${orderId}/conclude`);
          const newData = data?.map((order) => {
            if (order.id === orderId) {
              return {
                ...order,
                status: "CONCLUDED" as OrderStatus,
              };
            }
            return order;
          });
          if (newData) {
            mutate(newData, false);
          }
        } catch (err) {
          console.log(err);
        }

        break;
      }

      case "READ_TO_PICKUP": {
        try {
          api.post(`/companies/${companyId}/orders/${orderId}/conclude`);
          const newData = data?.map((order) => {
            if (order.id === orderId) {
              return {
                ...order,
                status: "CONCLUDED" as OrderStatus,
              };
            }
            return order;
          });
          if (newData) {
            mutate(newData, false);
          }
        } catch (err) {
          console.log(err);
        }

        break;
      }

      default:
    }
  }

  function handleUpdatePrint(printData: any): void {
    console.log(printData);
  }

  function handleCancel(orderId: string): void {
    api.post(`/companies/${companyId}/orders/${orderId}/cancell`);
    const newData = data?.map((order) => {
      if (order.id === orderId) {
        return {
          ...order,
          status: "CANCELLED" as OrderStatus,
        };
      }
      return order;
    });
    if (newData) {
      mutate(newData, false);
    }
  }

  return (
    <VStack spacing={4} align="stretch" margin="20px">
      {isNewOrder && <ReactHowler src={bell} playing />}

      {orderUpdate && (
        <ModalOrder
          isOpen={isOpen}
          onClose={onClose}
          orderUpdate={orderUpdate}
          // eslint-disable-next-line react/jsx-no-bind
          handleUpdatePrint={handleUpdatePrint}
        />
      )}
      <CardsOrder data={data} />
      <Box margin="25px">
        <Center>
          <Heading textColor="blue.300" marginTop="20px">
            Aqui Você Acompanha os Pedidos da Sua Loja
          </Heading>
        </Center>
        <Center>
          <Text textColor="gray.400">
            Fique atendo para despachar os pedidos no tempo correto
          </Text>
        </Center>
      </Box>
      <Box>
        <TableContainer
          border="1px solid"
          borderColor="gray.100"
          borderRadius="md"
        >
          <Table variant="striped" colorScheme="blue">
            <Thead>
              <Tr>
                <Th>Pedido</Th>
                <Th>Nome</Th>
                <Th>Status</Th>
                <Th>Mudar Status</Th>

                <Th>Total</Th>
                <Th>Açoes</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map((newDataOrder) => (
                <Tr key={newDataOrder.id}>
                  <Td>{newDataOrder.orderNumber}</Td>
                  <Td>{newDataOrder.customer.name}</Td>
                  <Td>
                    <Text
                      fontSize="md"
                      as="b"
                      color={
                        getStatusButton(
                          newDataOrder.status,
                          newDataOrder.orderType
                        ).statusColor
                      }
                    >
                      {
                        getStatusButton(
                          newDataOrder.status,
                          newDataOrder.orderType
                        ).info
                      }
                    </Text>
                  </Td>

                  {newDataOrder.status === "CONCLUDED" ||
                  newDataOrder.status === "CANCELLED" ? (
                    <Td />
                  ) : (
                    <Td>
                      <Button
                        textColor="white"
                        colorScheme={
                          getStatusButton(
                            newDataOrder.status,
                            newDataOrder.orderType
                          ).color
                        }
                        onClick={() => {
                          handleChangeStatus(
                            newDataOrder.id,
                            newDataOrder.status,
                            newDataOrder.orderType
                          );
                        }}
                      >
                        {
                          getStatusButton(
                            newDataOrder.status,
                            newDataOrder.orderType
                          ).label
                        }
                      </Button>
                    </Td>
                  )}

                  <Td> {formatPrice(newDataOrder.total)}</Td>

                  {newDataOrder.status === "CONCLUDED" ||
                  newDataOrder.status === "CANCELLED" ? (
                    <Td>
                      <Button
                        onClick={() => {
                          onOpen();
                          setOrderUpdate(newDataOrder);
                        }}
                        leftIcon={<EditIcon />}
                        size="xs"
                        border="1px"
                        marginRight="5px"
                        colorScheme="linkedin"
                        paddingRight="2px"
                      />
                    </Td>
                  ) : (
                    <Td>
                      <Button
                        onClick={() => {
                          onOpen();
                          setOrderUpdate(newDataOrder);
                        }}
                        leftIcon={<EditIcon />}
                        size="xs"
                        border="1px"
                        marginRight="5px"
                        colorScheme="linkedin"
                        paddingRight="2px"
                      />
                      <Button
                        onClick={() => {
                          handleCancel(newDataOrder.id);
                          // api.post(`/companies/${companyId}/orders/${orderId}/conclude`);
                        }}
                        // leftIcon={<DeleteIcon />}
                        size="xs"
                        border="1px"
                        marginRight="5px"
                        colorScheme="red"
                        padding="5px"
                      >
                        Cancelar
                      </Button>
                    </Td>
                  )}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </VStack>
  );
}

export { Order };
