import {
  Box,
  Flex,
  Heading,
  Stack,
  Text,
  Image,
  Input,
  Show,
  Hide,
  FormControl,
  FormLabel,
  Button,
  Center,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
// eslint-disable-next-line import/no-unresolved
import resetImage from "../../download/resetImage.png";
import { api } from "../../services/api";

interface FormData {
  password: string;
  passwordConfirmation: string;
}

function Reset(): JSX.Element {
  const { register, handleSubmit, reset } = useForm<FormData>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const toast = useToast();
  async function handleFormSubmit(data: FormData): Promise<void> {
    // console.log(data);
    try {
      await api.post("/password/reset", {
        ...data,
        token: searchParams.get("token"),
      });
      reset();
      navigate("/");
      toast({
        title: "Senha Alterada com sucesso.",
        description: "Você já pode fazer login",
        status: "success",
        position: "top-right",
        isClosable: true,
      });
    } catch (erro) {
      toast({
        title: "Erro",
        description: "Contate o suporte",
        status: "warning",
        position: "top-right",
        isClosable: true,
      });
    }
  }

  return (
    <Stack minH="75vh" direction={{ base: "column", md: "row" }}>
      <Flex p={8} flex={1} align="center" justify="center">
        <Stack
          spacing={4}
          w="full"
          maxW="md"
          margin="20px"
          as="form"
          onSubmit={handleSubmit(handleFormSubmit)}
        >
          <Center>
            <Heading fontSize="4xl" textColor="blue.600">
              Resetar Senha
            </Heading>
          </Center>
          <Center>
            <Text fontSize="lg" color="gray.600">
              Você está prestes a redefinir sua senha ✌️
            </Text>
          </Center>

          <FormControl>
            <FormLabel margin="0px" textColor="blue.400">
              Nova Senha
            </FormLabel>
            <Input {...register("password")} type="password" />
          </FormControl>
          <FormControl>
            <FormLabel margin="0px" textColor="blue.400">
              Repetir Nova Senha
            </FormLabel>
            <Input {...register("passwordConfirmation")} type="password" />
          </FormControl>
          <Stack spacing={6}>
            <Stack
              direction={{ base: "column", sm: "row" }}
              align="start"
              justify="space-between"
            >
              <Button
                onClick={() => navigate("/")}
                colorScheme="teal"
                variant="link"
                size="xs"
                margin="0"
                maxW="20%"
              >
                Login
              </Button>
            </Stack>
            <Button type="submit" marginTop="20px" colorScheme="blue">
              Enviar
            </Button>
          </Stack>
        </Stack>
        <Hide below="md">
          <Image minW="350px" maxW="450px" alt="reset img" src={resetImage} />
        </Hide>
      </Flex>
    </Stack>
    // <Flex
    //   height="100vh"
    //   bgColor="blue.50"
    //   justifyContent="center"
    //   alignItems="center"
    // >
    //   <Flex
    //     maxW="1000px"
    //     py={12}
    //     px={6}
    //     mx="auto"
    //     padding="30px"
    //     justifyContent="center"
    //     alignItems="center"
    //     shadow="dark-lg"
    //     borderRadius="xl"
    //     bgColor="#FFF"
    //   >
    //     {/* <Flex alignItems="center" justifyContent="center"> */}
    //     <Show above="sm">
    //       <Stack
    //         paddingLeft="54px"
    //         margin="20px"
    //         minWidth="300px"
    //         spacing={4}
    //         as="form"
    //         onSubmit={handleSubmit(handleFormSubmit)}
    //       >
    //         <Center margin="0px">
    //           <Heading textColor="blue.600">Resetar Senha</Heading>
    //         </Center>
    //         <Center margin="0px">
    //           <Text textColor="gray.400">
    //             Você está prestes a redefinir sua senha
    //           </Text>
    //         </Center>
    //         <FormControl>
    //           <FormLabel margin="0px" textColor="blue.400">
    //             Nova Senha
    //           </FormLabel>
    //           <Input {...register("password")} type="password" />
    //         </FormControl>
    //         <FormControl>
    //           <FormLabel margin="0px" textColor="blue.400">
    //             Repetir Nova Senha
    //           </FormLabel>
    //           <Input {...register("passwordConfirmation")} type="password" />
    //         </FormControl>
    //         <Button
    //           onClick={() => navigate("/")}
    //           colorScheme="teal"
    //           variant="link"
    //           size="xs"
    //           margin="0"
    //           maxW="20%"
    //         >
    //           Login
    //         </Button>

    //         <Button type="submit" marginTop="20px" colorScheme="blue">
    //           Enviar
    //         </Button>
    //       </Stack>
    //     </Show>
    //     <Hide below="md">
    //       <Box>
    //         <Image
    //           minW="300px"
    //           maxW="600px"
    //           alt="login img"
    //           src={resetImage}
    //           // bgSize={{ base: "null", md: "1200px", lg: "1200px" }}
    //         />
    //       </Box>
    //     </Hide>
    //   </Flex>
    // </Flex>
  );
}

export { Reset };
