/* eslint-disable react/no-children-prop */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/function-component-definition */
import { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { IconContext } from "react-icons";

import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import {
  Button,
  Center,
  Flex,
  Tag,
  TagLabel,
  useDisclosure,
  Text,
  Box,
  MenuButton,
  Menu,
  MenuItem,
  MenuList,
  IconButton,
} from "@chakra-ui/react";
import { FaRegUserCircle } from "react-icons/fa";
import { BiDotsVerticalRounded } from "react-icons/bi";

import { EmailIcon } from "@chakra-ui/icons";
import { SidebarData } from "./SidebarData";
import Submenu from "./Submenu";
import { useAuth } from "../hooks/auth";
import { DrawerMenuRight } from "./Menu/DrawerMenuRight";
// menu principal topo
const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.5rem;
  background-color: #5846ff;
`;

// menu esquerda
const SidebarNav = styled.div<{ sidebar: boolean }>`
  position: absolute;
  /* float: left !important; */
  z-index: 999; //prioridade de sobrepor com relacao aos outros elementos do css
  width: 200px;
  height: 100vh;
  background-color: #5846ff;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 100ms;
`;
const NavIcon = styled(Link)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 5rem;
  font-size: 1.8rem;
  margin-left: 1rem;
`;
const SidebarWrap = styled.div``;

const Sidebar: FC = () => {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  // // funcao que vai esconder e mostrar o input

  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);

  // // menu DRawer chakra
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <IconContext.Provider value={{ color: "#fff" }}>
      <Nav>
        <NavIcon to="#" onClick={showSidebar}>
          <AiOutlineMenu />
        </NavIcon>
        <Flex alignItems="center">
          <Tag
            size="lg"
            backgroundColor="#6a5ed1"
            textColor="gray.100"
            borderRadius="full"
          >
            <TagLabel>{user.name}</TagLabel>
          </Tag>

          <Button
            marginLeft="10px"
            backgroundColor="#5846ff"
            onClick={onOpen}
            size="xs"
            padding="0px"
          >
            <FaRegUserCircle size="25px" />
          </Button>
          {/* <Button
            backgroundColor="#5846ff"
            onClick={onOpen}
            size="xs"
            padding="0px"
          >
            <BiDotsVerticalRounded size="30px" />
          </Button> */}
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<BiDotsVerticalRounded />}
              colorScheme="blue"
              margin="0px"
              padding="0px"
              variant="outline"
            />
            <MenuList p={2} minW="0" w="120px">
              <MenuItem onClick={onOpen}>Editar Perfil</MenuItem>
              <MenuItem
                onClick={() => {
                  signOut();
                  navigate("/");
                }}
              >
                Sair
              </MenuItem>
              {/* <Button
            colorScheme="red"
            onClick={() => {
              signOut();
              navigate("/");
            }}
          >
            sair
          </Button> */}
            </MenuList>
          </Menu>

          <DrawerMenuRight isOpen={isOpen} onClose={onClose} />
        </Flex>
      </Nav>

      {/* BARRA DA LATERAL ESQUERDA */}
      <SidebarNav sidebar={sidebar}>
        <SidebarWrap>
          <NavIcon to="#" onClick={showSidebar}>
            <AiOutlineClose />
          </NavIcon>
          {SidebarData.map((item, index) => (
            <Submenu item={item} key={index} />
          ))}
        </SidebarWrap>
        <Box alignSelf="self-end" marginTop="160px">
          <Center>
            <Link to="/">
              <EmailIcon color="gray.300" margin="5px" />
            </Link>
            <Text fontSize="sm" textColor="gray.300">
              contato@suporte.com
            </Text>
          </Center>
          <Center>
            <Text fontSize="sm" textColor="gray.300">
              (32) 99999-9999
            </Text>
          </Center>
        </Box>
      </SidebarNav>
    </IconContext.Provider>
  );
};

export default Sidebar;
