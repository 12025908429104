import { useRef, useState, ChangeEvent } from "react";
import * as Yup from "yup";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { shade } from "polished";
import {
  useToast,
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Input,
  FormControl,
  InputGroup,
  InputLeftElement,
  Stack,
  Center,
  FormErrorMessage,
  Avatar,
  Badge,
  Link,
  Text,
  DrawerFooter,
} from "@chakra-ui/react";
import { FiCamera, FiLock, FiMail, FiUser } from "react-icons/fi";
import styled from "styled-components";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { render } from "react-dom";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/auth";

import { api } from "../../services/api";

interface ProfileFormData {
  name: string;
  email: string;
  oldPassword: string;
  password: string;
  confirmationPassword: string;
}
interface DrawerMenuRightProps {
  isOpen: boolean;
  onClose: () => void;
}

export const AvatarInput = styled.div`
  margin-bottom: 32px;
  position: relative;
  /* width: 186px; */ // nao precisa por causa do allign self
  align-self: center;

  img {
    width: 130px;
    height: 130px;
    border-radius: 50px;
  }

  label {
    position: absolute;
    width: 48px;
    height: 48px;
    background: #3182ce;
    border-radius: 50%;
    right: 0;
    bottom: 0;
    border: 0;
    transition: background-color 0.2s;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    input {
      display: none; // esconder o escolher arquivo
    }

    svg {
      width: 20px;
      height: 20px;
      color: #fff;
    }

    &:hover {
      background: ${shade(0.2, "#3182ce")};
    }
  }
`;

// const schema = Yup.object().shape({
//   email: Yup.string().email("Digite um e-mail válido"),
//   password: Yup.string(),
//   oldPassword: Yup.string(),
//   confirmationPassword: Yup.string(),
// });

const schema = Yup.object().shape({
  name: Yup.string(),
  email: Yup.string().email("Digite um e-mail válido"),
  oldPassword: Yup.string(),
  password: Yup.string().when("oldPassword", {
    is: (val: string) => !!val.length, // se tiver algum texto, torna nova senha obrigatoria
    then: Yup.string().required("Campo obrigatorio"),
    otherwise: Yup.string(),
  }),
  confirmationPassword: Yup.string()
    .when("oldPassword", {
      is: (val: string) => !!val.length, // se tiver algum texto, torna nova senha obrigatoria
      then: Yup.string().required().required("Campo obrigatorio"),
      otherwise: Yup.string(),
    })
    .oneOf([Yup.ref("password"), null], "Confirmacao incorreta"),
});

function DrawerMenuRight({
  isOpen,
  onClose,
}: DrawerMenuRightProps): JSX.Element {
  // funcao que vai esconder e mostrar o input
  const [showElement, setShowElement] = useState(false);
  const [showElementProfile, setShowElementProfile] = useState(false);

  // funcao que cancela e volta pro showElem,ente e Showprofile false

  const cancelButton = (): void => {
    setShowElement(false);
    setShowElementProfile(false);
  };

  // funcao do profile picture
  const toast = useToast();
  const { user, updateUser, signOut } = useAuth();
  const navigate = useNavigate();
  // console.log(user);

  // avatar
  async function handleAvatarChange(
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> {
    try {
      if (e.target.files) {
        const data = new FormData();

        data.append("avatarFilename", e.target.files[0]);
        const response = await api.patch("/profile/avatar", data);
        updateUser(response.data);
        toast({
          title: "Atualização de perfil.",
          description: "Perfil atualizado com sucesso!",
          status: "success",
          position: "top-right",
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<ProfileFormData>({
    defaultValues: { oldPassword: "" },
    resolver: yupResolver(schema),
  });

  // funcao que vai esconder e mostrar o input
  // const functionButton = (): void => setShowElement(!showElement);

  // const functionButtonProfile = (): void => {
  //   setShowElementProfile(!showElementProfile);
  //   setShowElement(!showElement);
  // };

  // envianfo form pro banco
  async function handleFormSubmit(data: ProfileFormData): Promise<void> {
    console.log(data);
    try {
      const { name, email, oldPassword, password, confirmationPassword } = data;

      const newData = {
        name: showElement ? user.name : name,
        email: showElement ? user.email : email,
        ...(data.oldPassword
          ? { oldPassword, password, confirmationPassword }
          : {}),
      };

      const response = await api.put(`/profile/update`, newData);
      // console.log(response.data);
      updateUser(response.data);
      reset();
      cancelButton();
      // onClose();
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>{user.name}</DrawerHeader>
        <DrawerBody>
          <Stack spacing={4}>
            <AvatarInput>
              <img src={user.avatar_url} alt={user.name} />
              <label htmlFor="avatar">
                <FiCamera />
                <input type="file" id="avatar" onChange={handleAvatarChange} />
              </label>
            </AvatarInput>

            <>
              {!showElement && (
                <>
                  <FormControl
                  // hidden={showElement}
                  >
                    <InputGroup>
                      <InputLeftElement pointerEvents="none">
                        <FiUser color="gray.300" />
                      </InputLeftElement>
                      <Input
                        autoComplete="off"
                        {...register("name")}
                        type="text"
                        placeholder="Usuario"
                        defaultValue={user.name}
                        readOnly={!showElementProfile}
                        border="none"
                      />
                    </InputGroup>
                  </FormControl>
                  <FormControl
                    isInvalid={!!errors.email}
                    // hidden={showElement}
                  >
                    <InputGroup>
                      <InputLeftElement pointerEvents="none">
                        <FiMail color="gray.300" />
                      </InputLeftElement>

                      <Input
                        {...register("email")}
                        type="email"
                        placeholder="Email"
                        defaultValue={user.email}
                        readOnly={!showElementProfile}
                        border={!showElement ? "none" : "solid"}
                      />
                    </InputGroup>
                    <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                  </FormControl>
                </>
              )}

              {/* //SENHA COMECA AQUI */}
              <FormControl
                isInvalid={!!errors.oldPassword}
                marginBottom="16px"
                hidden={!showElement}
              >
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <FiLock color="gray.300" />
                  </InputLeftElement>

                  <Input
                    {...register("oldPassword")}
                    type="password"
                    placeholder="Senha Antiga"
                  />
                </InputGroup>
                <FormErrorMessage>
                  {errors.oldPassword?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={!!errors.password}
                marginBottom="16px"
                hidden={!showElement}
              >
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    // children={<FiLock color="gray.300" />}
                  >
                    <FiLock color="gray.300" />
                  </InputLeftElement>

                  <Input
                    {...register("password")}
                    type="password"
                    placeholder="Nova Senha"
                  />
                </InputGroup>
                <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={!!errors.confirmationPassword}
                hidden={!showElement}
              >
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    // children={<FiLock color="gray.300" />}
                  >
                    <FiLock color="gray.300" />
                  </InputLeftElement>

                  <Input
                    {...register("confirmationPassword")}
                    type="password"
                    placeholder="Repetir Nova Senha"
                  />
                </InputGroup>
                <FormErrorMessage>
                  {errors.confirmationPassword?.message}
                </FormErrorMessage>
              </FormControl>
              <Center hidden={showElement}>
                <Button
                  colorScheme="blue"
                  textColor="white"
                  size="sm"
                  onClick={() => {
                    setShowElementProfile(!showElementProfile);
                    reset({ oldPassword: "" });
                  }}
                  marginRight="10px"
                  hidden={showElementProfile}
                >
                  Editar Perfil
                </Button>

                <Button
                  colorScheme="orange"
                  textColor="white"
                  size="sm"
                  hidden={showElementProfile}
                  onClick={() => {
                    setShowElement(!showElement);
                    // setShowElementProfile(!showElementProfile);
                  }}
                >
                  Alterar Senha
                </Button>
              </Center>

              {/* //BOTAO DO PERFIL DE ENVIAR E CANCELAR */}
              <Center marginTop="20px">
                <Button
                  colorScheme="blue"
                  size="sm"
                  mr={3}
                  onClick={handleSubmit(handleFormSubmit)}
                  padding="0px 20px 0px 20px"
                  hidden={!showElementProfile}
                >
                  Salvar
                </Button>
                <Button
                  colorScheme="red"
                  size="sm"
                  mr={3}
                  onClick={cancelButton}
                  hidden={!showElementProfile}
                >
                  Cancelar
                </Button>
              </Center>
              {/* //botao do alterar senha */}
              <Center marginTop="20px">
                <Button
                  colorScheme="blue"
                  size="sm"
                  mr={3}
                  onClick={handleSubmit(handleFormSubmit)}
                  padding="0px 20px 0px 20px"
                  hidden={!showElement}
                >
                  Salvar
                </Button>
                <Button
                  colorScheme="red"
                  size="sm"
                  mr={3}
                  onClick={cancelButton}
                  hidden={!showElement}
                >
                  Cancelar
                </Button>
              </Center>
            </>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export { DrawerMenuRight };
