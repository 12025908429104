/* eslint-disable no-shadow */
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Stack,
} from "@chakra-ui/react";
import { UseFieldArrayRemove } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import { Controller } from "react-hook-form";
import { RiDeleteBinLine } from "react-icons/ri";

import { CreateItemFormData } from "../modals/CreateItemModal";

interface ComplementDetails2Props {
  // register: UseFormRegister<CreateItemFormData>;
  // control: Control<CreateItemFormData>;
  // setValue: UseFormSetValue<CreateItemFormData>;
  // getValues: UseFormGetValues<CreateItemFormData>;
  index: number;
  remove: UseFieldArrayRemove;
}

function ComplementDetails2({
  index,
  remove,
}: ComplementDetails2Props): JSX.Element {
  const {
    register,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<CreateItemFormData>();
  const handleIncrementMin = (): void => {
    const oldValue = getValues(`complements.${index}.min`);
    setValue(`complements.${index}.min`, oldValue + 1);
  };

  const handleDecreaseMin = (): void => {
    const oldValue = getValues(`complements.${index}.min`);
    if (oldValue > 0) {
      setValue(`complements.${index}.min`, oldValue - 1);
    }
  };

  const handleIncrementMax = (): void => {
    const oldValue = getValues(`complements.${index}.max`);
    setValue(`complements.${index}.max`, oldValue + 1);
  };

  const handleDecreaseMax = (): void => {
    const oldValue = getValues(`complements.${index}.max`);
    if (oldValue > 0) {
      setValue(`complements.${index}.max`, oldValue - 1);
    }
  };

  return (
    <Box>
      <Stack spacing={4}>
        <Flex>
          <FormControl isInvalid={!!errors?.complements?.[index]?.name}>
            <FormLabel mb="0" className="required">
              Nome
            </FormLabel>
            <Input
              {...register(`complements.${index}.name` as const)}
              placeholder="Ex:Escolha uma opção:"
            />

            <FormErrorMessage>
              {errors?.complements?.[index]?.name?.message}
            </FormErrorMessage>
          </FormControl>
          <Flex ml="4rem" cursor="pointer">
            <RiDeleteBinLine size={20} onClick={() => remove(index)} />
          </Flex>
        </Flex>
        <HStack spacing={4}>
          <FormControl display=" flex" alignItems="center">
            <FormLabel>Mínimo</FormLabel>
            <Flex>
              <Button
                borderRadius="0"
                colorScheme="gray"
                onClick={handleDecreaseMin}
                size="sm"
              >
                -
              </Button>

              <Controller
                name={`complements.${index}.min` as const}
                control={control}
                render={({ field }) => (
                  <Input
                    type="number"
                    size="sm"
                    {...field}
                    readOnly
                    borderRadius="none"
                    onChange={(e) => {
                      field.onChange(parseInt(e.target.value, 10));
                    }}
                  />
                )}
              />
              <Button
                borderRadius="0"
                colorScheme="gray"
                onClick={handleIncrementMin}
                size="sm"
              >
                +
              </Button>
            </Flex>
          </FormControl>
          <FormControl display=" flex" alignItems="center">
            <FormLabel>Máximo</FormLabel>
            <Flex>
              <Button
                borderRadius="0"
                colorScheme="gray"
                size="sm"
                onClick={handleDecreaseMax}
              >
                -
              </Button>
              <Controller
                name={`complements.${index}.max` as const}
                control={control}
                render={({ field }) => (
                  <Input
                    type="number"
                    size="sm"
                    {...field}
                    borderRadius="none"
                    onChange={(e) => {
                      field.onChange(parseInt(e.target.value, 10));
                    }}
                  />
                )}
              />

              <Button
                borderRadius="0"
                colorScheme="gray"
                size="sm"
                onClick={handleIncrementMax}
              >
                +
              </Button>
            </Flex>
          </FormControl>
          <FormControl>
            <Checkbox ml={16}>Obrigatório</Checkbox>
          </FormControl>
        </HStack>
      </Stack>
      <Divider m="1rem 0 1rem 0 " />
    </Box>
  );
}

export { ComplementDetails2 };
