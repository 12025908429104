import { Box, Button, Stack } from "@chakra-ui/react";

import { useFieldArray, useFormContext } from "react-hook-form";

import { useCallback } from "react";
import { EditItemFormData } from "./index";
import { ComplementDetails2 } from "./ComplementDetails2";
import { Options } from "./Options";
import { api } from "../../../../services/api";

interface ComplementsProps {
  productId: string | undefined;
}

function Complements({ productId }: ComplementsProps): JSX.Element {
  const { control } = useFormContext<EditItemFormData>();
  const {
    fields: complements,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "complements",
  });

  const undoAssociationComplementProduct = useCallback(
    async (index: number) => {
      const complement = complements.find((_, i) => i === index);
      const complementId = complement?.complementId;
      if (complementId) {
        // o complemento ja existe no banco de dados
        await api.delete(`/complements/${complementId}/products/${productId}`);
        remove(index);
      } else {
        // aqui, adicionou um comppemento no aaray, mas nao fou salvo no banco ainda
        remove(index);
      }
    },
    [complements, productId, remove]
  );

  return (
    <Stack spacing={4}>
      <Button
        w="30%"
        colorScheme="blue"
        onClick={() => {
          append({
            name: "",
            min: 0,
            max: 0,
          });
        }}
      >
        Adicionar complementos
      </Button>
      {complements.map((complement, index) => (
        <Box key={complement.id} border="1px solid #d5d5d5" rounded="md" p={4}>
          {/* eslint-disable-next-line no-underscore-dangle */}
          <ComplementDetails2
            {...{ index, undoAssociationComplementProduct }}
          />
          <Options {...{ index, complementId: complement.complementId }} />
        </Box>
      ))}
    </Stack>
  );
}

export { Complements };
