/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  ModalFooter,
  Button,
  FormControl,
  Input,
  FormErrorMessage,
  Textarea,
  Stack,
  FormLabel,
  RadioGroup,
  Radio,
  HStack,
  Flex,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { FormProvider } from "react-hook-form";

import { useEffect } from "react";
import { api } from "../../../services/api";

import { Complements } from "../components/Complements";
import { ItemDetails } from "../components/ItemDetails";

import { TComplements, TItem } from "../../../types";
import { formatStringInputToNumber } from "../../../utils/formatStringInputToNumber";
import { currencyMask } from "../../../utils/masks";
import { formatNumberToStringInput } from "../../../utils/formatNumberToStringInput";

interface DuplicateItemModalProps {
  isOpen: boolean;
  onClose: () => void;
  categoryId: string;
  item: TItem | null;
  setItemsState: React.Dispatch<React.SetStateAction<TItem[]>>;
}

export interface DuplicateItemFormData {
  name: string;
  description: string;
  originalPrice: string;
  type: "PIZZA" | "DEFAULT";
  urlImage: string;
  additionnalInformation?: string;
  status: "AVAILABLE" | "UNAVAILABLE";
}

function DuplicateItemModal({
  isOpen,
  onClose,
  categoryId,
  setItemsState,
  item,
}: DuplicateItemModalProps): JSX.Element {
  const { register, handleSubmit, reset, setValue, control } =
    useForm<DuplicateItemFormData>();

  useEffect(() => {
    if (item) {
      reset({
        name: `${item.name} - cópia`,
        description: item.description,
        originalPrice: formatNumberToStringInput(item.originalPrice),
        type: "DEFAULT",
        urlImage: item.urlImage,
        status: "UNAVAILABLE",
      });
    }
  }, [item, reset]);

  async function handleDuplicateFormSubmit(
    data: DuplicateItemFormData
  ): Promise<void> {
    try {
      const formData = new FormData();
      if (item) {
        /** **************** create product *************************** */
        formData.append("urlImage", item.urlImage);
        formData.append(
          "obj",
          JSON.stringify({
            name: data.name,
            description: data.description,
            additionnalInformation: data.additionnalInformation,
            type: data.type,
            status: data.status,
          })
        );
        // ATENCAO; COMPANYID FIXO!!!!!!!
        const response = await api.post(
          `companies/db18d8ed-c1ba-4512-b47d-d82ca1426876/products`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        const product = response.data;

        /** **************** create item(associa o produto a categoria) ****** */
        const responseItem = await api.post(
          `/categories/${categoryId}/products/${product.id}`,
          {
            originalPrice: formatStringInputToNumber(data.originalPrice),
            status: data.status,
          }
        );

        /**
         * se o item duplicado possui complementos, associa todos os
         * complementos desse item para o novo item
         */

        if (item.complements.length !== 0) {
          let complementIndex = 0;
          for (const complement of item.complements) {
            // associa o complemnto ao producto
            const complementProductResponse = await api.post(
              `/complements/${complement.complementId}/products/${product.id}`,
              {
                max: complement.max,
                min: complement.min,
                index: complementIndex,
              }
            );
            complementIndex += 1;
          }
        }

        /** Seta o novo estado */
        setItemsState((state) => [
          ...state,
          {
            ...item,
            id: responseItem.data.id,
            name: data.name,
            description: data.description,
            status: data.status,
            originalPrice: formatStringInputToNumber(data.originalPrice),
            discountPrice: Number(null), // type discountPrice(TItem) = number
            // quando duplica um item, nao tem promocao
            // Number(null) = 0
          },
        ]);

        reset();
        onClose();
      }
    } catch (error) {
      console.log(error);
    }
  }

  // console.log(metods.formState.errors);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl" scrollBehavior="inside">
      <ModalOverlay />

      <ModalContent
        as="form"
        onSubmit={handleSubmit(handleDuplicateFormSubmit)}
        overflow="hidden"
      >
        <ModalHeader>Duplicar Item</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing="1rem">
            <FormControl>
              <FormLabel>Nome</FormLabel>
              <Input {...register("name")} />
            </FormControl>

            <FormControl>
              <FormLabel>Descrição</FormLabel>
              <Textarea {...register("description")} />
            </FormControl>

            <FormControl w="30%">
              <FormLabel>Preço</FormLabel>
              <Input
                {...register("originalPrice")}
                onChange={(e) => {
                  const { value } = e.currentTarget;
                  e.currentTarget.value = currencyMask(value);
                  return e;
                }}
              />
            </FormControl>
            <FormControl>
              <Controller
                control={control}
                name="status"
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <HStack>
                      <Radio value="AVAILABLE">Disponivel</Radio>
                      <Radio value="UNAVAILABLE">Pausado</Radio>
                    </HStack>
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="gray"
            mr={3}
            onClick={() => {
              reset();
              onClose();
            }}
          >
            Close
          </Button>
          <Button type="submit" colorScheme="blue">
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export { DuplicateItemModal };
