import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormLabel,
  HStack,
  Checkbox,
  Text,
  Box,
  Center,
  Select,
  VStack,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import { useSetRecoilState } from "recoil";

import { Schedule, TOpeningHour } from "../../../types";
import { transformOpeningHours } from "../../../utils/tranformOpeningHours";

import { openingHoursState } from "../store/atom";

interface CreateModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const hours = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
  "23:59",
];

interface FormData {
  startAt: string;
  endAt: string;
  sunday: number;
  monday: number;
  tuesday: number;
  wednesday: number;
  thursday: number;
  friday: number;
  saturday: number;
}

function CreateModal({ isOpen, onClose }: CreateModalProps): JSX.Element {
  const setOpeningHours = useSetRecoilState(openingHoursState);
  const { handleSubmit, control, reset } = useForm<FormData>({
    defaultValues: {
      startAt: "00:00",
      endAt: "00:00",
      monday: 0,
      sunday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
    },
  });

  function handleAdicionar(formData: Omit<TOpeningHour, "id">): void {
    try {
      const newOpeningHours: Schedule[] = [];
      transformOpeningHours(formData, newOpeningHours);

      setOpeningHours((oldOpeningHours) => [
        ...oldOpeningHours,
        ...newOpeningHours,
      ]);

      reset();
      onClose();
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(handleAdicionar)}>
        <ModalHeader>Cadastrar Horario de funcionamento</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing="2rem">
            <HStack>
              <Box>
                <Text>Domingo</Text>
                <Center>
                  <Controller
                    name="sunday"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        onChange={(e) =>
                          field.onChange(Number(e.target.checked))
                        }
                      />
                    )}
                  />
                </Center>
              </Box>
              <Box>
                <Text>Segunda</Text>
                <Center>
                  <Controller
                    name="monday"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        onChange={(e) =>
                          field.onChange(Number(e.target.checked))
                        }
                      />
                    )}
                  />
                </Center>
              </Box>
              <Box>
                <Text>Terca</Text>
                <Center>
                  <Controller
                    name="tuesday"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        onChange={(e) =>
                          field.onChange(Number(e.target.checked))
                        }
                      />
                    )}
                  />
                </Center>
              </Box>
              <Box>
                <Text>Quarta</Text>
                <Center>
                  <Controller
                    name="wednesday"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        onChange={(e) =>
                          field.onChange(Number(e.target.checked))
                        }
                      />
                    )}
                  />
                </Center>
              </Box>
              <Box>
                <Text>Quinta</Text>
                <Center>
                  <Controller
                    name="thursday"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        onChange={(e) =>
                          field.onChange(Number(e.target.checked))
                        }
                      />
                    )}
                  />
                </Center>
              </Box>
              <Box>
                <Text>Sexta</Text>
                <Center>
                  <Controller
                    name="friday"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        onChange={(e) =>
                          field.onChange(Number(e.target.checked))
                        }
                      />
                    )}
                  />
                </Center>
              </Box>
              <Box>
                <Text>Sabado</Text>
                <Center>
                  <Controller
                    name="saturday"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        onChange={(e) =>
                          field.onChange(Number(e.target.checked))
                        }
                      />
                    )}
                  />
                </Center>
              </Box>
            </HStack>

            <HStack spacing="2rem">
              <FormLabel display="flex" alignItems="center">
                <Text mr="1rem">Das: </Text>
                <Controller
                  name="startAt"
                  control={control}
                  render={({ field }) => (
                    <Select {...field}>
                      {hours.map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </Select>
                  )}
                />
              </FormLabel>

              <FormLabel display="flex" alignItems="center">
                <Text mr="1rem"> Até: </Text>
                <Controller
                  name="endAt"
                  control={control}
                  render={({ field }) => (
                    <Select {...field}>
                      {hours.map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </Select>
                  )}
                />
              </FormLabel>
            </HStack>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="gray" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button type="submit" colorScheme="blue">
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export { CreateModal };
