/* eslint-disable no-param-reassign */
function maskCnpj(value: string): string {
  value = value.replace(/\D+/g, "");
  // coloca ponto entre o segundo e o terceiro dígitos
  value = value.replace(/^(\d{2})(\d)/, "$1.$2");

  // coloca ponto entre o quinto e o sexto dígitos
  value = value.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");

  // coloca uma barra entre o oitavo e o nono dígitos
  value = value.replace(/\.(\d{3})(\d)/, ".$1/$2");

  // coloca um hífen depois do bloco de quatro dígitos
  value = value.replace(/(\d{4})(\d)/, "$1-$2");

  return value;
}

function maskCep(value: string): string {
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d{5})(\d)/, "$1-$2");

  return value;
}

function maskCelPhone(value: string): string {
  value = value.replace(/\D/g, "");
  value = value.replace(/^0/, "");
  if (value.length > 10) {
    value = value.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
  } else if (value.length > 5) {
    value = value.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
  } else if (value.length > 2) {
    value = value.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
  } else {
    value = value.replace(/^(\d*)/, "($1");
  }

  return value;
}

function maskCurrency(value: string): string {
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d)(\d{2})$/, "$1,$2");
  value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");

  return value;
}

function undoMaskCnpj(value: string): string {
  value = value.replaceAll(".", "");
  value = value.replace("-", "");

  return value;
}

/** adiocionado ante  */

function currencyMask(value: string): string {
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d)(\d{2})$/, "$1,$2");
  value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");

  return value;
}

function undoCurrencyMask(value: string): string {
  return value.replace(/[^0-9,]*/g, "").replace(",", ".");
}

function undoMask(value: string): string {
  return value.replace(/[^\w]/gi, "");
}

export {
  maskCnpj,
  maskCelPhone,
  undoMaskCnpj,
  maskCep,
  maskCurrency,
  currencyMask,
  undoCurrencyMask,
  undoMask,
};
