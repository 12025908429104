/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/function-component-definition */
/* eslint-disable import/extensions */
import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
// eslint-disable-next-line import/extensions
// eslint-disable-next-line import/no-unresolved
import { SidebarItem } from "../models/SidebarItem";

type SidebarLinkProps = {
  item: SidebarItem;
};

const SidebarLink = styled(Link)`
  display: flex;
  align-items: center;
  height: 3.75rem;
  font-size: 1.125rem;
  padding: 2rem;
  text-decoration: none;
  color: #ffffff;

  &:hover {
    background-color: #6d44dc;
    border-left: 4px solid #fff;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 1rem;
`;

const DivSet = styled.span`
  margin-left: 1rem;
`;

const DropdownLink = styled(Link)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 3.75rem;
  font-size: 1.125rem;
  padding-left: 3rem;
  text-decoration: none;
  color: #ffffff;

  &:hover {
    background-color: #6d44dc;
  }
`;

const Submenu: FC<SidebarLinkProps> = ({ item }) => {
  const [subnav, setSubnav] = useState(false);
  const showSubnav = () => setSubnav(!subnav);

  return (
    <>
      <SidebarLink to={item.path} onClick={showSubnav}>
        {item.icon}
        <SidebarLabel>{item.title}</SidebarLabel>

        <DivSet>
          {item?.subnav && subnav ? item?.iconOpened : item?.iconClosed}
        </DivSet>
      </SidebarLink>
      {subnav &&
        item?.subnav?.map((subnavItem, index) => (
          <DropdownLink to={subnavItem.path} key={index}>
            {subnavItem.icon}
            <SidebarLabel>{subnavItem.title}</SidebarLabel>
          </DropdownLink>
        ))}
    </>
  );
};

export default Submenu;
