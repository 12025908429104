/* eslint-disable react/jsx-no-bind */
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Text,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import { v4 as uuid } from "uuid";
import { useState } from "react";
import { useRecoilValue } from "recoil";

import { openingHoursState } from "./store/atom";
import { CreateModal } from "./modals/CreateModal";
import { RangeBox } from "./components/HourRange";
import { TOpeningHour } from "../../types";
import { api } from "../../services/api";
import { useAuth } from "../../hooks/auth";

const weekDays = [
  "Domingo",
  "Segunda",
  "Terça",
  "quarta",
  "Quinta ",
  "Sexta",
  "Sábado",
];

const COL = 8;
const ROW = 25;
const grid = Array.from({ length: ROW }, (x, i) =>
  Array.from({ length: COL }, (y, j) => ({
    rowStart: i + 1,
    rowEnd: i + 1 + 1,
    columnStart: j + 1,
    columnEnd: j + 1 + 1,
    weedDay: i === 0 && j > 0 ? weekDays[j - 1] : "",
    hour: j === 0 && i > 0 ? `${i - 1}h` : "",
  }))
);

function OpeningHour(): JSX.Element {
  const openingHours = useRecoilValue(openingHoursState);
  const { user } = useAuth();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();

  async function handleSubmitChanges(): Promise<void> {
    setIsSubmitting(true);

    const array = openingHours.reduce((acc, item) => {
      const startAt = `${item.startAt[0]
        .toString()
        .padStart(2, "0")}:${item.startAt[1].toString().padStart(2, "0")}`;

      const endAt = `${item.endAt[0]
        .toString()
        .padStart(2, "0")}:${item.endAt[1].toString().padStart(2, "0")}`;
      const index = acc.findIndex(
        (i) => i.startAt === startAt && i.endAt === endAt
      );
      if (index !== -1) {
        switch (item.weekDay) {
          case 0:
            acc[index].sunday = 1;
            break;
          case 1:
            acc[index].monday = 1;
            break;
          case 2:
            acc[index].tuesday = 1;
            break;
          case 3:
            acc[index].wednesday = 1;
            break;
          case 4:
            acc[index].thursday = 1;
            break;
          case 5:
            acc[index].friday = 1;
            break;
          case 6:
            acc[index].saturday = 1;
            break;
          default:
            break;
        }
      } else {
        acc.push({
          startAt,
          endAt,
          sunday: item.weekDay === 0 ? 1 : 0,
          monday: item.weekDay === 1 ? 1 : 0,
          tuesday: item.weekDay === 2 ? 1 : 0,
          wednesday: item.weekDay === 3 ? 1 : 0,
          thursday: item.weekDay === 4 ? 1 : 0,
          friday: item.weekDay === 5 ? 1 : 0,
          saturday: item.weekDay === 6 ? 1 : 0,
        });
      }

      return acc;
    }, [] as Omit<TOpeningHour, "id">[]);

    try {
      await api.post(`/companies/${user.companyId}/opening-hours`, {
        openingHours: array,
      });
      setIsSubmitting(false);
      toast({
        title: "Alteração do  horário de funcionamento.",
        description: "Horário de funcionamento alterado com sucesso.",
        status: "success",
        position: "top-right",
      });
    } catch (error) {
      setIsSubmitting(false);
      toast({
        title: "Alteração do  horário de funcionamento.",
        description: "Ocorreu um erro, tente novamente.",
        status: "error",
        position: "top-right",
      });
      console.log(error);
    }
  }

  return (
    <Box>
      <Box h="calc(100vh - 50px -  45px)" w="100%" bg="#f5f5f5">
        <Grid
          h="100%"
          w="100%"
          templateColumns={`repeat(${COL}, 1fr)`}
          templateRows={`repeat(${ROW}, 1fr)`}
          position="relative"
        >
          {grid.map((row) =>
            row.map((cell) => (
              <GridItem
                key={uuid()}
                bg="white"
                colStart={cell.columnStart}
                colEnd={cell.columnEnd}
                rowStart={cell.rowStart}
                rowEnd={cell.rowEnd}
                position="relative"
                border="0.03125rem solid #d5d5d5"
              >
                <Box
                  position="absolute"
                  top="-12px"
                  bg="#fff"
                  w="36px"
                  fontSize="14px"
                >
                  {cell.hour}
                </Box>
                <Text fontSize="14px"> {cell.weedDay}</Text>
              </GridItem>
            ))
          )}
          {openingHours.map((item) => (
            <RangeBox
              key={item.id}
              id={item.id}
              startAt={item.startAt}
              endAt={item.endAt}
              weekDay={item.weekDay}
            />
          ))}
        </Grid>
      </Box>

      <Flex justifyContent="end" p="0.5rem">
        <Button colorScheme="red" onClick={onOpen} size="sm">
          Adicionar
        </Button>
        <Button
          colorScheme="red"
          onClick={handleSubmitChanges}
          size="sm"
          isLoading={isSubmitting}
          ml="1rem"
        >
          Salvar ateracoes
        </Button>
      </Flex>

      <CreateModal isOpen={isOpen} onClose={onClose} />
    </Box>
  );
}

export { OpeningHour };
