/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { useState, useEffect } from "react";
import {
  Modal,
  Text,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Box,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  ModalFooter,
  Button,
  HStack,
  useToast,
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { api } from "../../../../services/api";

import { Complements } from "./Complements";
import { ItemDetails } from "./ItemDetails";

import { TItem } from "../../../../types";

import { currencyMask } from "../../../../utils/masks";
import { formatPrice } from "../../../../utils/formatPrice";
import { formatNumberToStringInput } from "../../../../utils/formatNumberToStringInput";
import { formatStringInputToNumber } from "../../../../utils/formatStringInputToNumber";
import { useAuth } from "../../../../hooks/auth";

interface EditItemModalProps {
  isOpen: boolean;
  onClose: () => void;
  categoryId: string;
  item: TItem | null;
}

export interface EditItemFormData {
  id: string;
  name: string;
  description: string;
  additionnalInformation: string;
  urlImage: string;
  cod: string;
  type: "DEFAULT" | "PIZZA";
  originalPrice: string;
  discountPrice: string;
  discountPercentage: number;
  promotionIsActived: boolean;
  productId: string;
  complements: {
    _id?: string;
    name: string;
    min: number;
    max: number;
    complementId?: string;
    options: {
      id?: string;
      name: string;
      productId?: string;
      description: string;
      cod: string;
      originalPrice: string;
      discountPrice: string;
    }[];
  }[];
  teste: string;
}

const optionSchema = {
  name: Yup.string().required("Campo obrigatório"),
  originalPrice: Yup.string().required("Campo obrigatório"),
};

const complementSchema = {
  name: Yup.string().required("Campo obrigatório"),
  options: Yup.array()
    .of(Yup.object().shape(optionSchema))
    .required()
    .min(1, "Complemento deve ter no mínimo uma opção"),
};

const schema = Yup.object().shape({
  name: Yup.string().required("Campo obrigatório"),
  originalPrice: Yup.string().required("Campo Obrigatório"),
  complements: Yup.array().of(Yup.object().shape(complementSchema)),
});

function EditItemModal({
  isOpen,
  onClose,
  categoryId,
  item,
}: EditItemModalProps): JSX.Element {
  const metods = useForm<EditItemFormData>({ resolver: yupResolver(schema) });
  const [tabIndex, setTabIndex] = useState(0);
  const toast = useToast();
  const { user } = useAuth();

  useEffect(() => {
    if (item) {
      metods.reset({
        ...item,
        id: item.id,
        originalPrice: formatNumberToStringInput(item.originalPrice),
        discountPrice: formatNumberToStringInput(item.discountPrice),
        discountPercentage: Number(
          ((1 - item.discountPrice / item.originalPrice) * 100).toFixed(2)
        ),
        promotionIsActived: !!item.discountPrice,
        complements: item.complements.map((complement) => ({
          ...complement,

          options: complement.options.map((option) => ({
            ...option,
            originalPrice: formatNumberToStringInput(option.originalPrice),
            discountPrice: formatNumberToStringInput(option.discountPrice),
          })),
        })),
      });
    }
  }, [item, metods]);

  /**
   * reseta modal
   * volta tab para priemira aba
   * fecha modal
   */
  function closeEditItemModal(): void {
    if (Object.entries(metods.formState.touchedFields).length === 0) {
      onClose();
      metods.reset();
      setTabIndex(0);
    } else {
      toast({
        title: "Existe atualizações para serem salvas, antes fechar.",
        status: "warning",
        position: "top-right",
      });
    }
  }

  async function handleEditFormSubmit(data: EditItemFormData): Promise<void> {
    try {
      /** *************** atualiza o produto ********************** */
      // console.log(data);
      const formData = new FormData();
      if (data.urlImage) {
        // data pode ser null
        formData.append("urlImage", data.urlImage[0]);
      }

      formData.append(
        "obj",
        JSON.stringify({
          name: data.name,
          description: data.description,
          additionnalInformation: data.additionnalInformation,
          cod: data.cod,
        })
      );

      const productResponse = await api.put(
        `companies/${user.companyId}/products/${data.productId}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      /** *************** atualiza o item ********************** */
      const itemId = data.id;
      const responseItem = await api.put(
        `/categories/${categoryId}/items/${itemId}`,
        {
          originalPrice: formatStringInputToNumber(data.originalPrice),
          discountPrice:
            data.discountPrice && formatStringInputToNumber(data.discountPrice),
        }
      );

      if (data.complements.length !== 0) {
        let complementIndex = 0;

        for (const complement of data.complements) {
          let { complementId } = complement;
          let complementResponse;
          let complementProductResponse;
          const { productId } = data;

          // O complemento sera atualizado( name, min, max ...)
          if (complementId) {
            complementResponse = await api.patch(
              `/companies/${user.companyId}/complements/${complementId}`,
              { name: complement.name }
            );
            // atualiza productComplements
            complementProductResponse = await api.put(
              `/complements/${complementId}/products/${productId}`,
              {
                max: complement.max,
                min: complement.min,
                index: complementIndex,
              }
            );

            complementIndex += 1;

            // atualiza( name, descripiton, urlImage ....) em products
            let optionIndex = 0;
            for (const option of complement.options) {
              // option vai ser atualizado
              let formDataUpdate;
              if (option.id) {
                formDataUpdate = new FormData();
                formDataUpdate.append(
                  "obj",
                  JSON.stringify({
                    name: option.name,
                    description: option.description,
                    // additionnalInformation: data.additionnalInformation,
                    type: "DEFAULT", // MUDAR ISSO
                    cod: option.cod,
                  })
                );

                // eslint-disable-next-line no-shadow
                // const { productId } = option.productId;
                const productOptionResponse = await api.put(
                  `companies/${user.companyId}/products/${option.productId}`,
                  formDataUpdate,
                  {
                    headers: { "Content-Type": "multipart/form-data" },
                  }
                );

                // atualiza a associacao entre option e product( na tabela options)
                const optionResponse = await api.put(
                  `/complements/${complementId}/products/${option.productId}/options`,
                  {
                    originalPrice: formatStringInputToNumber(
                      option.originalPrice
                    ),
                    cod: option.cod,
                    index: optionIndex,
                  }
                );
              } else {
                // foi adicionado nova opcao, nom complemento ja existente
                formDataUpdate = new FormData();
                formDataUpdate.append(
                  "obj",
                  JSON.stringify({
                    name: option.name,
                    description: option.description,
                    // additionnalInformation: data.additionnalInformation,
                    type: "DEFAULT",
                    cod: option.cod,
                  })
                );
                // ATENCAO; COMPANYID FIXO!!!!!!!
                // cria produto
                const responseProductOption = await api.post(
                  `companies/${user.companyId}/products`,
                  formDataUpdate,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                );
                // associa option ao complemto e product
                const optionResponse = await api.post(
                  `/complements/${complementResponse.data.id}/products/${responseProductOption.data.id}/options`,
                  {
                    originalPrice: formatStringInputToNumber(
                      option.originalPrice
                    ),
                    cod: option.cod,
                    index: optionIndex,
                  }
                );
              }
              optionIndex += 1;
            }
          } else {
            // foi adicionado novo complemento
            complementResponse = await api.post("/complements", {
              name: complement.name,
            });

            // associa o complemnto ao producto
            complementId = complementResponse.data.id;

            complementProductResponse = await api.post(
              `/complements/${complementId}/products/${productId}`,
              {
                max: complement.max,
                min: complement.min,
                index: complementIndex,
              }
            );

            let optionIndex = 0;
            for (const option of complement.options) {
              const formDataCreateOptionProduct = new FormData();
              formDataCreateOptionProduct.append(
                "obj",
                JSON.stringify({
                  name: option.name,
                  description: option.description,
                  // additionnalInformation: data.additionnalInformation,
                  type: "DEFAULT",
                  cod: option.cod,
                })
              );
              // ATENCAO; COMPANYID FIXO!!!!!!!
              // cria produto
              const responseProductOption = await api.post(
                `companies/${user.companyId}/products`,
                formDataCreateOptionProduct,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              );
              // associa option ao complemto e product
              const optionResponse = await api.post(
                `/complements/${complementResponse.data.id}/products/${responseProductOption.data.id}/options`,
                {
                  originalPrice: formatStringInputToNumber(
                    option.originalPrice
                  ),
                  cod: option.cod,
                  index: optionIndex,
                }
              );
              optionIndex += 1;
            }
          }
          complementIndex += 1;
        }
      }
      /**
       * atualizar complemento( nome) ou criar    => complementId
       * atualizar productComplements( min, max)  => productComplementId
       * atualizar ou add options
       */

      // metods.formState.touchedFields = {}; // limpa avisos de campos touched
      // closeEditItemModal();
      onClose();
      metods.reset();
      setTabIndex(0);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      size="4xl"
      scrollBehavior="inside"
      onClose={() => closeEditItemModal()}
    >
      <ModalOverlay />
      <FormProvider {...metods}>
        <ModalContent
          as="form"
          overflow="hidden"
          onSubmit={metods.handleSubmit(handleEditFormSubmit)}
        >
          <ModalHeader>Editar item</ModalHeader>
          <ModalCloseButton />
          <ModalBody py={0}>
            <Tabs index={tabIndex} onChange={(index) => setTabIndex(index)}>
              <TabList position="sticky" top="0" bg="white" zIndex="sticky">
                <Tab>Detalhes</Tab>
                <Tab>Complementos</Tab>
              </TabList>

              <TabPanels overflow="auto" py={4}>
                {/* Detalhes */}
                <TabPanel py={0}>
                  <ItemDetails />
                </TabPanel>
                {/* Complementos */}
                <TabPanel py={0}>
                  <Complements productId={item?.productId} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              variant="outline"
              mr={3}
              onClick={() => {
                closeEditItemModal();
              }}
            >
              Close
            </Button>

            {tabIndex < 1 && (
              <Button
                colorScheme="blue"
                onClick={() => setTabIndex((state) => state + 1)}
              >
                Próximo
              </Button>
            )}
            {tabIndex === 1 && (
              <Button
                colorScheme="blue"
                type="submit"
                isLoading={metods.formState.isSubmitting}
              >
                Salvar
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </FormProvider>
    </Modal>
  );
}

export { EditItemModal };

/**
 * Atualizar item:
 * 1) atualiza produto relacionado ao item( name, description ....) => retorna produto atualizado(productItemResponse)
 * 2) atualiza item(associacao entre produto e categoria : preco, cod ...) => retorna item atualizado( itemResponse)
 * 3) se o item tiver complemento, atualiza complementos:
 *       3.1) atualizar complemento existente:
 *           3.1.1 atualizar opcoes existentes
 *           3.1.2 novas opcoes adicionadas
 *       3.2) foi adicionado novo(s) complemento(s)
 *           3.2.1 criar complemento
 *           3.2.2 associar complemento ao produto(item)
 *           3.2.3 complemento novo = opcao nova( dentro do for)
 *               3.2.3.1 cria produto(option) => productOptionResponse
 *               3.2.3.1 criar option(associar ..) => responseOption
 *
 *
 *
 */

// metods.setValue("name", item.name);
// metods.setValue("description", item.description);
// item.complements.forEach((complement, complementIndex) => {
//   metods.setValue(`complements.${complementIndex}.name`, complement.name);
//   // setValue(`complements.${complementIndex}.min`, complement.min);
//   // setValue(`complements.${complementIndex}.max`, complement.max);
//   // eslint-disable-next-line no-shadow
//   // complement.options.forEach((option, optionIndex) => {
//   //   setValue(
//   //     `complements.${complementIndex}.options.${optionIndex}.name`,
//   //     option.name
//   //   );
//   //   setValue(
//   //     `complements.${complementIndex}.options.${optionIndex}.description`,
//   //     option.description
//   //   );
//   //   setValue(
//   //     `complements.${complementIndex}.options.${optionIndex}.cod`,
//   //     option.cod
//   //   );
//   //   // setValue(
//   //   //   `complements.${complementIndex}.options.${optionIndex}.originalPrice`,
//   //   //   option.originalPrice
//   //   // );
//   // });
// });

// setValue("originalPrice", formatPrice(item.originalPrice));
// setValue("discountPrice", currencyMask(item.discountPrice.toString()));
