import { DeleteIcon } from "@chakra-ui/icons";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Flex,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  FormControl,
  useToast,
  FormErrorMessage,
  FormLabel,
  Input,
  Divider,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useEffect } from "react";

import { Controller, useForm } from "react-hook-form";
import { api } from "../../../services/api";
import { CategoriesI } from "./ModalUpdateWallet";

interface ModalCreateCategoryProps {
  isOpen: boolean;
  onClose: () => void;
  companyId: string;
  categories: CategoriesI[];
  setCategories: React.Dispatch<React.SetStateAction<CategoriesI[]>>;
}
interface FormCategory {
  id: string;
  title: string;
}
function ModalCreateCategory({
  isOpen,
  onClose,
  companyId,
  categories,
  setCategories,
}: ModalCreateCategoryProps): JSX.Element {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormCategory>();
  const toast = useToast();

  function handleSubmitCreateCategory(data: FormCategory): void {
    // console.log(data);
    api.post(`/companies/${companyId}/transactionsCategories`, data).then(
      (response) =>
        setCategories((state) => [
          { id: response.data.id, title: response.data.title },
          ...state,
        ])

      // console.log(response.data)
    );
    toast({
      title: "Sucesso",
      description: `Categoria Cadastrada com sucesso`,
      status: "success",
      position: "top-right",
      duration: 4000,
      isClosable: true,
    });

    reset();
    onClose();
  }

  function handleDeleteCategory(id: string): void {
    console.log(id);
    // deletar faltando ver com andré
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        as="form"
        onSubmit={handleSubmit(handleSubmitCreateCategory)}
      >
        <ModalHeader>Adicionar Categoria</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isInvalid={!!errors.title}>
            <Input
              {...register("title")}
              margin="2px"
              placeholder="Nome da Categoria"
            />
            <FormErrorMessage marginTop="0px">
              {errors.title?.message}
            </FormErrorMessage>
          </FormControl>
          <Flex pt={2}>
            <Button colorScheme="purple" type="submit">
              Adicionar
            </Button>
          </Flex>
          <Divider margin={4} />
          <TableContainer>
            <Table variant="striped" colorScheme="blue">
              <Thead>
                <Tr>
                  <Th>Categorias</Th>
                  <Th>Ações</Th>
                </Tr>
              </Thead>
              <Tbody>
                {categories.map((resul) => (
                  <Tr key={resul.id}>
                    <Td>{resul.title}</Td>
                    <Td>
                      <Button
                        colorScheme="red"
                        size="xs"
                        onClick={() => handleDeleteCategory(resul.id)}
                      >
                        <DeleteIcon />
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </ModalBody>

        <ModalFooter />
      </ModalContent>
    </Modal>
  );
}
export { ModalCreateCategory };
