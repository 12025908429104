import { Box, Button, Flex, useDisclosure } from "@chakra-ui/react";
import { MdAdd } from "react-icons/md";

import { CategoriesContainer } from "./components/CategoriesContainer";
import { CreateCategoryModal } from "./modals/CreateCategoryModal";

function Menu(): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box p="1rem">
      <Flex mb="1rem">
        <Button
          colorScheme="primary"
          variant="outline"
          leftIcon={<MdAdd size={20} />}
          onClick={onOpen}
        >
          Adicionar categoria
        </Button>
      </Flex>
      <CategoriesContainer />

      <CreateCategoryModal isOpen={isOpen} onClose={onClose} />
    </Box>
  );
}

export { Menu };
