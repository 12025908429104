import {
  Flex,
  Box,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Input,
  Heading,
  HStack,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import { ChangeEvent } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { MdAdd, MdDelete } from "react-icons/md";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useEffect } from "react";
import { formatNumberToStringInput } from "../../../utils/formatNumberToStringInput";
import { formatPrice } from "../../../utils/formatPrice";
import { currencyMask } from "../../../utils/masks";
import { parametersState } from "../store/atom";
import { formatStringInputToNumber } from "../../../utils/formatStringInputToNumber";
import { api } from "../../../services/api";
import { useAuth } from "../../../hooks/auth";

interface FormValues {
  parameter: { id?: string; distance: number; time: number; value: string }[];
}

const parameterSchema = {
  value: Yup.number()
    .transform((value, originalvalue) =>
      formatStringInputToNumber(originalvalue)
    )
    .required("Campo obrigatório"),
  time: Yup.number()
    .typeError("Digite um valor válido")
    .required("Campo obrigatório")
    .integer("Somente valores inteiros")
    .positive("Tempo deve ser maior que zero"),
};

const schema = Yup.object().shape({
  parameter: Yup.array().of(Yup.object().shape(parameterSchema)),
});

function onChangeMaskInput(
  e: ChangeEvent<HTMLInputElement>
): ChangeEvent<HTMLInputElement> {
  const { value } = e.currentTarget;
  if (value === "R$") {
    e.currentTarget.value = "";
    return e;
  }
  e.currentTarget.value = `R$ ${currencyMask(value)}`;
  return e;
}

function FeeTime(): JSX.Element {
  const [parameters, setParameters] = useRecoilState(parametersState);
  const { user } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      parameter: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: "parameter",
    control,
  });

  useEffect(() => {
    reset({
      parameter: parameters.map((el) => ({
        ...el,
        value: formatPrice(el.value),
      })),
    });
  }, [parameters, reset]);

  async function handleFormSubmit(data: FormValues): Promise<void> {
    try {
      const response = await api.post(
        `/companies/${user.companyId}/delivery-parameters`,
        data.parameter
      );

      setParameters(response.data);
      toast({
        title: "Alterações salvas.",
        status: "success",
        position: "top-right",
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Ocorreu um erro, tente novamente.",
        status: "error",
        position: "top-right",
        isClosable: true,
      });
    }
  }

  return (
    <Box>
      <Flex p={4} justifyContent="space-between">
        <span />
        <Heading size="lg">Cadastro de taxas e tempos</Heading>
        <Button
          leftIcon={<MdAdd />}
          colorScheme="primary"
          onClick={() =>
            append({
              distance: (fields.length + 1) * 1000,
              value: "",
              time: 0,
            })
          }
        >
          Adicionar
        </Button>
      </Flex>
      <Box
        as="form"
        w="100%"
        display="grid"
        placeItems="center"
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <TableContainer>
          <Table variant="unstyled" px={0}>
            <Thead>
              <Tr>
                <Th>Distância</Th>
                <Th>Valor</Th>
                <Th>Tempo(em minutos)</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {fields.map((parameter, index) => (
                <Tr key={parameter.id}>
                  <Td py={2}>{`Até ${parameter.distance / 1000} Km`}</Td>
                  <Td py={2}>
                    <FormControl
                      isInvalid={!!errors?.parameter?.[index]?.value}
                    >
                      <Input
                        px={2}
                        {...register(`parameter.${index}.value`)}
                        onChange={onChangeMaskInput}
                      />
                      <FormErrorMessage>
                        {errors?.parameter?.[index]?.value?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Td>
                  <Td py={2}>
                    <FormControl isInvalid={!!errors?.parameter?.[index]?.time}>
                      <Input
                        type="number"
                        {...register(`parameter.${index}.time`, {
                          valueAsNumber: true,
                        })}
                      />
                      <FormErrorMessage>
                        {errors?.parameter?.[index]?.time?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Td>
                  <Td>
                    {index === fields.length - 1 && fields.length > 1 && (
                      <MdDelete
                        cursor="pointer"
                        size={20}
                        onClick={() => remove(fields.length - 1)}
                      />
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <HStack spacing={4} mt={8} />
        <HStack>
          <Button
            colorScheme="primary"
            variant="outline"
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
          <Button
            type="submit"
            colorScheme="primary"
            // isDisabled={fields.length === 0}
          >
            Salvar alterações
          </Button>
        </HStack>
      </Box>
    </Box>
  );
}

export { FeeTime };
