/* eslint-disable react/jsx-no-bind */
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormLabel,
  HStack,
  Select,
  Text,
  FormControl,
} from "@chakra-ui/react";
import { useRef } from "react";

import { RiDeleteBinLine } from "react-icons/ri";
import { useRecoilState } from "recoil";

import { openingHoursState } from "../store/atom";

const weekDays = [
  "Domingo",
  "Segunda",
  "Terça",
  "quarta",
  "Quinta ",
  "Sexta",
  "Sábado",
];

const hours = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
  "24:00",
];

type UpdateModalProps = {
  id: string;
  isOpen: boolean;
  onClose: () => void;
};

function UpdateModal(props: UpdateModalProps): JSX.Element {
  const { id, isOpen, onClose } = props;
  const [openingHours, setOpeningHours] = useRecoilState(openingHoursState);

  const refStartAtInput = useRef<HTMLSelectElement>(null);
  const refEndAtInput = useRef<HTMLSelectElement>(null);

  const updateSchedule = openingHours.find((item) => item.id === id);

  const startAtDefault = `${updateSchedule?.startAt[0]
    .toString()
    .padStart(2, "0")}:${updateSchedule?.startAt[1]
    .toString()
    .padStart(2, "0")}`;

  const endAtDefault = `${updateSchedule?.endAt[0]
    .toString()
    .padStart(2, "0")}:${updateSchedule?.endAt[1].toString().padStart(2, "0")}`;

  function handleDeleteSchedule(): void {
    setOpeningHours((oldOpeningHour) =>
      oldOpeningHour.filter((schedule) => schedule.id !== id)
    );
    onClose();
  }

  function handleUpdateSchedule(): void {
    if (
      refStartAtInput.current &&
      refStartAtInput.current.value &&
      refEndAtInput.current &&
      refEndAtInput.current.value
    ) {
      const [hi, mi] = refStartAtInput.current.value.split(":");
      const [hf, mf] = refEndAtInput.current.value.split(":");
      setOpeningHours((oldOpeningHour) =>
        oldOpeningHour.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              startAt: [Number(hi), Number(mi)],
              endAt: [Number(hf), Number(mf)],
            };
          }
          return item;
        })
      );
    }

    onClose();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{weekDays[updateSchedule?.weekDay || 0]}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack>
            <FormControl>
              <FormLabel display="flex" alignItems="center">
                <Text mr="1rem">Das: </Text>
                <Select defaultValue={startAtDefault} ref={refStartAtInput}>
                  {hours.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
              </FormLabel>
            </FormControl>

            <FormControl>
              <FormLabel display="flex" alignItems="center">
                <Text mr="1rem"> Até: </Text>
                <Select defaultValue={endAtDefault} ref={refEndAtInput}>
                  {hours.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
              </FormLabel>
            </FormControl>

            <Button
              variant="unstyled"
              leftIcon={<RiDeleteBinLine size={20} />}
              onClick={handleDeleteSchedule}
            />
          </HStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="gray" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button colorScheme="blue" onClick={handleUpdateSchedule}>
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export { UpdateModal };
