import {
  Flex,
  Heading,
  Stack,
  Text,
  Image,
  Input,
  Hide,
  FormControl,
  FormLabel,
  Button,
  Center,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

// eslint-disable-next-line import/no-unresolved
import forgotImage from "../../download/forgotImage.png";
import { api } from "../../services/api";

interface FormData {
  email: string;
}

function Forgot(): JSX.Element {
  const {
    handleSubmit,
    register,
    reset,
    formState: { isSubmitting },
  } = useForm<FormData>();

  const toast = useToast();
  const navigate = useNavigate();
  async function handleFormSubmit(data: FormData): Promise<void> {
    try {
      await api.post("/password/forgot", data);

      reset();
      toast({
        title: "E-mail enviado ",
        description:
          "Você receberá as instruções em seu email para redefinir sua senha",
        status: "success",
        position: "top-right",
        isClosable: true,
      });
    } catch (erro) {
      toast({
        title: "Erro ",
        description: "E-mail ou senha incorretos",
        status: "warning",
        position: "top-right",
        isClosable: true,
      });
    }
  }

  return (
    <Stack minH="75vh" direction={{ base: "column", md: "row" }}>
      <Flex p={8} flex={1} align="center" justify="center">
        <Stack spacing={4} w="full" maxW="md" margin="20px">
          <Center>
            <Heading textColor="blue.600">Esqueci minha senha</Heading>
          </Center>
          <Center>
            <Text textColor="gray.400">
              Você recebera um email com as instruções de redefinição
            </Text>
          </Center>

          <FormControl>
            <FormLabel margin="0px" textColor="blue.400">
              E-mail
            </FormLabel>
            <Input {...register("email")} type="text" />
          </FormControl>
          <Stack spacing={6}>
            <Stack
              direction={{ base: "column", sm: "row" }}
              align="start"
              justify="space-between"
            >
              <Button
                onClick={() => navigate("/")}
                colorScheme="teal"
                variant="link"
                size="xs"
                margin="0"
                maxW="20%"
              >
                Voltar
              </Button>
            </Stack>
            <Button
              isLoading={isSubmitting}
              onClick={handleSubmit(handleFormSubmit)}
              marginTop="20px"
              colorScheme="blue"
            >
              Enviar
            </Button>
          </Stack>
        </Stack>
        <Hide below="md">
          <Image minW="250px" maxW="400px" alt="login img" src={forgotImage} />
        </Hide>
      </Flex>
    </Stack>
  );
}

export { Forgot };
